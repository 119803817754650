import { Component } from '@angular/core';
import { QuizProcessorService } from '@private/models/quizzes/client/service/quiz-processor.service'
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract'
import { UxService } from '@services/ux/ux.service';
import { TQuestionPresentation } from '@private/models/questions/types/question.type';
import { exists } from '@library/common/generic-common/tools/utils/concise.exists';


@Component({
  selector: 'app-add-questions',
  templateUrl: './add-questions.page.html',
  styleUrls: ['./add-questions.page.scss'],
})
export class AddQuestionsPage extends ConciseComponent {

  public get questionOptions():TQuestionPresentation[]|undefined {
    return (exists(this, 'processor.questions')) ? this.processor.questions : undefined
  }


  constructor(
    public override ux:UxService,
    protected processor:QuizProcessorService
  ) {
    super(ux)
  }














  ionViewWillEnter = () => this.ngOnInit()



  

  









  public override ngOnInit(): void {
    if (!this.processor.questions) this.ux.navigation.navigate(['search'])
    else this.processor.setAlreadySelectedQuestions()
  }

  












  public setRowColor(index):'odd'|'even' {
    return this.processor.setRowColor(index) 
  }














  public onUser_ToggleQuestion():void {
    if (this.questionOptions) this.processor.toggleQuestion(this.questionOptions)
  }














  public onUser_NavigateBack() {
    this.ux.events.Ux_onOverlay.next({ display: false })
  }

}
