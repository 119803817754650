<form [formGroup]="form" [ngClass]="deviceClasses">
  <section id="question">
    <h4>Question:</h4>
    <div class="formContent">
      <textarea formControlName="question" rows="6" pTextarea></textarea>
    </div>
  </section>
  <section id="answer">
    <h4>Answer:</h4>
    <div id="answerContainer" class="formContent">
      <p-radioButton formControlName="answer" label="True" name="answer" value="true"></p-radioButton>
      <p-radioButton formControlName="answer" label="False" name="answer" value="false"></p-radioButton>
    </div>
  </section>
  <section id="reason">
    <h4>Reason:</h4>
    <div class="formContent">
      <textarea formControlName="reason" rows="6" pTextarea [placeholder]="reasonFieldDescription"></textarea>
    </div>
  </section>
  <section id="tags" *ngIf="tags && tags.length > 0">
    <h4>Tag:</h4>
    <div class="formContent">
      <p-multiSelect formControlName="tags" [options]="tags" defaultLabel="Select One" optionLabel="name"></p-multiSelect>
    </div>
  </section>
  <section id="feedback">
    <hr class="formContent">
    <div *ngIf="feedbackMessage" id="feedbackMessage">{{ feedbackMessage }}</div>
  </section>
  <section id="actions">
    <button id="buttonSubmit" [label]="submitButtonLabel" class="buttonTertiary" [disabled]="!canSubmit" (click)="onUser_submit()" pButton></button>
    <button label="Cancel" class="buttonPrimary" (click)="onUser_cancel()" pButton></button>
  </section>
</form>
