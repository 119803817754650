import { EventsUxService } from '@services/ux/events/events-ux.service'
import { EventsAppService } from '../events/events-app.service'
import { ILogsService } from './logs.service.interface'
import { LogsCommonService } from '@library/common/logs-common/client/logs-common.service'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class LogsService extends LogsCommonService implements ILogsService {

  
  constructor(
    protected override app:EventsAppService,
    protected override ux:EventsUxService,
    protected override http:HttpClient
  ) { 
    super(app, ux, http)
  }














  protected setEnvironment():void {
    this.saas = environment
  }

}