export class HttpOptions {

  public headers:any
  public params:any
  public body:any


  constructor(vars?:{ params?:any, body?:any }) {
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    if (vars && vars.params) this.params = vars.params
    if (vars && vars.body) this.body = vars.body
  }

}