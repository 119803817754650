import { HttpClient } from "@angular/common/http";
import { EventsAppService } from "@services/app/events/events-app.service";
import { IUsersLiason } from './UsersLiason.service.interface'
import { environment } from '@env/environment'
import { UsersLiasonCommon } from '@library/common/generic-common/typings/user/client/UsersLiason-common.service'
import { Injectable } from "@angular/core";
import { TUserFactory } from '@library/common/generic-common/typings/user/typings/TUser.factory'
import { TUserSettings } from '@library/common/generic-common/typings/user/typings/TUser.settings'
import { uiUserDetails } from '@library/common/generic-common/typings/user/typings/uiUser.class'


@Injectable({
  providedIn: 'root'
})
export class UsersLiason extends UsersLiasonCommon implements IUsersLiason {

  constructor(
    protected override http:HttpClient,
    protected event:EventsAppService
  ) {
    super(http, event)
  }

  // protected override assignEndpoints() {

  // }

  
  // protected override assignEnvironment():any {
    // this.saas = environment
  // }

  // protected override assignFactory() {
    // this.factory = TUserFactory
    // this.factory.settings = TUserSettings
  // }

  // protected override assignUiModelWrapper() {
    // this.uiModelWrapper = uiUserDetails
  // }

  public override create(properties?:any) { 
    let record = (this.model as any).create(properties.table.split('.')[0])
    if (properties) for (let [ property, value ] of Object.entries(properties)) record[property] = value
    if (this.model) record = new this.model(record)
    return record
  }

}