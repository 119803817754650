<form [formGroup]="form" [ngClass]="deviceClasses">
  <h3>Question:</h3>
    <div class="formContent">
      <textarea rows="6" pTextarea>{{ uiQuestion }}</textarea>
    </div>
  <div *ngIf="mode === 'exam'">
    <h3>Your Answer:</h3>
    <div class="formContent">
      <p-radioButton label="True" name="userAnswer" value="true" formControlName="userAnswer"></p-radioButton>
      <p-radioButton label="False" name="userAnswer" value="false" formControlName="userAnswer"></p-radioButton>
    </div>
  </div>
  <div *ngIf="mode === 'grade'">
    <h2>Grade Your Answer:</h2>
    <div class="formContent">
      <h3>Your Answer was:</h3>
        <div class="formContent">
          <p-radioButton label="True" name="userAnswer" value="true" formControlName="userAnswer"></p-radioButton>
          <p-radioButton label="False" name="userAnswer" value="false" formControlName="userAnswer"></p-radioButton>
        </div>
      <h3>Correct Answer is:</h3>
        <div class="formContent">
          <p-radioButton label="True" name="answer" value="true" formControlName="answer"></p-radioButton>
          <p-radioButton label="False" name="answer" value="false" formControlName="answer"></p-radioButton>
        </div>
      <h3>Reason:</h3>
        <div class="formContent">
          <textarea rows="6" pTextarea>{{ uiReason }}</textarea>
        </div>
      <h3>Grade:</h3>
        <div class="formContent">
          <p-dropdown [options]="correctOptions" formControlName="correct" placeholder="Select One" optionLabel="name"></p-dropdown>
        </div>
    </div>
  </div>
  <hr class="formContent">
  <div id="buttonContainer">
    <button label="Next" class="buttonTertiary" (click)="onUser_Submit()" pButton></button>
  </div>
</form>
