import { Injectable } from '@angular/core';
import { ConciseService } from '@library/common/generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract'
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout'
import { TOnScreenLayout } from '@library/common/generic-common/client/typings/TScreenLayout.type';
import { IScreenService } from './screen.service.interface'
import { EventsUxService } from '../events/events-ux.service';


@Injectable({
  providedIn: 'root'
})
export class ScreenService extends ConciseService implements IScreenService {

  public get deviceClasses():string[] { return Object.values(this.summary) as string[] || [ ] as string[] }
  public get summary():TOnScreenLayout { return { device: this.device, orientation: this.orientation, size: this.size } }
  public device!:TOnScreenLayout['device']
  public size!:TOnScreenLayout['size']
  public orientation!:TOnScreenLayout['orientation']


  constructor(
    public override events:EventsUxService,
    private screen:BreakpointObserver,
  ) {
    super(events)
    this.init()
  }














  private init() {
    this.screen.observe(Breakpoints.Web).subscribe(resolution => this.onWebResolution(resolution))
    this.screen.observe(Breakpoints.WebPortrait).subscribe(resolution => this.onWebPortraitResolution(resolution))
    this.screen.observe(Breakpoints.WebLandscape).subscribe(resolution => this.onWebLandscapeResolution(resolution))
    this.screen.observe(Breakpoints.Handset).subscribe(resolution => this.onHandsetResolution(resolution))
    this.screen.observe(Breakpoints.HandsetLandscape).subscribe(resolution => this.onHandsetLandscapeResolution(resolution))
    this.screen.observe(Breakpoints.HandsetPortrait).subscribe(resolution => this.onHandsetPortraitResolution(resolution))
    this.screen.observe(Breakpoints.Tablet).subscribe(resolution => this.onTabletResolution(resolution))
    this.screen.observe(Breakpoints.TabletLandscape).subscribe(resolution => this.onTabletLandscapeResolution(resolution))
    this.screen.observe(Breakpoints.TabletPortrait).subscribe(resolution => this.onTabletPortraitResolution(resolution))
    this.screen.observe(Breakpoints.XSmall).subscribe(resolution => this.onXSmallResolution(resolution))
    this.screen.observe(Breakpoints.Small).subscribe(resolution => this.onSmallResolution(resolution))
    this.screen.observe(Breakpoints.Medium).subscribe(resolution => this.onMediumResolution(resolution))
    this.screen.observe(Breakpoints.Large).subscribe(resolution => this.onLargeResolution(resolution))
    this.screen.observe(Breakpoints.XLarge).subscribe(resolution => this.onXLargeResolution(resolution))
  }














  private onWebResolution(resolution:BreakpointState) {
    if (resolution.matches) this.device = 'pc'
  }













  
  private onWebPortraitResolution(resolution:BreakpointState) {
    if (resolution.matches) this.orientation = 'portrait'
  }














  private onWebLandscapeResolution(resolution:BreakpointState) {
    if (resolution.matches) this.orientation = 'landscape'
  }














  private onHandsetResolution(resolution:BreakpointState) {
    if (resolution.matches) this.device = 'mobile'
  }














  private onHandsetLandscapeResolution(resolution:BreakpointState) {
    if (resolution.matches) this.orientation = 'landscape'
  }














  private onHandsetPortraitResolution(resolution:BreakpointState) {
    if (resolution.matches) this.orientation = 'portrait'
  }














  private onTabletResolution(resolution:BreakpointState) {
    if (resolution.matches) this.device = 'tablet'
  }














  private onTabletLandscapeResolution(resolution:BreakpointState) {
    if (resolution.matches) this.orientation = 'landscape'
  }














  private onTabletPortraitResolution(resolution:BreakpointState) {
    if (resolution.matches) this.orientation = 'portrait'
  }














  private onXSmallResolution(resolution:BreakpointState) {
    if (resolution.matches) this.size = 'xsmall'
    this.summarize()
  }














  private onSmallResolution(resolution:BreakpointState) {
    if (resolution.matches) this.size = 'small'
    this.summarize()
  }














  private onMediumResolution(resolution:BreakpointState) {
    if (resolution.matches) this.size = 'medium'
    this.summarize()
  }














  private onLargeResolution(resolution:BreakpointState) {
    if (resolution.matches) this.size = 'large'
    this.summarize()
  }














  private onXLargeResolution(resolution:BreakpointState) {
    if (resolution.matches) this.size = 'xlarge'
    this.summarize()
  }














  private summarize() {
    this.events.Ux_onScreenLayout.next(this.summary)
  }

}
