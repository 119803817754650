import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { TSchedule } from '@library/datetime-common/typings/TSchedule.type';
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract';
import { exists } from '@library/common/generic-common/tools/utils/concise.exists';
import { QuizProcessorService } from '@private/models/quizzes/client/service/quiz-processor.service';
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent extends ConciseComponent {

  @Input('input') input:any
  @Output() output:EventEmitter<any> = new EventEmitter()

  public get uiRate() {
    if (exists(this, 'form.controls.rate.value')) return this.form.controls['rate'].value
  }
        
  public get uiUnit() {
    if (exists(this, 'form.controls.unit.value')) return (this.unitOptions.find(option => option.value === this.form.controls['unit'].value.value) as any).name
    else return ''
  }
        
  public get uiTime():string|undefined {
    return (exists(this, 'form.controls.time.value') && this.form.controls['time'].value) ? this.quizProcessor.getMilitaryTime(this.form.controls['time'].value) : undefined
  }

  public form!:FormGroup
  public unitOptions:{ name:string, value:string }[] = [ ]
  public nextQuiz:any
  public gmt!:number
  public minimumRate:number = 1


  constructor(
    public override ux:UxService,
    private quizProcessor:QuizProcessorService
  ) {
    super(ux)
  }














  protected override setupEventListeners() { }














  public override ngOnInit() {
    this
      .begin(this.ngOnInit)
      .createUnitOptions()
      .createForm()
      .patchUnitValue()
      .patchTimeValue()
      .previewFirstDue()
      .conclude(this.ngOnInit)
  }


      public createUnitOptions():this {
        this.unitOptions = [
          { name: "Day(s)", value: 'day' },
          { name: "Month(s)", value: 'month'},
          { name: "Monday(s)", value: 'monday' },
          { name: "Tuesday(s)", value: 'tuesday' },
          { name: "Wednesday(s)", value: 'wednesday' },
          { name: "Thursday(s)", value: 'thursday' },
          { name: "Friday(s)", value: 'friday' },
          { name: "Saturday(s)", value: 'saturday' },
          { name: "Sunday(s)", value: 'sunday' },
        ]
        return this
      }


      private createForm():this {
        this.form = new FormGroup({
          rate: new FormControl(this.input && this.input.amount || 1, { validators: [Validators.required, Validators.min(1), Validators.max(365) ]}),
          unit: new FormControl(null, { validators: [Validators.required] }),
          time: new FormControl(null, { validators: [Validators.required] })
        })
        return this
      }


      private patchUnitValue():this {
        let unit = this.unitOptions.find(option => option.value === (this.input && this.input.unit))
        this.form.controls['unit'].patchValue(unit)
        return this
      }


      private patchTimeValue():this {
        if (!this.input || !this.input.militaryTime) return this
        let value = this.quizProcessor.setMilitaryTime(this.input.militaryTime)
        this.form.controls['time'].patchValue(value)
        return this
      }


      private previewFirstDue():this {
        this.emit()
        return this
      }














  public onScheduleChange() {
    if (!this.uiUnit || !this.uiRate || !this.uiTime) return
    else this.emit()
  }















  public async fixTimePickerUiQuirk() {
    this.ux.layers["layer-main"].style.display = 'none'
    this.ux.layers["layer-background"].style.display = 'none'
    await this.sleepToAvoidTimePickerUiQuirk(1);
    this.ux.layers["layer-background"].style.display = 'block'
    this.ux.layers["layer-main"].style.display = 'block'
  }


      private sleepToAvoidTimePickerUiQuirk(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms))
      }














  private async emit()
  // :TSchedule 
  {
    if (!this.gmt) this.gmt = await this.quizProcessor.getUtcOffset()
    if (!this.form.controls['rate'].value || !this.form.controls['unit'].value || !this.uiTime) return
    let output = this.quizProcessor.createScheduleOutput(this)
    this.output.emit(output)
    // return output
  }

}
