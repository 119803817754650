<form *ngIf="form" [formGroup]="form" [ngClass]="deviceClasses">

  <section fadeInView *ngIf="mode !== 'reset' && mode !== 'confirm'" id="access">
    <div class="fieldsContainer">
      <div class="ui-inputgroup" style="display: flex;">
        <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
        <input formControlName="email" type="text" pInputText placeholder="Username" style="flex-grow: 1; max-width: 350px">
        <div style="flex-grow: 1"></div>
      </div>
      <div class="ui-inputgroup" style="display: flex; align-items: baseline;">
        <span class="ui-inputgroup-addon"><i class="pi pi-key"></i></span>
        <input formControlName="password" type="password" pPassword placeholder="Password" [feedback]="mode === 'signup'" style="flex-grow: 1; max-width: 350px">
        <div style="flex-grow: 1"></div>
      </div>
    </div>
    <hr class="formContent">
    <div class="actions">
      <div id="containerLogin">
        <button label="Login" class="buttonPrimary" (click)="onUser_submit('login')" pButton [disabled]="form.invalid"></button>
      </div>
      <div id="containerOr">Or</div>
      <div id="containerSignUp">
        <button label="Sign Up"class="buttonPrimary" (click)="onUser_submit('signup')" pButton [disabled]="form.invalid"></button>
      </div>
      <div id="containerForgotPW">
        <button label="Forgot Password?" class="buttonTertiary" (click)="onUser_submit('reset')" [disabled]="form.controls['email'].invalid" pButton></button>
      </div>
    </div>
  </section>

  <section fadeInView *ngIf="mode === 'reset'" id="reset">
    <h3>Reset Password Code</h3>
    <form [formGroup]="form">
      <div class="fieldsContainer">
        <label for="code">
          <input type="text" pInputText formControlName="code" placeholder="Enter Password Reset Code Here" name="code" />
        </label>
      </div>
      <hr class="formContent">
      <div class="actions">
        <button label="Reset Password" class="buttonPrimary" [disabled]="form.controls['code'].invalid" (click)="onUser_submit('confirm')" pButton></button>
        <button label="Cancel" class="buttonTertiary" (click)="onUser_cancel()" pButton></button>
      </div>
    </form>
  </section>

  <section fadeInView *ngIf="mode === 'confirm'" id="confirm">
    <h3>Enter New Password</h3>
    <form [formGroup]="form">
      <div class="fieldsContainer">
        <label for="passwordNew" id="passwordNew">
          <input type="password" formControlName="passwordNew" placeholder="New Password" name="passwordNew" pInputText />
        </label>
        <label for="passwordConfirm" id="passwordConfirm">
          <input type="password" formControlName="passwordConfirm" placeholder="Confirm New Password" name="passwordConfirm" pInputText />
        </label>
      </div>
      <hr class="formContent">
      <div id="confirmActions" class="actions">
        <button label="Confirm New Password" class="buttonAction" [disabled]="newPasswordInvalid" (click)="onUser_submit('changed')" pButton></button>
        <button label="Cancel" class="buttonTertiary" (click)="onUser_cancel()" pButton></button>
      </div>
    </form>
  </section>

</form>