<main [ngClass]="deviceClasses">
  <div id="prompt">
    <section id="header">{{ title }}</section>
    <section id="body">
      <section id="message"><p>{{ message }}</p></section>
      <section id="actions">
        <hr>
        <div id="buttons">
          <button [label]="labelConfirm" (click)="onConfirmButton()" class="buttonTertiary" pButton></button>
          <button [label]="labelDismiss" (click)="onDismissButton()" class="" pButton></button>
        </div>
      </section>
    </section>
  </div>
</main>