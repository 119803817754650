import { IAccountManagerService } from './account-manager.service.interface'
import { AccountManagerCommonService } from '@library/common/account-common/account-manager-common.service'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { Injectable } from '@angular/core'
import { EventsAppService } from '@services/app/events/events-app.service'
import { RegistrationManagerService } from './registration/registration-manager.service'
import { LoginsService } from './logins/logins.service'
import { AuthService } from './auth/auth.service'
import { DateTimeService } from '../datetime/datetime.service'
import { StateService } from '@services/app/state/state.service'


@Injectable({
  providedIn: 'root'
})
export class AccountManagerService extends AccountManagerCommonService implements Exactly<IAccountManagerService, AccountManagerCommonService> {


  constructor(
    protected override events:EventsAppService,
    protected override registration:RegistrationManagerService,
    protected override logins:LoginsService,
    protected override auth:AuthService,
    protected override datetime:DateTimeService,
    protected override state:StateService
  ) {
    super(events, registration, logins, auth, datetime, state)
  }

  

}
