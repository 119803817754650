import { Injectable } from '@angular/core';
import { TQuestionPresentation, TQuestionPersistence } from '@private/models/questions/types/question.type'
import { TTag } from '@private/models/tags/types/tag.type';
import { AppService } from '@services/app/app.service';
// import { settings } from '@private/models/questions/types/_question.settings';
import Question from '@private/models/questions/types/question.model';
import { TUpdateResponse } from '@library/common/generic-common/server/typings/TUpdateRequest.type';
import { TReadResponse } from '@library/common/generic-common/server/typings/TReadRequest.type';
import { ConciseService } from '@library/common/generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract';


@Injectable({
  providedIn: 'root'
})
export class QuestionProcessorService extends ConciseService {

  // public settings:typeof settings = settings
  public model:typeof Question = Question


  constructor(
    private app:AppService
  ) { 
    super(app)
    // TQuestionFactory.settings = settings
  }














  create():any {
    return new this.model(this.app.state.accountId)
  }














  public getRecord(params:{ id:string }) {
    return Promise.all([
      this.app.api.models.questions.get.id({ value: params.id }),
      this.app.api.models.tags.get.all()
    ])
  }














  public getDataSecondary():Promise<any>[] {
    return [this.app.api.models.tags.get.all()]
  }














  public update(question:TQuestionPersistence):Promise<TUpdateResponse> {
    return this.app.api.models.questions.update({ item: question })
  }













  
  public mutateFormOutput(question:TQuestionPresentation):TQuestionPersistence {
    question.links.tag = { } as any
    question.tags?.forEach((tag:any) => question.links.tag[tag.id] = null)
    delete question.tags
    if (question.type === 'boolean') question.answer = (question.answer === 'true');
    if (question.type === 'multiple') delete question.newAnswerOption
    if (question.type === 'multiple') delete question.optionEdit
    return question
  }














  public setTags(params:{ question:TQuestionPresentation, tags:TTag[] }):TTag[] {
    return params.tags.filter(tagOption => (tagOption.id in params.question.links.tag) ? tagOption : undefined)
  }

}
