import { OnInit, OnDestroy, ViewContainerRef, HostListener, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConciseClass } from '../ConciseClass/ConciseClass.abstract'
import { TLogs_onUserAction, TLogs_onCodeCall } from '../../../../../logs-common/client/events/ux/logs-ux-common.events.io';
import { TStateGeneric } from '../../../typings/TStateGeneric.type';
import { IConciseComponent } from './ConciseComponent.abstract.interface'
import { Exactly } from '../../../typings/Exactly';
import { exists } from '../../../../../generic-common/tools/utils/concise.exists';
import { TDynamicProperty } from '../../../typings/TDynamicProperty.type'


@Directive()
export abstract class ConciseComponent extends ConciseClass implements OnInit, OnDestroy, Exactly<IConciseComponent, ConciseComponent> {


  @HostListener('window:resize', ['$event']) protected onScreenChange() { this.render() }

  public get is():TStateGeneric|undefined {
    if (!this.requirements) return
    else if (this.requirements.includes('warning')) return 'warning'
    else if (this.requirements.includes('failure')) return 'failure'
    else if (this.requirements.every(requirement => ((this as TDynamicProperty )[requirement] !== undefined && (this as TDynamicProperty)[requirement] !== null))) return 'ready'
    else return 'working'
  }

  public get deviceClasses():string[] {
    return (exists(this, 'ux.screen.deviceClasses') ? this.ux.screen.deviceClasses : null )
  }
  
  protected override events?:any
  protected eventSubscriptions:Subscription[] = [ ]
  protected requirements:string[] = [ ]
  

  constructor(
    protected ux:any
  ) {
    super(ux)
  }













      
  public ngOnInit() {
    this.begin(this.ngOnInit)
    this.setupEventListeners()
    this.ngOnInitInstance()
    this.initAbstractPage()
    this.conclude(this.ngOnInit)
  }

  
      protected setupEventListeners():void { }


      protected ngOnInitInstance():void { }


      protected initAbstractPage() {
        let getData:Function = (this as any).getData
        if (!getData) return
        this.begin(getData)
        this.pageLoad()
      }

      
      protected pageLoad():void { this.conclude((this as any).getData) }














  protected render() {
    (this.ux.screen.device === 'mobile') ? this.formatForMobile() : this.formatForPc()
    this.formatApply()
  }














  protected onUserAction(params:{ function:Function, params?:any }) {
    this.ux.events.Logs_onUserAction.next({ method: params.function.name, origin: this.constructor.name, details: params } as TLogs_onUserAction)
  }














  protected begin(process:Function):this {
    this.requirements.push(`${ process.name }...`)
    this.ux.events.Ux_onStateChange.next({ is: 'working' })
    return this
  }













  
  protected conclude(process:Function):this {
    this.requirements = this.requirements.filter(requirement => requirement !== `${ process.name }...`)
    this.ux.events.Ux_onStateChange.next({ is: 'ready' })
    return this
  }













      
  protected override onCall(params:{ function:Function, params?:any, result?:any, results?:any, event?:any }):void { 
    let event = { origin: this.constructor.name, method: params.function.name, details: params.params || params.result || params.results || params.event } as TLogs_onCodeCall
    let superDependency = this['app' as keyof this] || this['ux'] || this['events' as keyof this]
    if (params.function.name.includes('onUser_')) {
      if (superDependency.Logs_onUserAction) superDependency.Logs_onUserAction.next(event)
      else if (exists(superDependency, 'events.Logs_onUserAction')) superDependency.events.Logs_onUserAction.next(event)
      else if (exists(this, 'ux.events')) this['ux'].events.Logs_onUserAction.next(event)
    }
    else {
      if (superDependency.Logs_onCodeCall) superDependency.Logs_onCodeCall.next(event)
      else if (superDependency.events) superDependency.events.Logs_onCodeCall.next(event)
    }
  }














  protected projectDynamicContentIntoContainer(params:Function|{ viewContainer:ViewContainerRef, component:any }):any {
    let { viewContainer, component } = (params.constructor === Function)? (params as Function)() : params
    viewContainer.clear()
    const componentFactory = this.ux.resolver.resolveComponentFactory(component)
    return viewContainer.createComponent(componentFactory)
  }














  protected formatForMobile() { }














  protected formatForPc() { }














  protected formatApply() { }



  









  
  public ngOnDestroy():void {
    this.eventSubscriptions.forEach(subscription => subscription.unsubscribe())
    this.ngOnDestroyInstance()
  }

  
      protected ngOnDestroyInstance() { }
  
}