<form [formGroup]="form" [ngClass]="deviceClasses">
  <section id="question">
    <h4>Question:</h4>
    <div class="formContent">
      <textarea formControlName="question" rows="6" pTextarea></textarea>
    </div>
  </section>
  <section id="answer">
    <h4>Add One Possible Answer Here</h4>
    <div class="formContent">
      <textarea formControlName="newAnswerOption" rows="6" pTextarea></textarea>
      <button label="Add Answer Option" (click)="onAddAnswerOption()" pButton></button>
      <div *ngIf="options">
        <!-- <h3>Set <span id="correctHighlightSpan">Correct</span> Answer:</h3> -->
        <h5>Set Correct Answer:</h5>
        <h6 id="swipeInstructions">Swipe an answer left for options.</h6>
        <ion-list inset="true">
          <ion-item-sliding *ngFor="let option of options" #item>
            <ion-item [color]="setItemColor(option)">
              <ion-label>{{ option.answer }}</ion-label>
            </ion-item>
            <ion-item-options side="end">
              <ion-item-option (click)="onSelectCorrectAnswer(option, item)">
                <ion-button color="tertiary">Set As Correct<ion-icon name="checkmark"></ion-icon></ion-button>
              </ion-item-option>
              <ion-item-option (click)="onEditAnswer(option, item)">
                <ion-icon name="create"></ion-icon>
              </ion-item-option>
              <ion-item-option (click)="onDeleteAnswer(option)">
                <ion-icon name="trash"></ion-icon>
              </ion-item-option>
            </ion-item-options>
            <div *ngIf="option.editing">
              <ion-input id="optionEdit" type="text" [value]="option.answer" formControlName="optionEdit"></ion-input>
              <ion-button (click)="onUpdateOption(option, item)">Update</ion-button>
            </div>
          </ion-item-sliding>
        </ion-list>
      </div>
    </div>
  </section>
  <section id="reason">
    <h4>Reason:</h4>
    <div class="formContent">
      <textarea formControlName="reason" rows="6" pTextarea [placeholder]="reasonFieldDescription"></textarea>
    </div>
  </section>
  <section id="tags" *ngIf="tags && tags.length > 0">
    <h4>Tags:</h4>
    <div class="formContent">
      <p-multiSelect formControlName="tags" [options]="tags" defaultLabel="Select One" optionLabel="name"></p-multiSelect>
    </div>
  </section>
  <section id="feedback">
    <hr class="formContent">
    <div *ngIf="feedbackMessage" id="feedbackMessage">{{ feedbackMessage }}</div>
  </section>
  <section id="actions">
    <button id="buttonSubmit" [label]="submitButtonLabel" class="buttonTertiary" [disabled]="!canSubmit" (click)="onUser_submit()" pButton></button>
    <button label="Cancel" class="buttonPrimary" (click)="onUser_cancel()" pButton></button>
  </section>
</form>
