import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular'
import { ReactiveFormsModule } from '@angular/forms'
import { FormsModule } from '@angular/forms'

import { FadeInViewDirective } from '@library/common/generic-common/client/animations/fade-in-view.directive'
import { FadeInSlowDirective } from '@library/common/generic-common/client/animations/fade-in-slow.directive'

import { HeaderComponent } from './header/header.component'
import { SchedulerComponent } from './scheduler/scheduler.component'
import { QuestionTextFormComponent } from './forms/BaseFormQuestion/question-text.form/question-text-form.component'
import { QuestionBooleanFormComponent } from './forms/BaseFormQuestion/question-boolean-form/question-boolean-form.component'
import { QuestionMultipleFormComponent } from './forms/BaseFormQuestion/question-multiple-form/question-multiple-form.component'
import { QuizFormComponent } from './forms/quiz-form/quiz-form.component'
import { TagFormComponent } from './forms/tag-form/tag-form.component'
import { QuestionaireTextComponent } from './forms/BaseFormQuestionaire/questionaire-text/questionaire-text.component'
import { QuestionaireBooleanComponent } from './forms/BaseFormQuestionaire/questionaire-boolean/questionaire-boolean.component'
import { QuestionaireMultipleChoiceComponent } from './forms/BaseFormQuestionaire/questionaire-multiple-choice/questionaire-multiple-choice.component'
import { SelectButtonModule } from 'primeng/selectbutton'
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea'
import { RadioButtonModule } from 'primeng/radiobutton'
import { SpinnerModule } from 'primeng/spinner'
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
// import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar'
// import { ConfirmationService } from 'primeng/api'
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FeedbackHeaderComponent } from './feedback-header/feedback-header.component'
import { FeedbackToasterComponent } from './feedback-toaster/feedback-toaster.component'
import { DeviceComponent } from './device/device.component'
import { LoadingComponent } from './loading/loading.component'
import { CustomGridActions } from './grid-cells/custom-grid-actions/custom-grid-actions.component'
import { FrontDoorFormWidget } from './forms/front-door/front-door.widget'
import { CellMobile } from './cell-mobile/cell-mobile.component'
import { AccordionModule } from 'primeng/accordion';

import { AlertComponent } from './alert/alert.component'
import { ConfirmComponent } from './confirm/confirm.component'

import { PolicyDataUsage } from './policies/data-usage/policy-data-usage.component'
import { PolicyPrivacy } from './policies/privacy/policy-privacy.component'

// import { AddQuestionsPage } from '@private/add-questions/add-questions.page'
// import { DashboardQuizSectionDirective } from '../directives/DashboardQuizSectionDirective.directive'
// import { DashboardBrowseSectionDirective } from '../directives/DashboardBrowseSectionDirective.directive'
// import { DashboardSettingsSectionDirective } from '../directives/DashboardSettingsSectionDirective.directive'




@NgModule({
  declarations: [
    FadeInViewDirective,
    FadeInSlowDirective,
    HeaderComponent,
    SchedulerComponent,
    QuestionTextFormComponent,
    QuestionBooleanFormComponent,
    QuestionMultipleFormComponent,
    QuizFormComponent,
    TagFormComponent,
    QuestionaireTextComponent,
    QuestionaireBooleanComponent,
    QuestionaireMultipleChoiceComponent,
    FeedbackHeaderComponent,
    FeedbackToasterComponent,
    DeviceComponent,
    LoadingComponent,
    CustomGridActions,
    FrontDoorFormWidget,
    CellMobile,
    AlertComponent,
    ConfirmComponent,
    PolicyDataUsage,
    PolicyPrivacy
    // AddQuestionsPage,
    // DashboardQuizSectionDirective,
    // DashboardBrowseSectionDirective,
    // DashboardSettingsSectionDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    DropdownModule,
    MultiSelectModule,
    InputTextareaModule,
    RadioButtonModule,
    SpinnerModule,
    CheckboxModule,
    FieldsetModule,
    // ConfirmDialogModule,
    CalendarModule,
    ToggleButtonModule,
    AccordionModule
  ],
  exports: [
    HeaderComponent,
    SchedulerComponent,
    QuestionTextFormComponent,
    QuestionBooleanFormComponent,
    QuestionMultipleFormComponent,
    QuizFormComponent,
    TagFormComponent,
    QuestionaireTextComponent,
    QuestionaireBooleanComponent,
    QuestionaireMultipleChoiceComponent,
    FadeInViewDirective,
    FadeInSlowDirective,
    SelectButtonModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    DropdownModule,
    InputTextareaModule,
    RadioButtonModule,
    SpinnerModule,
    CheckboxModule,
    FieldsetModule,
    MultiSelectModule,
    // ConfirmDialogModule,
    CalendarModule,
    FeedbackHeaderComponent,
    FeedbackToasterComponent,
    DeviceComponent,
    ToggleButtonModule,
    LoadingComponent,
    CustomGridActions,
    FrontDoorFormWidget,
    CellMobile,
    AccordionModule,
    AlertComponent,
    ConfirmComponent,
    PolicyDataUsage,
    PolicyPrivacy,
    // DashboardQuizSectionDirective,
    // DashboardBrowseSectionDirective,
    // DashboardSettingsSectionDirective,
  ],
  providers: [
    // ConfirmationService
  ],
  // entryComponents: [
  //   // AddQuestionsPage,
  //   CellMobile
  // ]
})
export class WidgetsModule { }
