import { Injectable } from '@angular/core';
// import { ServiceBase } from '@assets/classes/Abstract/ClassBase/ServiceBase/ServiceBase.class'
// import { IMobileService } from './mobile.service.interface'
// import { EventsAppService } from '@services/app/events/events-app.service';
import { EventsUxService } from '@services/ux/events/events-ux.service';
import { Capacitor, Plugins } from '@capacitor/core'
import { PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications'
import { Device } from '@capacitor/device'
// import { Dialog } from '@capacitor/dialog'
// const { Modals } = Plugins


@Injectable({
  providedIn: 'root'
})
export class MobileService 
// extends ServiceBase implements IMobileService 
{


  constructor(
    public events:EventsUxService,
  ) {
    // super(MobileService, events)
    this.init()
  }














  private init() {
    try { this.registerMobilePushNotifications() }
    catch (error) { console.error('Try init Push Notifications error:', error) }
  }


      private registerMobilePushNotifications() {
      if (!Capacitor.isPluginAvailable('PushNotifications')) return
        console.log('registerMobilePushNotifications...')
        PushNotifications.register()
        PushNotifications.addListener('registration', (token:Token) => this.onRegistration(token));
        PushNotifications.addListener('registrationError', (error: any) => { console.error('Error on registration: ' + JSON.stringify(error)); });
        PushNotifications.addListener('pushNotificationReceived', (notification:PushNotificationSchema) => this.onPushNotificationReceived(notification));
      }














  public async onRegistration(token:Token) {
    let device = await Device.getInfo() as any
        delete device.appBuild
        delete device.appVersion
        delete device.batteryLevel
        delete device.isCharging
        delete device.isVirtual
        device.fcmToken = token.value
        this.events.Ux_onDeviceInfo.next(device)
  }
















  private onPushNotificationReceived(notification:PushNotificationSchema) {
      var pushAlerturSound = new Audio('assets/audio.mp3');
      pushAlerturSound.play()
      // Dialog.alert({
      //   title: notification.title,
      //   message: notification.body as string
      // })
      // Modals.alert({
      //   title: notification.title,
      //   message: notification.body
      // })
  }

}
