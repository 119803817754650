import { IDateTimeService } from './datetime.service.interface'
import { DateTimeCommonService } from '@library/common/datetime-common/client/datetime-common.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { EventsAppService } from '@services/app/events/events-app.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'



@Injectable({
  providedIn: 'root'
})
export class DateTimeService extends DateTimeCommonService implements Exactly<IDateTimeService, DateTimeService> {
 

  constructor(
    protected override events:EventsAppService,
    protected override http:HttpClient
  ) {
    super(events, http)
  }














  // protected assignEnvironment() {
    // this.saas = environment
  // }














  // protected override assignEndpoints():void {
    
  // }

}