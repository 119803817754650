<ion-content fadeInView>
  <main id="grid" [ngClass]="deviceClasses">
    <app-header></app-header>
    <section id="questions">
      <table>
        <th>Include Questions:</th>
        <tr *ngFor="let option of questionOptions; let index = index" [ngClass]="setRowColor(index)">
          <td class="listItem">
            <p-checkbox [label]="option.question" [binary]="true" name="options" [(ngModel)]="option.selected" (click)="onUser_ToggleQuestion()"></p-checkbox>
          </td>
        </tr>
      </table>
    </section>
    <section id="actions">
      <hr class="formContent">
      <button pButton type="button" label="Back" (click)="onUser_NavigateBack()"></button>
    </section>
  </main>
</ion-content>
