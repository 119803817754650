import { Component } from "@angular/core";
import { ConciseComponent } from "@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract";
import { UxService } from "@services/ux/ux.service";


@Component({
  selector: 'app-confirm',
  templateUrl: 'confirm.component.html',
  styleUrls: ['confirm.component.scss']
})
export class ConfirmComponent extends ConciseComponent {

  public title:string = 'Confirm'
  public message:string
  public labelConfirm:string = 'Confirm'
  public labelDismiss:string = 'Cancel'
  private onConfirm?:Function
  private onDismiss?:Function


  constructor(
    protected override ux:UxService
  ) {
    super(ux)
    this.ux.events.Ux_onOverlay.subscribe(event => this.onOverlay(event))
  }


  











  public onConfirmButton() { 
    if (this.onConfirm) this.onConfirm(true)
    this.closeConfirmationPrompt()
  }














  public onDismissButton() { 
    if (this.onDismiss) this.onDismiss(false)
    this.closeConfirmationPrompt()
  }














  private closeConfirmationPrompt() {
    this.ux.events.Ux_onOverlay.next({ display: false })
  }














  private onOverlay(event) {
    if (event.component?.name === ConfirmComponent.name) 
      for (let [ property, value ] of Object.entries(event.params)) this[property] = value
  }

}