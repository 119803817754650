import { ICaptchaService } from './captcha.service.interface'
import { CaptchaCommonService } from '@library/common/captcha-common/client/captcha-common.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class CaptchaService extends CaptchaCommonService implements Exactly<ICaptchaService, CaptchaService> {

  constructor(
    protected override http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(http, events)
  }














  // protected assignEnvironment() {
    // this.saas = environment
  // }

}
