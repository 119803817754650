import { IDatabaseService } from './database.service.interface'
import { DatabaseCommonService } from '@library/common/database-common/client/database-common.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { EventsAppService } from '@services/app/events/events-app.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends DatabaseCommonService implements Exactly<IDatabaseService, DatabaseService> {

  constructor(
    protected override events:EventsAppService,
    protected override http:HttpClient
  ) {
    super(events, http)
  }














  // protected assignEnvironment() {
    // this.saas = environment
  // }
}
