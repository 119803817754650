import { Component, Input, OnInit } from '@angular/core';
import Question from '@private/models/questions/types/question.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TTag } from '@private/models/tags/types/tag.type';
import { BaseFormQuestion } from '../BaseFormQuestion.class'
import { QuestionProcessorService } from '@private/models/questions/client/service/question-processor.service'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-question-boolean-form',
  templateUrl: './question-boolean-form.component.html',
  styleUrls: ['./question-boolean-form.component.scss'],
})
export class QuestionBooleanFormComponent extends BaseFormQuestion implements OnInit {

  @Input() override input?:{ record:Question, supplementary: { tags:TTag[] } }
  public override tags!:TTag[]


  constructor(
    protected override ux:UxService,
    protected override processor:QuestionProcessorService
  ) {
    super(ux, processor)
  }















  protected override hookSetModelPost() {
    this.isolatedCopy.type = 'boolean'
    return this
  }














  protected setForm() {
    this.form = new FormGroup({
      question: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] }),
      answer: new FormControl(null, { validators: [Validators.required]}),
      reason: new FormControl(null),
      tags: new FormControl(null)
    })
  }














  public getCheckedState(value:boolean):boolean {
    if (value === true) return true
    else return false
  }

}
