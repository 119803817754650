import { trigger, transition, style, animate } from '@angular/animations'


export let slideInOut = [
  trigger('slideInOut', [
    transition(':enter', [
      style({transform: 'translateY(-10%)'}),
      animate('1000ms ease-in', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({transform: 'translateY(100%)'}))
    ])
  ])
]
