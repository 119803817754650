import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
export type TCellMobileRecord = { id:string, primaryDescriptor:string|number, secondaryDescriptor:string|number|null, primaryAction:string|number, secondaryAction:string|number }


@Component({
  selector: 'cell-mobile',
  templateUrl: './cell-mobile.component.html',
  styleUrls: ['./cell-mobile.component.scss']
})
export class CellMobile implements ICellRendererAngularComp {

  public id!:string
  public primaryDescriptor!:string|number
  public secondaryDescriptor!:string|number|null
  public primaryAction!:any
  public secondaryAction!:any

  constructor() { }














  public agInit(params:ICellRendererParams):void {
    for (let [ key, value ] of Object.entries(params.data)) this[key] = value
  }













  
  public refresh(params:any):boolean {
    return false
  }

}