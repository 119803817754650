import { HttpClient } from '@angular/common/http'
import { ApiService } from '../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { HttpOptions } from '../../generic-common/client/classes/HttpOptions/HttpOptions.class'
import { IDatabaseCommonService } from './database-common.service.interface'
import { Exactly } from '../../generic-common/client/typings/Exactly';
import { TDatabaseLinkRequest } from '../typings/TDatabaseLinkRequest.type';
import { environment } from '@env/environment'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface'
import * as io from '../typings/io.index'


export abstract class DatabaseCommonService extends ApiService implements Exactly<IDatabaseCommonService, DatabaseCommonService> {

  protected apiId = environment.api.ids.database

  constructor(
    protected override events:IEventsAppCommonService,
    protected http:HttpClient,
  ) {
    super(events)
    this.events.Database_onUserDatabaseCreateRequest.subscribe(request => this.tablesUserCreate(request))
    this.events.Database_onRecordLinkRequest.subscribe(request => this.onRecordLinkRequest(request))
  }














  public batchRead(params:io.TBatchReadRequest):Promise<io.TBatchReadResponse> {
    return this.http.post(this.baseUrl + this.batchRead.name, new HttpOptions({ params })).toPromise() as Promise<io.TBatchReadResponse>
  }














  public tablesAppCreate(params:io.TTablesAppCreateRequest):Promise<io.TTablesAppCreateResponse> { 
    return this.http.post(this.baseUrl + this.tablesAppCreate.name, new HttpOptions({ params })).toPromise() as Promise<io.TTablesAppCreateResponse>
  }














  public tablesUserCreate(params:io.TTablesUserCreateRequest):Promise<io.TTablesUsersCreateResponse> { 
    return this.http.post(this.baseUrl + this.tablesUserCreate.name, new HttpOptions({ params })).toPromise()
      .then(result => {
        this.events.Database_onUserDatabaseCreateResponse.next(result as io.TTablesUsersCreateResponse)
        return result as unknown as Promise<io.TSkeletonAlerturCreateResponse>
      })
      .catch(error => {
        this.events.Database_onUserDatabaseCreateResponse.next({ input: null, output: { success: false, details: { },  thirdParty: { } } })
        return error
      })
  }














  public linksLink(params:io.TLinksLinkRequest):Promise<io.TLinksLinkResponse> {
    return this.http.post(this.baseUrl + this.linksLink.name, new HttpOptions({ params })).toPromise() as Promise<io.TLinksLinkResponse>
  }














  public linksQueue(params:io.TLinksQueueRequest):Promise<io.TLinksQueueResponse> {
    return this.http.post(this.baseUrl + this.linksQueue.name, new HttpOptions({ params })).toPromise() as Promise<io.TLinksQueueResponse>
  }














  public linksUnlink(params:io.TLinksUnlinkRequest):Promise<io.TLinksUnlinkResponse> {
    return this.http.post(this.baseUrl + this.linksUnlink.name, new HttpOptions({ params })).toPromise() as Promise<io.TLinksUnlinkResponse>
  }














  public scaleUpdate(params:io.TScaleUpdateRequest):Promise<io.TScaleUpdateResponse> {
    return this.http.post(this.baseUrl + this.scaleUpdate.name, new HttpOptions({ params })).toPromise() as Promise<io.TScaleUpdateResponse>
  }














  // public skeletonAlertur(params:io.TSkeletonAlerturCreateRequest):Promise<io.TSkeletonAlerturCreateResponse> {
  //   return this.http.post(this.baseUrl + this.skeletonAlertur.name, new HttpOptions({ params })).toPromise()
  //     .then((result:io.TSkeletonAlerturCreateResponse) => {
  //       this.events.Database_onUserDatabaseCreateResponse.next(result)
  //       return result as unknown as Promise<io.TSkeletonAlerturCreateResponse>
  //     })
  //     .catch(error => {
  //       this.events.Database_onUserDatabaseCreateResponse.next({ input: null, output: { success: false, details: error, thirdParty: null } })
  //       return error
  //     })
  // }















  private onRecordLinkRequest(requests:TDatabaseLinkRequest[]) {
    requests.forEach(request => {
      let action = (request.link) ? this.linksLink : this.linksUnlink
      action(request)
        .then(result => this.onSuccess(result))
        .catch(error => this.onFailure(error))
    })
  }


      private onSuccess(result:any) {
        if (!result) this.onFailure(result)
        else return // Background task, do nothing, no user feedback
      }


      private onFailure(result:any) {
        this.hasFailed({ at: this.onFailure, error: result })
      }

}
