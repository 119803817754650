import { ISettingsUxCommonService } from './settings-ux-common.service.interface'


export abstract class SettingsUxCommonService implements ISettingsUxCommonService {

  public standardMessages = {
    errorGeneric: 'An error occurred, please try again.',
    errorDataRetrieval: 'Unable to load data, please try reloading.',
    emailInvalid: 'This provided email address is not valid.',
    genericMissingInfo: 'Some required information is missing.',
    deferredToBackgroundWorker: 'Since there are many records, this process is running in the background.  You will receive another notification like this when it is complete.',
    resetCodeSent: 'A reset code has been sent to the email address',
    emailVerify: 'Before a new user can log in they must check their email for a message from "no-reply@verificationemail.com" and click the verification link.',
    supportContacted: `Success.  Please allow 24 hours for support to respond.`,
    itemTransferSuccess: 'Item Transferred.',
    itemTransferFailure: 'Item Transfer Failed, please try again.',
    placesHierarchyError: `Was not able to load all of Places hierachy.  There may be some missing.  You could try reloading the page.`,
    recordCreated(model?:string) {
      return `${ (model) ? model : `Record` } Created`
    },
    recordCreatedFail(model?:string) {
      return `${ (model) ? model : `Record` } Create Failed`
    },
    recordSaved(model?:string) {
      return `${ (model) ? model : `Record` } Saved`
    },
    recordSaveFail(model?:string) {
      return `Unable to update ${ model || 'record' }.  Please try editing again.`
    },
    recordRemoved(model?:string) {
      return `${ (model) ? model : `Record` } Removed`
    },
    recordRemovedFail(model?:string) {
      return `${ (model) ? model : `Record` } Removal Failed.`
    },

    Event_RandomThoughtRemovalFailure: `Unable to remove original random thought.  It may still be in your scratchpad.  Please try deleting it from there.`
  }
}