import { TFactory } from '../../../../../../library/common/generic-common/client/classes/TFactoryV2/TFactory.abstract.class'
import { ModelDynamoDb } from '../../../../../../library/common/generic-common/typings/ModelDynamoDbV2/ModelDynamoDb.abstract'
import { TDevice } from './device.type'

export type temporaryNothing = any



class Device extends ModelDynamoDb {

  override table
  override id
  override createdAt
  override updatedAt
  fcmToken
  manufacturer
  model
  operatingSystem
  osVersion
  platform
  uuid
  notifications
  overridelinks

  constructor(record?) {
    super(record)
  }

}



export default Device







// export class TDeviceFactory extends TFactory {

//   protected static output:TDevice


//   protected static createStructure() {
//     this.output = {
//       table: `${ localStorage.getItem('accountId') }.devices`,
//       id: null as temporaryNothing,
//       createdAt: null as temporaryNothing,
//       updatedAt: null as temporaryNothing,
//       fcmToken: null as temporaryNothing,
//       manufacturer: null as temporaryNothing,
//       model: null as temporaryNothing,
//       operatingSystem: null as temporaryNothing,
//       osVersion: null as temporaryNothing,
//       platform: null as temporaryNothing,
//       uuid: null as temporaryNothing,
//       links: { },
//       notifications: true,
//     } as TDevice
//     return this
//   }

// }
