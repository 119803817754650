import { Injectable } from '@angular/core';
import { Exactly } from '@library/common/generic-common/client/typings/Exactly';
import { IApiService } from '@services//app/api/api.service.interface'
import { ModelsService } from '@services/app/api/models/models.service'
import { DateTimeService } from '@services/app/api/datetime/datetime.service';
import { ScheduleService } from '@services/app/api/schedule/schedule.service'
import { AccountManagerService } from '@services/app/api/account/account-manager.service'
import { CaptchaService } from '@services/app/api/captcha/captcha.service'
import { DatabaseService } from '@services/app/api/database/database.service'
import { SupportService } from '@services/app/api/support/support.service'


@Injectable({
  providedIn: 'root'
})
export class ApiService implements Exactly<IApiService, ApiService> {

  
  constructor(
    public readonly account:AccountManagerService,
    public readonly captcha:CaptchaService,
    public readonly database:DatabaseService,
    public readonly datetime:DateTimeService,
    public readonly models:ModelsService,
    public readonly schedule:ScheduleService,
    public readonly support:SupportService
  ) { }

}
