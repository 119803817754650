<div id="container">
  <div>
    <span class="clickable" (click)="onUser_NavigateDashboard()">Dashboard</span>
    <span *ngFor="let crumb of breadcrumbs" (click)="onUser_NavigateCrumb(crumb)" [ngClass]="isClickable(crumb)">  > {{ crumb.label }}</span>
  </div>
  <div id="iconContainer">
    <ion-icon class="clickable" name="speedometer" (click)="onUser_NavigateDashboard()"></ion-icon>
    <ion-icon class="clickable" name="search" (click)="onUser_NavigateSearch()"></ion-icon>
    <ion-icon class="clickable" name="settings" (click)="onUser_NavigateSettings()"></ion-icon>
  </div>
</div>
