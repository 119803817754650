import { Component } from '@angular/core';
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract';
import { TUiFeedback } from '@library/common/generic-common/client/typings/TUiFeedback.type';
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-feedback-header',
  templateUrl: './feedback-header.component.html',
  styleUrls: ['./feedback-header.component.scss'],
})
export class FeedbackHeaderComponent extends ConciseComponent {

  public message:string|null = null
  public state!:string
  public divStyle!:any
  private timeout!:number
  private heightTransitionInterval!:number


  constructor(
    public override ux:UxService
  ) { 
    super(ux)
  }














  protected override setupEventListeners() {
    this.eventSubscriptions.push(this.ux.events.Ux_onFeedbackPrimary.subscribe(request => this.onFeedback(request)))
  }














  public override ngOnInitInstance():void {
    this.timeout = 3000
    this.heightTransitionInterval = 400
  }













  
  public onFeedback(feedback:TUiFeedback) {
    this
      .setState(feedback)  
      .determineComponentHeight()
      .displayMessageWhenHeightGrows(feedback)
      .shrinkComponentAfterInterval()
  }


      private setState(feedback):this {
        this.state = feedback.state
        return this
      }


      private determineComponentHeight():this {
        this.divStyle = { height: '33px' }
        return this
      }


      private displayMessageWhenHeightGrows(feedback):this {
        setTimeout(()=> {
          this.message = feedback.message
        }, this.heightTransitionInterval)
        return this
      }


      private shrinkComponentAfterInterval() {
        setTimeout(()=> {
          this.message = null
          this.divStyle.height = '0px'
        }, this.timeout)
      }















  public get headingStyle() {
    return { fontSize: '20px', paddingTop: '.3rem' }
  }

}
