import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, NoPreloading } from '@angular/router';
// import { canActivate } from '@library/common/generic-common/tools/validators/auth-canMatch'
import { guardIsAuthorized } from '@library/common/generic-common/tools/validators/guard-canActivate-isAuthorized'


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./sections/public/home/home.module').then( m => m.HomePageModule) },
  { path: 'policies', loadChildren: () => import('./sections/public/policies/policies.module').then( m => m.PoliciesPageModule) },
  // { path: 'policies/privacy', loadChildren: () => import('./sections/public/policies/privacy/privacy.module').then( m => m.PoliciesPrivacyPageModule) },
  // { path: 'policies/data-usage', loadChildren: () => import('./sections/public/policies/data-usage/data-usage.module').then( m => m.DataUsagePageModule) },
  // { path: 'policies/data-removal', loadChildren: () => import('./sections/public/policies/data-removal/data-removal.module').then( m => m.DataRemovalPageModule) },
  { path: 'dashboard', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/features/dashboard/dashboard.module').then( m => m.DashboardPageModule) },
  { path: 'search', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/features/search/search.module').then( m => m.SearchPageModule) },
  { path: 'add-questions', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/features/add-questions/add-questions.module').then( m => m.AddQuestionsPageModule) },
  { path: 'exam/:id', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/features/exam/exam.module').then( m => m.ExamPageModule) },
  { path: 'review', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/features/review/review.module').then( m => m.ReviewPageModule) },
  { path: 'results', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/features/results/results.module').then( m => m.ResultsPageModule) },
  { path: 'preferences', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/preferences/client/page/preferences.module').then( m => m.PreferencesPageModule) },
  { path: 'question/:id', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/questions/client/page/question.module').then( m => m.QuestionPageModule) },
  { path: 'question', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/questions/client/page/question.module').then( m => m.QuestionPageModule) },
  { path: 'tag/:id', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/tags/client/page/tag.module').then( m => m.TagPageModule) },
  { path: 'tag', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/tags/client/page/tag.module').then( m => m.TagPageModule) },
  { path: 'quiz/:id', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/quizzes/client/page/quiz.module').then( m => m.QuizPageModule) },
  { path: 'quiz', canLoad: [guardIsAuthorized], loadChildren: () => import('./sections/private/models/quizzes/client/page/quiz.module').then( m => m.QuizPageModule) },
]


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
