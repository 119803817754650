import { Injectable } from '@angular/core';
import { AccountManagerService } from '@services/app/api/account/account-manager.service';
import { Subject } from 'rxjs';
import { Exactly } from '@library/common/generic-common/client/typings/Exactly';
import { StateService } from '@services/app/state/state.service';
import { EventsAppService } from '@services/app/events/events-app.service';
import { DateTimeService } from '@services/app/api/datetime/datetime.service';
import { DatabaseService } from '@services/app/api/database/database.service';
import { promisfyHandlers } from '@library/common/generic-common/tools/utils/promisfyHandlers';
import * as io from './public.service.io'
import { SupportService } from '@services/app/api/support/support.service';


@Injectable({
  providedIn: 'root'
})
export class PublicService implements Exactly<io.IPublicService, PublicService>  {


  public get hasLoggedIn():boolean { return this.state.hasLoggedIn }
  public get version():string { return this.state.version }
  public onLogin:Subject<io.TOnLoginEvent> = new Subject()
  public onPasswordForgot:Subject<io.TOnPasswordForgot> = new Subject()
  public onRegisterResponse:Subject<io.TOnRegisterResponse> = new Subject()
  public onPasswordForgotReset:Subject<io.TForgotResetReponse> = new Subject()
  public onPasswordChange:Subject<io.TonPasswordChange> = new Subject()
  public onDataRemovalRequest:Subject<io.TonDataRemovalResponse> = new Subject()


  constructor(
    public state:StateService,
    private events:EventsAppService,
    private account:AccountManagerService,
    private datetime:DateTimeService,
    private database:DatabaseService,
    private support:SupportService
  ) { 
    if (this.state.timezones.length === 0) this.datetime.timezones()
    this.events.Account_onLoginResponse.subscribe(result => this.onLogin.next(result))
    this.events.Account_onPasswordForgotResponse.subscribe(result => this.onPasswordForgot.next(result))
    this.events.Account_onRegisterResponse.subscribe(result => this.onRegisterResponse.next(result))
    this.events.Account_onUserEmailCheckAvailabilityResponse.subscribe(result => this.onEmailAvailability(result))
    this.events.Account_onPasswordForgotResetResponse.subscribe(result => this.onPasswordForgotReset.next(result))
    this.events.Account_onPasswordChangeResponse.subscribe(result => this.onPasswordChange.next(result))
    this.events.Support_onDataRemovalResponse.subscribe(result => this.onDataRemovalRequest.next(result))
  }














  public checkEmailAvailability(email:string):Promise<any> {
    this.account.userEmailCheckAvailability({ saasName: this.state.saasName, email })
    return new Promise(resolve=> promisfyHandlers(this.onEmailAvailability, resolve))
  }


      private onEmailAvailability(availability:boolean) { 
        (this.onEmailAvailability as any).resolve(availability)
      }














  public login(params:io.TLoginRequest):io.TLoginResponse {
    this.account.userLogin({ saasName: this.state.saasName, username: params.username, password: params.password })
  }














  public userPasswordForgot(username:string):void {
    this.account.userPasswordForgot({ saasName: this.state.saasName, username: username })
  }














  public userPasswordForgotReset(params:{ username:string, password:string, confirmationCode:string }):void {
    let request = Object.assign(params, { saasName: this.state.saasName, code: params.confirmationCode })
    this.account.userPasswordForgotReset(request)
  }














  public userPasswordChange(params:{ email:string, password:string, formerPassword:string }):void {
    let request = Object.assign(params, { saasName: this.state.saasName })
    this.account.userPasswordChange(request)
  }













  
  public signup(params:{ email:string, password:string }):void {
    this.account.accountRegister(params)
  }














  public onRemoveDataRequest(params:{ email:string }):void {
    this.support.dataRemoval({ saasName: this.state.saasName, email: params.email })
  }

}