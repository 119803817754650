<form [formGroup]="form" [ngClass]="deviceClasses">
  <section id="name">
    <h4>Tag Name:</h4>
    <div class="formContent">
      <input type="text" formControlName="name" name="name" pInputText />
    </div>
  </section>
  <section id="description">
    <h4>Description: (Optional)</h4>
    <div class="formContent">
      <textarea formControlName="description" name="description" rows="6" pInputTextarea></textarea>
    </div>
  </section>
  <section id="feedback">
    <hr class="formContent">
    <div id="feedbackMessage" *ngIf="feedbackMessage">{{ feedbackMessage }}</div>
  </section>
  <section id="actions">
    <button [label]="submitButtonLabel" class="buttonTertiary" [disabled]="!canSubmit" (click)="onUser_submit()" pButton></button>
    <button label="Cancel" class="buttonPrimary" (click)="onUser_cancel()" pButton></button>
  </section>
</form>
