import { Component, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UxService } from '@services/ux/ux.service';
import { TLayer } from '@library/common/generic-common/client/typings/TLayer.type';
import { PublicService } from '@public/service/public.service';
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract';
import { exists } from '@library/common/generic-common/tools/utils/concise.exists';
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists';

// @Injectable({
//   providedIn: 'root'
// })
// class whatever extends SplashScreen { }

// @Injectable({
//   providedIn: 'root'
// })
// class something extends StatusBar { }

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends ConciseComponent {

  @ViewChild("dynamicContentContainer", { static: false, read: ViewContainerRef }) dynamicContentContainer!:ViewContainerRef

  public get isReady():object|undefined { return propertyIfExists(this, 'ux.layers') }

  public get loading():TLayer|undefined { return (exists(this, 'ux.layers')) ? this.ux.layers['layer-loading'] : undefined }

  public get primary():TLayer|undefined { return (exists(this, 'ux.layers')) ? this.ux.layers['layer-primary-channel'] : undefined }

  public get secondary():TLayer|undefined { return (exists(this, 'ux.layers')) ? this.ux.layers['layer-secondary-channel'] : undefined }

  public get overlay():TLayer|undefined { return (exists(this, 'ux.layers')) ? this.ux.layers['layer-overlay'] : undefined }

  public get main():TLayer|undefined { return (exists(this, 'ux.layers')) ? this.ux.layers['layer-main'] : undefined }

  public get background():TLayer|undefined { return (exists(this, 'ux.layers')) ? this.ux.layers['layer-background'] : undefined }

  public active:boolean = false
  private dynamicComponent:any


  constructor(
    public override ux:UxService,
    private publicService:PublicService,
    private platform:Platform,
    // private splashScreen:whatever,
    // private statusBar:something,
  ) {
    super(ux)
    console.clear()
    console.log(`Version: ${ this.publicService.state.version }`)
    console.log('Production Stage:', this.publicService.state.production)
    console.log(`Bootstrapped at: ${ new Date().toLocaleTimeString() }`)
    if (this.publicService.state.downForMaintenance) console.warn('Down for Maintenance:', this.publicService.state.downForMaintenance)
    this.initializeApp()
  }














  protected override setupEventListeners() {
    this.ux.events.Ux_onOverlay.subscribe(request => this.onOverlay(request))
  }

  











  
  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault()
      // this.splashScreen.hide()
    })
  }














  private onOverlay(params:{ display:boolean, component?:any }) {
    this.onNewState(params.display)
    if (params.component) this.onNewComponent(params.component)
  }


      private onNewState(newState:boolean) {
        this.active = newState
        if (this.active) this.displayOverlay()
        else this.hideOverlay()
      }


      private onNewComponent(providedComponent:any) {
        this.dynamicComponent = providedComponent
      }


          private displayOverlay() {
            this.ux.layers["layer-overlay"].display = true
            this.waitFor({ 
              condition: ()=> exists(this, 'dynamicContentContainer'), 
              then: this.projectDynamicContentIntoContainer, 
              with: ()=> { return { viewContainer: this.dynamicContentContainer, component: this.dynamicComponent } }, 
              interval: 1000 
            })
          }

          
          private hideOverlay() {
            this.ux.layers["layer-overlay"].display = false
          }

}
