import { Injectable } from '@angular/core'
import { IAuthService } from './auth.service.interface'
import { AuthCommonService } from '@library/common/account-common/auth/client/auth-common.service'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class AuthService extends AuthCommonService implements Exactly<IAuthService, AuthService> {


  constructor(
    protected override http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(http, events)
  }













  
  // protected assignEnvironment():void {
  //   this.saas = environment
  // }

}
