import { Input, Output, EventEmitter, Directive } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { UxService } from '@services/ux/ux.service'
import { capitalizeLeadingCharacter } from '@library/common/generic-common/tools/formatters/capitalizeLeadingCharacter'
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract'
import copyOf from '@library/common/generic-common/tools/utils/concise.copyOf'

export type TConciseFormInput = { record:any, supplementary?: { [model:string]:any[] } }

@Directive()
export abstract class ComponentForm extends ConciseComponent {

  @Input() input?:TConciseFormInput
  @Input() buttonLabel!:string
  @Output() output:EventEmitter<any> = new EventEmitter()

  public get canSubmit():boolean {
    if (!this.form) return false
    return this.form.valid
  }
  
  public get feedbackMessage():string|null|void {
    if (!this.form) return
    for (let [ field, properties ] of Object.entries(this.form.controls)) {
      if (properties.status === 'INVALID') return this.selectErrorMessage(field, properties)
    }
    return null
  }

  public form!:FormGroup
  public submitButtonLabel!:string
  protected isolatedCopy:any


  constructor(
    protected override ux:UxService
  ) { 
    super(ux)
  }














  public override ngOnInit() {
    this
    .hookSetModelPre()
    .setModel()
    .hookSetModelPost()
    .setSupplementaryData()
    .setSubmitButtonLabel()
    .hookCreateFormPre()
    .createForm()
    .hookCreateFormPost()
    .hookBindRecordToFormPre()
    .bindRecordToForm()
    .hookBindRecordToFormPost()
    .setupEventListeners()
  }


      protected hookSetModelPre():this { return this }
  

      protected setModel():this {
        this.isolatedCopy = copyOf(this.input?.record) || { }
        return this
      }


      protected hookSetModelPost():this { return this }


      protected setSupplementaryData():this {
        if (this.input?.supplementary) for (let [ model, records ] of Object.entries(this.input?.supplementary)) this[model] = records
        return this
      }


      protected setSubmitButtonLabel():this {
        if (this.buttonLabel) this.submitButtonLabel = this.buttonLabel
        else if (this.input?.record) this.submitButtonLabel = `Update`
        else this.submitButtonLabel = `Create`
        return this
      }


      protected hookCreateFormPre():this { return this }


      protected createForm():this { return this }


      protected hookCreateFormPost():this { return this }


      protected hookBindRecordToFormPre():this { return this }
      

      protected bindRecordToForm():this {
        Object.keys(this.form.controls).forEach(field => {
          if (this.isolatedCopy[field] !== undefined && this.isolatedCopy[field] !== null) {
            if (this.isolatedCopy[field].constructor === Boolean) this.form.patchValue({ [field]: `${ this.isolatedCopy[field] }` })
            else this.form.patchValue({ [field]: this.isolatedCopy[field] })
          }
        })
        return this
      }


      protected hookBindRecordToFormPost():this { return this }














  public onUser_submit() {
    this
      .hookMutateDataBeforeBindToRecord()
      .bindFormValuesToRecord()
      .hookMutateDataBeforeEmit()
      .outputData()
  }

      
      protected hookMutateDataBeforeBindToRecord():this { return this }


      protected bindFormValuesToRecord():this {
        for (let [ field, value ] of Object.entries(this.form.value)) {
          this.isolatedCopy[field] = value
        }
        return this
      }


      protected hookMutateDataBeforeEmit():this { return this }


      protected outputData() {
        this.output.emit(this.isolatedCopy)
      }















  private selectErrorMessage(field, properties):string|void {
    for (let [ validation ] of Object.entries(properties.errors))
      if (validation === 'required') return `${ capitalizeLeadingCharacter(field) } is required.`
      else throw new Error('Unknown Validation')
  }













  
  public onUser_cancel() {
    this.ux.navigation.back()
  }

}
