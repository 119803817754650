import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { RegistrationManagerCommonService } from '@library/common/account-common/registration/registration-manager-common.service'
import { IRegistrationManagerService } from './registration-manager.service.interface'
import { RegistrationAssistantNewAuthService } from './new-auth/registration-assistant-new-auth'
import { RegistrationAssistantNewLoginService } from './new-login/registration-assistant-new-login.service'
import { RegistrationAssistantNewDbService } from './new-db/registration-assistant-new-db.service'
import { EventsAppService } from '@services/app/events/events-app.service'
import { SettingsAppService } from '@services/app/settings/settings-app.service'
import { StateService } from '@services/app/state/state.service'


@Injectable({
  providedIn: 'root'
})
export class RegistrationManagerService extends RegistrationManagerCommonService implements Exactly<IRegistrationManagerService, RegistrationManagerService> {


  constructor(
    public override auth:RegistrationAssistantNewAuthService,
    public override logins:RegistrationAssistantNewLoginService,
    protected override database:RegistrationAssistantNewDbService,
    protected override events:EventsAppService,
    protected override settings:SettingsAppService,
    protected override state:StateService
  ) {
    super(auth, logins, database, events, settings, state)
  }













  
  // protected hookConstructorPre() {
    
  // }














  public checkEmailAddressAvailability(email: string):Promise<boolean> {
    return Promise.resolve(true)
  }

}
