<form [formGroup]="form" [ngClass]="deviceClasses">
  <h3>Question:</h3>
    <div class="formContent">
      <textarea rows="6">{{ uiQuestion }}</textarea>
    </div>
  <div *ngIf="mode === 'exam'">
    <h3>Your Answer:</h3>
    <div class="formContent">
      <div *ngFor="let option of uiOptions" class="multipleChoiceOption">
        <p-radioButton [label]="option.answer" name="userAnswer" [value]="option.answer" formControlName="userAnswer"></p-radioButton>
      </div>
    </div>
  </div>
  <div *ngIf="mode === 'grade'">
    <h3>Your Answer:</h3>
      <div class="formContent">
        <textarea  rows="6">{{ uiUserAnswer }}</textarea>
      </div>
    <h3>Correct Answer:</h3>
      <div class="formContent">
        <textarea rows="6">{{ uiCorrectAnswer }}</textarea>
      </div>
    <h3>Reason:</h3>
      <div class="formContent">
        <textarea rows="6" pTextarea>{{ uiReason }}</textarea>
      </div>
    <h3>Grade Your Answer:</h3>
      <div class="formContent">
        <p-dropdown [options]="correctOptions" formControlName="correct" placeholder="Select One" optionLabel="name"></p-dropdown>
      </div>
  </div>
  <hr class="formContent">
  <div id="buttonContainer">
    <button label="Next" class="buttonTertiary" (click)="onUser_Submit()" pButton></button>
  </div>
</form>
