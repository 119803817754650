<p-accordion>
  <p-accordionTab [header]="header">
    <ul>
      <li>Manufacturer: {{ device.manufacturer }}</li>
      <li>Model: {{ device.model }}</li>
      <li>Device Unique Id: {{ device.uuid }}</li>
    </ul>
    <div id="actions">
      <div>Push Notification Reminder: <p-toggleButton [(ngModel)]="notifications" (onChange)="onUser_ToggleNotifications()" ngDefaultControl></p-toggleButton></div>
      <button label="Remove" (click)="onUser_Remove()" pButton></button>
    </div>
  </p-accordionTab>
</p-accordion>




















<!-- <div>
  <h5>Device: {{ device.id }}</h5>
  <ul>
    <li>Manufacturer: {{ device.manufacturer }}</li>
    <li>Model: {{ device.model }}</li>
    <li>Device Unique Id: {{ device.uuid }}</li>
  </ul>
  <div id="actions">
    <div>Push Notification Reminder: <p-toggleButton [(ngModel)]="notifications" (onChange)="onUser_ToggleNotifications()" ngDefaultControl></p-toggleButton></div>
    <button label="Remove" (click)="onUser_Remove()" pButton></button>
  </div>
</div> -->
