import { ModelDynamoDb } from "@library/common/generic-common/typings/ModelDynamoDbV2/ModelDynamoDb.abstract";
import { TUserSettings } from './TUser.settings'
import { TUser } from "./TUser.type";
import { TConciseFormInput } from "@library/common/generic-common/client/typings/TConciseFormInput.type";


export class User extends ModelDynamoDb {

  public static settings = TUserSettings
  public static form:TConciseFormInput

  constructor(model:TUser) {
    super(model)
  }

}