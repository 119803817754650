<div class="outer">
  <div class="loading-container">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="loading-item">
      <i style="font-size: .8rem; color: white;">Loading...</i>
    </div>
  </div>
</div>
