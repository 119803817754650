import { ConciseClass } from '../ConciseClass/ConciseClass.abstract'
import { IConciseService } from './ConciseService.abstract.interface'
import { Exactly } from '../../../typings/Exactly';
import { TOnError } from '../../../../../logs-common/types/TOnError.type';
import { exists } from '../../../../../generic-common/tools/utils/concise.exists';


export abstract class ConciseService extends ConciseClass implements Exactly<IConciseService, ConciseService> {

  
  constructor(
    protected override events:any,
    protected name?:string
  ) {
    super(events)
  }














  protected hasFailed(params:{ at:Function, error:any }):any {
    let event = { origin: this.constructor.name, name: params.at.name, details: params.error.details || params.error } as TOnError
    let superDependency = this['app' as keyof this] || this['ux' as keyof this] || this['events']
    if (superDependency.Logs_onError) superDependency.Logs_onError.next(event)
    else if (superDependency.events) superDependency.events.Logs_onError.next(event);
    (this.hasFailed as any).logged = true
    let message = `${ this.constructor.name }.${ params.at.name }${ (exists(params, 'error.message')) ? `: ${ params.error.message }` : '' }`
    console.error(message)
    return params.error
  }

}