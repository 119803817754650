export type TIsEmailResponse = { valid:boolean, errorMessage?:string }

export let MIsEmailResponse = { valid: true }


export function isEmail(input:string):TIsEmailResponse {
  if (!input || input === undefined) return { valid: false, errorMessage: '' }
  if (!input.includes('@')) return { valid: false, errorMessage: `No @ symbol in Email address.` }
  let [ , domainInfo ] = input.split('@')
  if (!domainInfo) return { valid: false, errorMessage: `There is no domain name e.g. "@gmail.com"`}
  if (!domainInfo.includes('.') || domainInfo.endsWith('.')) return { valid: false, errorMessage: `There is no domain type e.g. - .com, .org...` }
  if (domainInfo.split('.')[1].length < 2) return { valid: false, errorMessage: `Domain type "${ domainInfo.split('.')[1] }" must be greater than 1 character in length.` }
  let atSymbol = input.indexOf('@')
  let dotSymbol = input.indexOf('.')
  if (dotSymbol === atSymbol + 1) return { valid: false, errorMessage: `There must be a domain name between @ and . symbols` }

  return { valid: true, errorMessage: `Valid format.` }
}