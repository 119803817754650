// Refactored from original : https://github.com/angular/angular/issues/14324
import { Injectable, ComponentFactoryResolver, Type, ComponentFactory } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CoalescingComponentFactoryResolver extends ComponentFactoryResolver {


  private rootResolve:(component:Type<any>) => ComponentFactory<any>
  private isAlreadyCallingResolver = false
  private readonly resolvers = new Map<ComponentFactoryResolver, (component:Type<any>) => ComponentFactory<any>>()


  constructor(
    private readonly rootResolver:ComponentFactoryResolver
  ) {
    super()
  }














  init() {
    this.rootResolve = this.rootResolver.resolveComponentFactory
    this.rootResolver.resolveComponentFactory = this.resolveComponentFactory
  }














  public registerResolver(resolver: ComponentFactoryResolver) {
    const original = resolver.resolveComponentFactory
    this.resolvers.set(resolver, original)
  }














  public resolveComponentFactory = <T>(component:Type<T>):ComponentFactory<T> => {
    if (this.isAlreadyCallingResolver) return undefined as any
    else try { return this.callResolver(component) }
      finally { this.isAlreadyCallingResolver = false }
  }














  private callResolver(component:Type<any>) {
    this.isAlreadyCallingResolver = true
    return this.resolveInternal(component)
  }


      private resolveInternal = <T>(component: Type<T>): ComponentFactory<T> => {
        for (const [ CodegenComponentFactoryResolver, resolveComponentFactory ] of Array.from(this.resolvers.entries())) {
          try {
            const factory = resolveComponentFactory.call(CodegenComponentFactoryResolver, component)
            if (factory) return factory
          } catch {}
        }
        return this.rootResolve.call(this.rootResolver, component)
      }

}
