import { Injectable } from '@angular/core';
import { Event, Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, RoutesRecognized, GuardsCheckStart, ChildActivationStart, ActivationStart, GuardsCheckEnd, ResolveStart, ResolveEnd, ChildActivationEnd, ActivationEnd, NavigationEnd, NavigationCancel, NavigationError, Scroll, NavigationExtras } from '@angular/router'
import { Location } from '@angular/common'
import { EventsUxService } from '@services/ux/events/events-ux.service';
import { SettingsUxService } from '@services/ux/settings/settings-ux.service'
import { ConciseService } from '@library/common/generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract'
import { INavigationService } from './navigation.service.interface'
import { InterpreterService } from '../interpreter/interpreter.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationService extends ConciseService implements INavigationService {


  constructor(
    public override events:EventsUxService,
    private router:Router,
    private settings:SettingsUxService,
    private location:Location,
    private interpreter:InterpreterService
  ) {
    super(events)
    this.router.events.subscribe(event => this.onRouterEvent(event))
  }













  
  public back():void {
    this.location.back()
  }
  












  
  public navigate(commands:any[], extras?:NavigationExtras):Promise<boolean> {
    return this.router.navigate(commands, extras)
  }














  private onRouterEvent(event:Event) {
    let functionName:string = `on${ event.constructor.name }`
    if (this[functionName as keyof this]) (this as any)[functionName](event)
  }














  private downForMaintenance() {
    this.router.navigate(['downForMaintenance'])
  }







//https://angular.io/guide/router#router-events

  private onNavigationStart	(event:NavigationStart|NavigationEnd) {
    this.events.Ux_onStateChange.next({ is: 'working' })
    // if (this.settings.env.downForMaintenance && event.url !== '/downForMaintenance') this.downForMaintenance()
    // else if (!this.settings.env.downForMaintenance && event.url === '/downForMaintenance') this.router.navigate(['home'])
  }  // An event triggered when navigation starts.


// private onRouteConfigLoadStart(event:RouteConfigLoadStart) { }
  // An event triggered before the Router lazy loads a route configuration.


// private onRouteConfigLoadEnd(event:RouteConfigLoadEnd) { }
  // An event triggered after a route has been lazy loaded.


// private onRoutesRecognized(event:RoutesRecognized) {
  // this.setLocation(event.urlAfterRedirects)
  // this.layers["layer-mobileActions"].display = false
  // this.layers["layer-overlay"].display = false
// }  // An event triggered when the Router parses the URL and the routes are recognized.


// private onGuardsCheckStart(event:GuardsCheckStart) { }
  // An event triggered when the Router begins the Guards phase of routing.


// private onChildActivationStart(event:ChildActivationStart) { }
  // An event triggered when the Router begins activating a route's children.


// private onActivationStart(event:ActivationStart) { }
  // An event triggered when the Router begins activating a route.


// private onGuardsCheckEnd(event:GuardsCheckEnd) { }
  // An event triggered when the Router finishes the Guards phase of routing successfully.


// private onResolveStart(event:ResolveStart) { }
  // An event triggered when the Router begins the Resolve phase of routing.


// private onResolveEnd(event:ResolveEnd) { }
  // An event triggered when the Router finishes the Resolve phase of routing successfuly.


// private onChildActivationEnd(event:ChildActivationEnd) { }
  // An event triggered when the Router finishes activating a route's children.


// private onActivationEnd(event:ActivationEnd) { }
  // An event triggered when the Router finishes activating a route.


  private onNavigationEnd(event:NavigationEnd) {
    this.events.Ux_onStateChange.next({ is: 'ready' })
  }  // An event triggered when navigation ends successfully.


  private onNavigationCancel(event:NavigationCancel) {
    if (this.interpreter.error(event)) this.events.Ux_onFeedbackSecondary.next({ state: 'failure', message: this.interpreter.error(event) as string })
    if (event.code === 3) this.navigate(['home'])
    this.events.Ux_onStateChange.next({ is: 'ready' })
  }  // An event triggered when navigation is canceled. This is due to a Route Guard returning false during navigation.


  private onNavigationError(event:NavigationError) {
    this.events.Ux_onStateChange.next({ is: 'ready' })
  }  // An event triggered when navigation fails due to an unexpected error.


// private onScroll(event:Scroll) { }
  // An event that represents a scrolling event.


}