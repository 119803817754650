import { IRegistrationAssistantNewDbService } from './registration-assistant-new-db.service.interface'
import { RegistrationAssistantCommonNewDbService } from '@library/common/account-common/registration/new-db/registration-assistant-common-new-db.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { EventsAppService } from '@services/app/events/events-app.service'
import { UsersLiason } from '@services/app/api/models/users/UsersLiason.service'
import { environment } from '@env/environment'
import { TNewAccountData } from '@library/common/account-common/registration/typings/TNewAccountData.type'
import Preference from '@private/models/preferences/types/preferences.model'
// import { TPreferencesSettings } from '@private/models/preferences/types/preferences.settings'
import { TPreferences } from '@private/models/preferences/types/preferences.type'
import { TDynamoRecord } from '@library/common/generic-common/typings/ModelDynamoDbV2/TDynamoRecord.type'
import { StateService } from '@services/app/state/state.service'


@Injectable({
  providedIn: 'root'
})
export class RegistrationAssistantNewDbService extends RegistrationAssistantCommonNewDbService implements Exactly<IRegistrationAssistantNewDbService, RegistrationAssistantNewDbService> {


  constructor(
    protected override events:EventsAppService,
    protected users:UsersLiason,
    protected override state:StateService
  ) {
    super(events, users, state)
  }













  
  protected hookConstructorPre() {
    this.saas = environment
  }

  
  protected createUserDatabaseRecords(params:TNewAccountData) {
    // newAccountData: params, initialUser
    let records:TDynamoRecord[] = [ ]
    records.push(this.createInitialPreferences(params))
    return records
  }

  protected createInitialPreferences(params:TNewAccountData):TPreferences {
    // let factory = TPreferencesFactory
    // factory.settings = TPreferencesSettings
    let preferences:TPreferences = Preference.create(params.accountId as string)
    preferences.email = params.email
    preferences.emailNotifications = true
    preferences.id = 'default'
    preferences.streak = 3
    preferences.timezone = params.timezone as string
    preferences.utcOffset = params.utcOffset as number
    return preferences
  }

}
