import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs'
import { IEventsUxCommonService } from './events-ux-common.service.interface'
import { TScreenLayout } from '../../../generic-common/client/typings/TScreenLayout.type';
import { Exactly } from '../../../generic-common/client/typings/Exactly'
import * as io from './events-ux-common.service.io'
import * as logs from '../../../logs-common/client/events/ux/logs-ux-common.events.io'
import * as state from '../../../state-common/events/state-common.events.io'


export abstract class EventsUxCommonService implements Exactly<IEventsUxCommonService, EventsUxCommonService>  {
  public Logs_onCodeCall:Subject<logs.TLogs_onCodeCall> = new Subject()
  public Logs_onUserAction:Subject<logs.TLogs_onUserAction> = new Subject()
  public Logs_onNavigation:Subject<logs.TLogs_onNavigation> = new Subject()
  public Ux_onDeviceSize:BehaviorSubject<io.TUx_onDeviceSize> = new BehaviorSubject({ size: 'isUnknown' } as TScreenLayout )
  public Ux_onScreenLayout:BehaviorSubject<io.TUx_onScreenLayout> = new BehaviorSubject( null as unknown as io.TUx_onScreenLayout)
  public Ux_onStateChange:Subject<state.onStateRequest> = new Subject()
  public Ux_onDeviceInfo:BehaviorSubject<io.TUx_onDeviceInfo> = new BehaviorSubject( null as unknown as io.TUx_onDeviceInfo)
  public Ux_onFeedbackPrimary:Subject<io.TUx_onFeedbackPrimary> = new Subject()
  public Ux_onFeedbackSecondary:Subject<io.TUx_onFeedbackSecondary> = new Subject()
  public Ux_onOverlay:ReplaySubject<io.TUx_onOverlay> = new ReplaySubject()
  public Ux_onRouteTitle:Subject<io.TUx_onRouteTitle> = new Subject()
  public Ux_onRouteData:Subject<io.TUx_onRouteData> = new ReplaySubject()
}
