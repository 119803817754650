import { TOnCodeCall } from '../../../../../logs-common/types/TOnCodeCall.type'
import { IConciseClass } from '../../Abstract/ConciseClass/ConciseClass.abstract.interface'
import { Exactly } from '../../../typings/Exactly';

type TWaitForRequest = { condition:Function, then:Function, with?:any, interval?:number, this?:any }


export abstract class ConciseClass implements Exactly<IConciseClass, ConciseClass> {


  protected delayUpdateInterval:number = 2000
  protected delayUpdateProcess?:ReturnType<typeof setTimeout>|undefined
  protected events?:any


  constructor(superDependency:any) { 
    console.log(`${ this.constructor.name } loading...`)
    if (localStorage.getItem('logging')) {
      this.attachFunctionNameProperty(this)
      this.attachLogging(this)
    }
  }














  private attachFunctionNameProperty(TClass:any) {
    let properties = Object.getOwnPropertyNames(TClass)
    properties.forEach(property => this.attachNameProperty(TClass, property))
    let protoType = Object.getPrototypeOf(TClass)
    if (protoType.constructor.name !== ConciseClass.name && protoType.constructor.name !== Object.name) this.attachFunctionNameProperty(protoType)
  }


      private attachNameProperty(TClass:any, property:string) {
        if (typeof TClass[property] === 'function' && property !== 'constructor') Object.defineProperty(TClass[property], 'name', { value: property })
      }














  protected attachLogging(TClass:any):void { 
    let properties = Object.getOwnPropertyNames(Object.getPrototypeOf(TClass))
    properties.forEach(propertyName => this.wrapMethodWithLogging(TClass, propertyName))
  }


      private wrapMethodWithLogging(TClass:any, propertyName:string) {
        if (this.shouldWrapMethodForLogging(TClass, propertyName)) TClass[propertyName] = this.loggingWrapper(TClass[propertyName], propertyName)
      }


          private shouldWrapMethodForLogging(TClass:any, propertyName:string):boolean {
            let exclude
            let exclusionsMap = JSON.parse(localStorage.getItem('loggingExclusions') as string) || { }
            if (!exclusionsMap.LogsService) exclusionsMap.LogsService = ["writeItem", "onCodeCall"]
            if (TClass.constructor.name in exclusionsMap) exclude = exclusionsMap[TClass.constructor.name].includes(propertyName)
            return (
              TClass[propertyName] 
              && typeof TClass[propertyName] === 'function' 
              && propertyName !== 'constructor'
              && propertyName !== 'factory'
              && propertyName !== 'hasFailed'
              && !exclude
              && !TClass[propertyName].skipLogging
              && !TClass[propertyName].wrapped
            )
          }

          
          private loggingWrapper(method:Function, propertyName:string) {
            let placeholder:any = {
              [propertyName]: function(propertyName:string) {
                try {
                  this.onCall({ function: method, params: arguments })
                  return method.apply(this, arguments)
                } 
                catch(error) { 
                  this.hasFailed({ at: method, error }) 
                }
              }
            }
            placeholder[propertyName].wrapped = true
            return placeholder[propertyName]
          }














  protected onCall(params:{ function:Function, params?:any, result?:any, results?:any, event?:any }):void { 
    let event = { origin: this.constructor.name, method: params.function.name, details: params.params || params.result || params.results || params.event } as TOnCodeCall
    let superDependency:any = this['app' as keyof this] || this['ux' as keyof this] || this['events' as keyof this]
    if (superDependency.Logs_onCodeCall) superDependency.Logs_onCodeCall.next(event)
    else if (superDependency.events) superDependency.events.Logs_onCodeCall.next(event)
  }













  
  protected waitFor(params:TWaitForRequest) {
    if (params.condition()) this.exectue(params)
    else {
      let monitor = setInterval(()=> {
        if (!params.condition()) return
        clearInterval(monitor)
        this.exectue(params)
      }, params.interval || 500)
    }
  }

  
      private exectue(params:TWaitForRequest) {
        let args
        if (params.with) args = (params.with.constructor === Function)? params.with() : params.with
        params.then.call(params.this || this, args)
      }














  protected delayProcess(process:Function, params?:any):Promise<any> {
    return new Promise((resolve)=> {
      if (!this.delayUpdateProcess) this.delayUpdateProcess = setTimeout(()=> {
        this.delayUpdateProcess = clearTimeout(this.delayUpdateProcess) as undefined
        resolve(process(params))
      }, this.delayUpdateInterval)
    })
  }

}
