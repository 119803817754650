import { HttpClient } from '@angular/common/http';
import { ISupportCommonService } from './support-common.service.interface'
import { HttpOptions } from '../../generic-common/client/classes/HttpOptions/HttpOptions.class'
import { ApiService } from '../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { EventsAppService } from '@services/app/events/events-app.service';
import { TResponseApiGeneric } from '../../generic-common/server/typings/TResponseApiGeneric.type';
import { Exactly } from '../../generic-common/client/typings/Exactly';
import { TGenericFailure } from '@library/common/generic-common/client/typings/TGenericFailure.type';
import { environment } from '@env/environment'
import * as io from '../typings/io.index'


export abstract class SupportCommonService extends ApiService implements Exactly<ISupportCommonService, SupportCommonService> {

  protected override apiId:string = environment.api.ids.support
  
  private LogFileSentToSupportLineLimit:number


  constructor(
    protected http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(events)
    this.LogFileSentToSupportLineLimit = 300
  }














  protected override hookConstructorPost() {
    this.events.Support_onAlertRequest.subscribe(request => this.alertSupport(request))
    this.events.Support_onSupporContactRequest.subscribe(request => this.contact(request))
    this.events.Support_onSupportLogRequest.subscribe(request => this.sendLog(request))
  }















  public contact(params:io.TContactRequest):Promise<io.TContactResponse> {
    return this.http.post(this.baseUrl + this.contact.name, new HttpOptions({ params })).toPromise()
      .then(result => {
        this.events.Support_onSupporContactResponse.next(result as TResponseApiGeneric)
        return result
      })
      .catch(error => {
        this.events.Support_onSupporContactResponse.next({ output: { success: false } } as io.TContactResponse)
        return error
      })
  }














  public sendLog(params:io.TSendLogRequest):Promise<io.TSendLogResponse|TGenericFailure> {
    let options = new HttpOptions({ params: this.makeSaveLogParams(params) })
    return this.http.post(this.baseUrl + this.sendLog.name, options).toPromise()
      .then((result:any) => this.onSendLogSuccess(result))
      .catch(error => this.onSendLogFailure(error))
  }


      private makeSaveLogParams(params:io.TSendLogRequest):io.TSendLogRequest {
        let log = JSON.parse(localStorage.getItem('log') as string)
        params.log = log.slice(-this.LogFileSentToSupportLineLimit)
        return params
      }


      private onSendLogSuccess(result:io.TSendLogResponse) {
        if (Object.values(result.output.details).some(success => success !== true)) return this.onSendLogFailure(result as TGenericFailure)
        this.events.Support_onSupportLogResponse.next(result)
        return result
      }


      private onSendLogFailure(error:TGenericFailure) {
        this.events.Support_onSupportLogResponse.next({ output: { success: false } } as io.TSendLogResponse)
        return error
      }














    public alertSupport(params:io.TAlertSupportRequest):Promise<io.TAlertSupportResponse> {
      return this.http.post(this.baseUrl + this.alertSupport.name, new HttpOptions({ params })).toPromise()
        .then(result => {
          this.events.Support_onAlertResponse.next(result as TResponseApiGeneric)
          return result
        })
        .catch(error => {
          this.events.Support_onAlertResponse.next({ output: { success: false } } as io.TAlertSupportResponse)
          return error
        })
    }













    
    public dataRemoval(params:io.TDataRemovalRequest):Promise<io.TDataRemovalResponse> {
      return this.http.post(this.baseUrl + this.dataRemoval.name, new HttpOptions({ params })).toPromise()
        .then(result => {
          this.events.Support_onDataRemovalResponse.next(result as io.TDataRemovalResponse)
          return result
        })
        .catch(error => {
          this.events.Support_onDataRemovalResponse.next(error)
          return error
        })
    }
  
}
