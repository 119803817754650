import { HttpClient } from '@angular/common/http';
import { Exactly } from '../../generic-common/client/typings/Exactly';
import { ICaptchaCommonService } from './captcha-common.service.interface'
import { ApiService } from '../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { HttpOptions } from '../../generic-common/client/classes/HttpOptions/HttpOptions.class';
import { environment } from '@env/environment'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';
import * as io from '../typings/io.index'


export abstract class CaptchaCommonService extends ApiService implements Exactly<ICaptchaCommonService, CaptchaCommonService> { 

  protected apiId = environment.api.ids.captcha

  constructor(
    protected http:HttpClient,
    protected override events:IEventsAppCommonService
  ) { 
    super(events)
    this.events.Captcha_onHumanVerifyRequest.subscribe(request => this.verify(request))
  }














  public ["secretKey-get"](params:io.TSecretKeyGetRequest):Promise<io.TSecretKeyGetResponse> {
    throw new Error(`Function exists in interface but is only implemented on server side.`)
  }














  public verify(params:io.TVerifyRequest):Promise<io.TVerifyResponse> {
    let options = new HttpOptions({ params })
    return this.http.post(this.baseUrl + this.verify.name, options).toPromise()
      .then(result => { 
        this.events.Captcha_onHumanVerifyResponse.next(result as io.TVerifyResponse)
        return result
      })
      .catch(error => { 
        this.events.Captcha_onHumanVerifyResponse.next(error as any)
        return error
      })
  }

}