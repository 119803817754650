import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UxService } from '@services/ux/ux.service';
import { BaseFormQuestionaire } from '../BaseFormQuestionaire.class'
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists';


@Component({
  selector: 'app-questionaire-multiple-choice',
  templateUrl: './questionaire-multiple-choice.component.html',
  styleUrls: ['./questionaire-multiple-choice.component.scss'],
})
export class QuestionaireMultipleChoiceComponent extends BaseFormQuestionaire implements OnInit {

  
  public get uiOptions():any[] { return propertyIfExists(this, 'isolatedCopy.options') }


  constructor(
    public override ux:UxService
  ) {
    super(QuestionaireMultipleChoiceComponent, ux)
  }














  protected override createForm():this {
    this.form = new FormGroup({
      userAnswer: new FormControl(null, { validators: [Validators.required] }),
      correct: new FormControl(null)
    })
    return this
  }

}
