import { Component } from "@angular/core";
import { EventsUxService } from "@services/ux/events/events-ux.service";


@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss'],
})
export class AlertComponent {

  public message?:string
  public labelDismiss:string = 'Okay'
  private onDismiss?:Function
  

  constructor(
    private events:EventsUxService
  ) { 
    this.events.Ux_onOverlay.subscribe(event => this.onOverlay(event))
  }














  public onDismissButton() {
    if (this.onDismiss) this.onDismiss()
    this.events.Ux_onOverlay.next({ display: false })
  }














  private onOverlay(event) {
    if (event.component.name === AlertComponent.name) for (let [ property, value ] of Object.entries(event.params)) this[property] = value
  }

}