<main>

  <h1>Data Usage Policy for IQQA</h1>

  <h3>Login Credentials</h3>

    <p>Login information such as the password required to gain entry is and will be stored in a specialized third-party data-store designed to securely keep passwords encrypted and only the user has access to change the password.  IQQA will never have access to your password and therefore will never contact you asking for your password.  If you forget your password IQQA will provide a link to request a password reset.  This initiates the third-party data-store service to email you a link to reset the password through their third-party mechanisms.  This third-party data-store is currently <a href="https://aws.amazon.com/cognito/" target="_blank">Amazon Cognito Identity Authentication</a>.</p>

  <h3>Email Address</h3>

    <p>IQQA will store the email address you registered with.  You will be able to toggle in the "User Preferences" section of the application whether you wish to receive email reminders for your due quizzes.</p>

  <h3>Log Files</h3>
  
    <h6>Web Traffic Logging</h6>

      <p>IQQA servers follow a standard procedure of using log files. These files log visitors when they visit websites. Hosting companies do this as part of their hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking third-party inbound referrers, tracking users' movement on the website, and gathering demographic information.</p>

    <h6>Application Level Logging</h6>

      <p>As well as web traffic logging, the IQQA application also records which sections of the code are being executed and at what time as well as the data processed.  This is used in a two fold manner; Providing tech support, which users can initiate the transfer of their log file to support in the user's "Preferences" section, and also determining the most used and least used features.</p>
  
  <h3>Cookies and Web Beacons</h3>
  
    <p>Like any other website, IQQA uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
  
    <p>For more general information on cookies, please read the "What Are Cookies" article on <a href="https://www.cookieconsent.com/what-are-cookies/">Cookie Consent website</a>.</p>
    <div id="ending">&nbsp;</div>

</main>
