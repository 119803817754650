<form *ngIf="form" [formGroup]="form" [ngClass]="deviceClasses">
  <h4>Rate:</h4>
  <div id="rateContainer" class="formContent">
    <h5>Every (number):</h5>
    <p-spinner formControlName="rate" [min]="minimumRate"  (onBlur)="onScheduleChange()"></p-spinner>
  </div>
  <div id="unitContainer" class="formContent">
    <h5>Unit:</h5>
    <p-dropdown formControlName="unit" [options]="unitOptions" placeholder="Select One" optionLabel="name"  (onBlur)="onScheduleChange()"></p-dropdown>
  </div>
  <div id="timeContainer" class="formContent">
    <h5>At</h5>
    <p-calendar formControlName="time" [timeOnly]="true"  (onBlur)="onScheduleChange()"></p-calendar>
    <h6>Military Time: (13:00 = 1pm)</h6>
  </div>
  <div id="summaryContainer">
    <p>Rate: Every {{ uiRate }} {{ uiUnit }} @ {{ uiTime }} hours</p>
  </div>
</form>
