import { HttpClient } from '@angular/common/http'
import { ApiService } from '../../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { HttpOptions } from '../../../generic-common/client/classes/HttpOptions/HttpOptions.class'
import { ILoginsCommonService } from './logins-common.service.interface'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';
import * as io from '../server/index.io'
import { environment } from '@env/environment';


export abstract class LoginsCommonService extends ApiService implements ILoginsCommonService {


  protected override apiId:string = environment.api.ids.logins

  
  constructor(
    protected http:HttpClient,
    protected override events:IEventsAppCommonService
  ) {
    super(events)
  }














  public create(params:io.TCreateRequest):Promise<io.TCreateResponse> {
    return this.http.put(this.baseUrl + this.create.name, new HttpOptions({ params })).toPromise() as Promise<io.TCreateResponse>
  }














  public readAuthId(params:io.TReadAuthIdRequest):Promise<io.TReadAuthIdResponse> {
    return this.http.get(this.baseUrl + this.readAuthId.name, new HttpOptions({ params })).toPromise() as Promise<io.TReadAuthIdResponse>
  }














  public readEmail(params:io.TReadEmailRequest):Promise<io.TReadEmailResponse> {
    return this.http.get(this.baseUrl + this.readEmail.name, new HttpOptions({ params })).toPromise() as Promise<io.TReadEmailResponse>
  }














  public readUserId(params:io.TReadUserIdRequest):Promise<io.TReadUserIdResponse> {
    return this.http.get(this.baseUrl + this.readUserId.name, new HttpOptions({ params })).toPromise() as Promise<io.TReadUserIdResponse>
  }














  public delete(params:io.TDeleteRequest):Promise<io.TDeleteResponse> {
    return this.http.delete(this.baseUrl + this.delete.name, new HttpOptions({ params })).toPromise() as Promise<io.TDeleteResponse>
  }

}
