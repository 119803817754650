import { Injectable } from '@angular/core';
import { IFeedbackService } from './feedback.service.interface';
import { ConciseService } from '@library/common/generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract'
import { EventsUxService } from '../events/events-ux.service';
import { ConfirmComponent } from '@library/widgets/confirm/confirm.component'
import * as io from './feedback.service.io'


@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends ConciseService implements IFeedbackService {


  constructor(
    protected override events:EventsUxService,
  ) { 
    super(events)
  }














  public notify(params:io.TNotifyRequest):io.TNotifyResponse {
    this.events.Ux_onFeedbackPrimary.next(params)
  }














  public alert(params:io.TAlertRequest):io.TAlertResponse {
    this.events.Ux_onFeedbackSecondary.next(params)
  }














  public present(component:io.TPresentRequest):io.TPresentResponse {
    this.events.Ux_onOverlay.next({ display: true, component })
  }














  public confirm(message:io.TConfirmRequest):io.TConfirmResponse {
    return new Promise((resolve)=> this.events.Ux_onOverlay.next({ display: true, component: ConfirmComponent, params: { message: message, onConfirm: resolve, onDismiss: resolve } }))
  }

}
