import { Exactly } from '@library/common/generic-common/client/typings/Exactly';
import { IScheduleService } from './schedule.service.interface'
import { ScheduleCommonService } from '@library/common/schedule-common/client/schedule-common.service'
import { HttpClient } from '@angular/common/http';
import { EventsAppService } from '@services/app/events/events-app.service';
import { environment } from '@env/environment'
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends ScheduleCommonService implements Exactly<IScheduleService, ScheduleService> {


  constructor(
    protected override http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(http, events)
  }














  // protected assignEnvironment() {
    // this.saas = environment
  // }

}
