import { TModelSettings } from '@library/common/generic-common/typings/ModelDynamoDbV2/TModelSettings.type'
import { ModelDynamoDb } from '../../../../../../library/common/generic-common/typings/ModelDynamoDbV2/ModelDynamoDb.abstract'

class Quiz extends ModelDynamoDb {

  ui_tags
  ui_questions

  constructor(record?) {
    super(record)
  }

}

namespace Quiz {

  export const settings:TModelSettings = {
    nameSingular: 'quiz',
    namePlural: 'quizzes',
    prefix: 'quiz',
    idLength: 5,
    canLinkTo: [
      'questions',
      'tags'
    ],
    indexes: {
      indexA: { name: 'indexA', property: null },
      indexB: { name: 'indexB', property: null },
      indexC: { name: 'indexC', property: null },
    },
   summary: ['name', 'description'],
  }

}

export default Quiz