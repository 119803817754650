<div>
  <!-- <button *ngFor="let action of actions" (click)="action.action(id)" [ngStyle]="actionStyle(action)"> -->
  <button *ngFor="let action of actions" (click)="action.action(id)">
    <i [class]="action.icon"></i>
  </button>
</div>
<!-- <div class="d-flex d-row justify-content-center p-0">
  <span class="align-self-center">
    <button *ngFor="let action of actions" (click)="action.action(id)" class="mx-1" [ngStyle]="actionStyle(action)">
      <i [class]="action.icon"></i>
    </button>
  </span>
</div> -->