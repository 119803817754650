import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'app-custom-grid-actions',
  templateUrl: './custom-grid-actions.component.html',
  styleUrls: ['./custom-grid-actions.component.scss']
})
export class CustomGridActions implements ICellRendererAngularComp {

  // public actionStyle(action:{ label:string }):any {
  //   if (!action) return
  //   if (action.label === 'Edit') return { background: 'var(--master-color-success-tint-gradient)' }
  //   if (action.label === 'Delete') return { background: 'var(--master-color-error-tint-gradient)' }
  // }

  public actions:any[] = [ ]
  public id!:number


  constructor() { }














  agInit(params:any) {
    this.actions = params.actions
    this.id = params.data.id
  }













  
  refresh():boolean {
    return false
  }

}
