import { TModelSettings } from '@library/common/generic-common/typings/ModelDynamoDbV2/TModelSettings.type'
import { ModelDynamoDb } from '../../../../../../library/common/generic-common/typings/ModelDynamoDbV2/ModelDynamoDb.abstract'
import { TQuestionBaseDetails, TQuestionBaseSummary, TQuestionType } from './question.type'


class Question extends ModelDynamoDb implements TQuestionBaseDetails  {

  type:TQuestionType
  streak:number
  bucket: 'a' | 'b' | 'c'
  question: string
  answer: any
  override links = {
    tags: { },
    quizzes: { }
  }

  constructor(record?) {
    super(record)
  }

  protected static override hookPersistencePost(params:{ item:any, summary:any }):any {
    params.summary.tags = Object.keys(params.item.links.tag) || []
    return [params.item, params.summary]
  }

}

namespace Question {
  export const settings:TModelSettings = {
    nameSingular: 'question',
    namePlural: 'questions',
    prefix: 'que',
    idLength: 5,
    canLinkTo: [
      'quiz',
      'tag'
    ],
    indexes: {
      indexA: { name: 'indexA', property: null },
      indexB: { name: 'indexB', property: null },
      indexC: { name: 'indexC', property: null },
    },
    summary: ['question'],
  }
  
}

export default Question