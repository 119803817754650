import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Question from '@private/models/questions/types/question.model';
import { TTag } from '@private/models/tags/types/tag.type';
import { BaseFormQuestion } from '../BaseFormQuestion.class'
import { QuestionProcessorService } from '@private/models/questions/client/service/question-processor.service'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-question-multiple-form',
  templateUrl: './question-multiple-form.component.html',
  styleUrls: ['./question-multiple-form.component.scss'],
})
export class QuestionMultipleFormComponent extends BaseFormQuestion implements OnInit {

  @Input() override input?:{ record:Question, supplementary: { tags:TTag[] } }
  @Input() override buttonLabel!:string
  public override tags!:TTag[]
  public options!:any[]


  constructor(
    protected override ux:UxService,
    protected override processor:QuestionProcessorService
  ) {
    super(ux, processor)
  }

 












  protected override hookSetModelPost() {
    this.isolatedCopy.type = 'multiple'
    return this
  }














  protected override createForm():this {
    this.form = new FormGroup({
      question: new FormControl(null, { validators: [Validators.required, Validators.minLength(1) ]}),
      answer: new FormControl(null, { validators: [Validators.required, Validators.minLength(1) ]}),
      reason: new FormControl(null),
      newAnswerOption: new FormControl(null, { validators: [Validators.minLength(1) ]}),
      optionEdit: new FormControl(null),
      tags: new FormControl(null)
    })
    return this
  }














  protected override hookBindRecordToFormPost() {
    if (this.isolatedCopy) this.options = this.isolatedCopy.options
    return this
  }















  public onAddAnswerOption() {
    if (!this.form.value.newAnswerOption) return
    if (!this.options) this.options = [ ]
    this.options.push({ answer: this.form.value.newAnswerOption, correct: false })
    this.form.controls['newAnswerOption'].setValue(null)
  }

















  public onSelectCorrectAnswer(selectedOption, DOMItem) {
    this.options.forEach(option => {
      if (option.answer === selectedOption.answer) option.correct = true
      else option.correct = false
    })
    this.form.patchValue({ answer: selectedOption.answer })
    DOMItem.close()
  }
















  public onEditAnswer(selectedOption, DOMItem) {
    selectedOption.editing = !selectedOption.editing
    DOMItem.close()
  }















  public onUpdateOption(selectedOption, DOMItem) {
    let targetIndex = this.options.findIndex(option => option.answer === selectedOption.answer)
    this.options[targetIndex].answer = this.form.controls['optionEdit'].value
    selectedOption.editing = false;
    (this.form as any).controls.optionEdit.value = null
  }















  public onDeleteAnswer(selectedOption) {
    let removeOption = this.options.findIndex(option => option.answer === selectedOption.answer)
    this.options.splice(removeOption, 1)
  }















  protected override hookMutateDataBeforeBindToRecord() {
    this.form.value.options = this.options
    return this
  }













  
  public setItemColor(option) {
    if (option.correct) return `success`
    else return `primary`
  }

}
