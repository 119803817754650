import { Subject } from "rxjs";
import { ConciseService } from "../../generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract";
import { TRegistrationAssistantNewAuth_onFinished } from './new-auth/registration-assistant-common-new-auth.service'
import { ISettingsAppCommonService } from "../../settings-common/app/settings-app-common.service.interface";
import { id } from "../../generic-common/tools/utils/concise.short.id";
import { IRegistrationManagerCommonService } from './registration-manager-common.interface'
import { Exactly } from "../../generic-common/client/typings/Exactly";
import { TReturnGeneric } from "../../generic-common/client/typings/TReturnGeneric";
import { IRegistrationAssistantCommonNewAuthService } from "./new-auth/registration-assistant-common-new-auth.interface";
import { IRegistrationAssistantCommonNewLoginService } from "./new-login/registration-assistant-common-new-login.service.interface";
import { IRegistrationAssistantCommonNewDbService } from "./new-db/registration-assistant-common-new-db.service.interface";
import { IStateCommonService } from '../../state-common/state-common.service.interface'
import { TNewAccountData } from "./typings/TNewAccountData.type";
import { IEventsAppCommonService } from "@library/common/events-common/client/app/events-app-common.service.interface";
import * as io from './registration-manager-common.io'


export abstract class RegistrationManagerCommonService extends ConciseService implements Exactly<IRegistrationManagerCommonService, RegistrationManagerCommonService> { 


  public onFinished:Subject<TReturnGeneric> = new Subject()
  protected newAccountData:TNewAccountData
  protected requirements:io.TRequirements = { hasAuthProfile: null, hasLoginProfile: null, hasDatabase: null }
  


  constructor(
    public auth:IRegistrationAssistantCommonNewAuthService,
    public logins:IRegistrationAssistantCommonNewLoginService,
    protected database:IRegistrationAssistantCommonNewDbService,
    protected override events:IEventsAppCommonService,
    protected settings:ISettingsAppCommonService,
    protected state:IStateCommonService
  ) {
    super(events)
    this.hookConstructorPre()
    this.events.State_onStateRequest.subscribe(state => this.state = state)
    this.auth.onFinished.subscribe(results => this.onFinishedAuthProfileCreation(results))
    this.logins.onFinished.subscribe(results => this.onFinishedLoginCreation(results))
    this.database.onFinished.subscribe(results => this.onFinishedDatabaseCreation(results))
  }














  protected hookConstructorPre():void { }














  public abstract checkEmailAddressAvailability(email:io.TCheckEmailAddressAvailabilityRequest):Promise<io.TCheckEmailAddressAvailabilityResponse>
















  public begin(params:io.TRegisterRequest):void {
    this.newAccountData = params
    this.newAccountData.accountId = `${ this.settings.accountIdPrefix }-${ id(this.settings.accountIdLength) }`
    this.newAccountData.userId = `${ this.settings.userIdPrefix }-${ id(this.settings.userIdLength) }`
    if (this.state.production) this.events.Support_onAlertRequest.next({ saas: this.settings.env.saasName, event: 'onAccountCreation', dateTime: new Date().valueOf(), accountId: this.newAccountData.accountId, userId: this.newAccountData.userId, details: { username: this.newAccountData.email } })
    this.auth.createProfile({ saasName: this.state.saasName, username: this.newAccountData.email, password: this.newAccountData.password })
  }













  
  private onFinishedAuthProfileCreation(results:TRegistrationAssistantNewAuth_onFinished):void {
    if (!results.success) return this.onError({ success: false, details: results })
    this.requirements.hasAuthProfile = true
    this.newAccountData.authId = results.details.authId
    this.logins.create(this.newAccountData)
  }














  private onFinishedLoginCreation(results:TReturnGeneric) {
    if (!results.success) return this.onError({ success: false, details: results })
    this.requirements.hasLoginProfile = true    
    this.database.create(this.newAccountData)
  }














  private onFinishedDatabaseCreation(results:TReturnGeneric) {
    if (!results.success) return this.onError({ success: false, details: results })
    this.requirements.hasDatabase = true
    if (!Object.values(this.requirements).every(requirement => requirement === true)) this.onError(results)
    else {
      this.hookRegistrationPost(results)
      this.onFinished.next(results)
    }
  }














  protected hookRegistrationPost(results:any) { }









  




  private onError(results:any) {
    this.onFinished.next(results)
    this.hasFailed({ at: this.onError, error: results })
  }

}