import { HttpClient } from '@angular/common/http';
import { ModelLiason } from '../../../../generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract'
import { User } from '../typings/user.model'
import { IUsersLiasonCommon } from '../client/UsersLiason-common.service.interface'
import { Exactly } from '../../../client/typings/Exactly';
import { EventsAppService } from '@services/app/events/events-app.service';
import { Injectable } from '@angular/core';


@Injectable()
export class UsersLiasonCommon extends ModelLiason implements Exactly<IUsersLiasonCommon, UsersLiasonCommon> { 


  protected model = User as any
  protected apiId:string


  constructor(
    protected override http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(http, events)
  }



  
  public get indexA():string {
    return User.settings.indexes.indexA.name
  }

}
