import { HttpClient } from '@angular/common/http';
import { HttpOptions } from '../../generic-common/client/classes/HttpOptions/HttpOptions.class'
// import { EventsAppService } from '@services/client/app/events/events-app.service';
import { SettingsAppCommonService } from '../../settings-common/app/settings-app-common.service';
import { ApiService } from '../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract';
import { IScheduleCommonService } from './schedule-common.service.interface'
import { Exactly } from '../../generic-common/client/typings/Exactly';
import { environment } from '@env/environment'
import { TGenericFailure } from '@library/common/generic-common/client/typings/TGenericFailure.type';
import * as io from '../typings/io.index'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';


export abstract class ScheduleCommonService extends ApiService implements Exactly<IScheduleCommonService, ScheduleCommonService> {

  protected apiId = environment.api.ids.schedule
  protected settings!:SettingsAppCommonService
  

  constructor(
    protected http:HttpClient,
    protected override events:IEventsAppCommonService,
  ) { 
    super(events)
    if ((this.events as any).Schedule_onJobRequest) (this.events as any).Schedule_onJobRequest.subscribe(request => this.update(request))
  }













  
  public update(params:io.TUpdateRequest):Promise<io.TUpdateResponse> {
    return this.http.put(this.baseUrl + 'update', new HttpOptions({ params })).toPromise()
      .then(result => this.onScheduleSuccess(result as io.TUpdateResponse))
      .catch(error => this.onScheduleFailure(error)) as Promise<io.TUpdateResponse>
  }


      private onScheduleSuccess(result:io.TUpdateResponse) {
        if (!result.output.success) this.onScheduleFailure(result)
        return result
      }


      private onScheduleFailure(error:TGenericFailure) {
        this.hasFailed({ at: this.onScheduleFailure, error })
      }














  delete(params:io.TDeleteRequest):Promise<io.TDeleteResponse> { 
    throw new Error('Not developed yet.')
  }














  ["get-boundTo"](params:io.TGetBoundToRequest):Promise<io.TGetBoundToResponse> { 
    throw new Error('This function is only to be implemented server side for internal procedures.')
  } // Currently only used server-side














  ["remove-boundTo"](params:io.TRemoveBoundToRequest):Promise<io.TRemoveBoundToResponse> { 
    throw new Error('This function is only to be implemented server side for internal procedures.')
  } // Currently only used server-side

}
