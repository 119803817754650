import { Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpOptions } from '../../generic-common/client/classes/HttpOptions/HttpOptions.class';
import { IDateTimeCommonService } from './datetime-common.service.interface'
import { Exactly } from '../../generic-common/client/typings/Exactly';
import { ApiService } from '../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { detectClientTimezone } from '../../generic-common/tools/utils/detectClientTimezone'
import * as io from '../typings/datetime.service.io'
import { environment } from '@env/environment'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';


@Directive()
export abstract class DateTimeCommonService extends ApiService implements Exactly<IDateTimeCommonService, DateTimeCommonService> {


  protected override apiId = environment.api.ids.datetime


  constructor(
    protected override events:IEventsAppCommonService,
    protected http:HttpClient,
  ) {
    super(events)
  }















  protected override hookConstructorPost():void {
    this.events.DateTime_onAddSubtractRequest.subscribe(request => this.addSubtract(request))
    this.events.DateTime_onEpochByTimezoneRequest.subscribe(request => this.epochByTimezone(request))
    this.events.DateTime_onFutureDateTimeRequest.subscribe(request => this.futureDateTime(request))
    this.events.DateTime_onGetTimezoneOffsetRequest.subscribe(request => this.getTimezoneOffset(request))
  }













  
  public guessTimezone():string {
    return detectClientTimezone()
  }













  
  public addSubtract(params:io.TAddSubtractRequest):Promise<io.TAddSubtractResponse> { 
    return this.http.get(this.baseUrl + this.addSubtract.name, new HttpOptions({ params })).toPromise()
      .then(result => {
        this.events.DateTime_onAddSubtractResponse.next(result as io.TAddSubtractResponse )
        return result
      })
      .catch(error => {
        this.events.DateTime_onAddSubtractResponse.next({ output: { success: false } } as io.TAddSubtractResponse)
        return error
      })
  }














  public epochByTimezone(params:io.TEpochByTimezoneRequest):Promise<io.TEpochByTimezoneResponse> { 
    return this.http.get(this.baseUrl + this.epochByTimezone.name, new HttpOptions({ params })).toPromise()
      .then(result => {
        this.events.DateTime_onEpochByTimezoneResponse.next(result as io.TEpochByTimezoneResponse)
        return result
      })
      .catch(error => {
        this.events.DateTime_onEpochByTimezoneResponse.next({ output: { success: false } } as io.TEpochByTimezoneResponse)
        return error
      })
  }

  












  public futureDateTime(params:io.TFutureDateTimeRequest):Promise<io.TFutureDateTimeResponse> {
    return this.http.get(this.baseUrl + this.futureDateTime.name, new HttpOptions({ params })).toPromise()
      .then(result => {
        this.events.DateTime_onFutureDateTimeResponse.next(result as io.TFutureDateTimeResponse)
        return result
      })
      .catch(error => {
        this.events.DateTime_onFutureDateTimeResponse.next({ output: { success: false } } as io.TFutureDateTimeResponse)
        return error
      })
  }













  
  public pastDateTime(params:io.TFutureDateTimeRequest):Promise<io.TFutureDateTimeResponse> {
    return this.http.get(this.baseUrl + this.pastDateTime.name, new HttpOptions({ params })).toPromise()
      .catch(error => {
        this.events.DateTime_onFutureDateTimeResponse.next({ output: { success: false } } as io.TFutureDateTimeResponse)
        return error
      })
  }














  public getTimezoneOffset(params:io.TGetTimezoneOffsetRequest):Promise<io.TGetTimezoneOffsetResponse> {
    return this.http.get(this.baseUrl + this.getTimezoneOffset.name, new HttpOptions({ params })).toPromise()
      .then(result => {
        this.events.DateTime_onGetTimezoneOffsetResponse.next(result as io.TGetTimezoneOffsetResponse)
        return result
      })
      .catch(error => {
        this.events.DateTime_onGetTimezoneOffsetResponse.next({ output: { success: false } } as io.TGetTimezoneOffsetResponse)
        return error
      })
  }













  
  public timezones():Promise<io.TTimezonesResponse> {
    return this.http.get(this.baseUrl + this.timezones.name, new HttpOptions()).toPromise()
      .then(result => {
        this.events.DateTime_onTimezonesResponse.next(result as io.TTimezonesResponse)
        return result
      })
      .catch(error => {
        this.events.DateTime_onTimezonesResponse.next({ output: { success: false } } as io.TTimezonesResponse)
        return error
      })
  }

}
