export default function copyOf(data:any, options?:{ deep:boolean, this:any }) {
  let copy = JSON.parse(JSON.stringify(data))
  let functions:any = { }
  if (options?.deep) for (let [ property, value ] of Object.entries(data)) {
    if ((value as any).constructor === Function) functions[property] = (value as Function).toString()
    else if ((value as any).constructor === Object) copy[property] = copyOf(value, options)
  }
  if (functions) for (let [ name, body ] of Object.entries(functions)) copy[name] = eval(body as string)
  return copy
}
