import { Subject } from 'rxjs';
import { TNewAccountData } from '../typings/TNewAccountData.type'
import { IRegistrationAssistantCommonNewLoginService } from './registration-assistant-common-new-login.service.interface'
import { Exactly } from '../../../generic-common/client/typings/Exactly';
import { TCreateResponse } from '../../logins/server/TCreate'
import { ApiService } from '../../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { TReturnGeneric } from '../../../generic-common/client/typings/TReturnGeneric';
import { ILoginsCommonService } from '../../../account-common/logins/client/logins-common.service.interface';
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';
import { IStateCommonService } from '@library/common/state-common/state-common.service.interface';


export abstract class RegistrationAssistantCommonNewLoginService extends ApiService implements Exactly<IRegistrationAssistantCommonNewLoginService, RegistrationAssistantCommonNewLoginService> {

  
  public onFinished:Subject<TReturnGeneric> = new Subject()
  protected abstract override apiId:string


  constructor(
    protected override events:IEventsAppCommonService,
    protected logins:ILoginsCommonService,
    protected state:IStateCommonService
  ) {
    super(events)
    this.events.State_onStateRequest.subscribe(state => this.state)
  }














  public create(params:TNewAccountData):Promise<TCreateResponse> {
    return this.logins.create({ saasName: this.state.saasName, authId: params.authId as string, acctId: params.accountId as string, userId: params.userId as string, email: params.email, friendlyName: (params.lastName && params.firstName)? `${ params.lastName }, ${ params.firstName }` : undefined  })
      .then(result => this.onCreateLoginProfileSuccess(result))
      .catch(error => this.onCreateLoginProfileFailure(error))
  }


      private onCreateLoginProfileSuccess(result:any) {
        this.onFinished.next({ success: true })
        return result
      }


      private onCreateLoginProfileFailure(error:any):void {
        this.onFinished.next({ success: false, details: error })
        return error
      }

}
