import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { ModelLiason } from '@library/common/generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract'
// import { TTagFactory } from '@private/models/tags/types/tag.factory'
// import { settings } from '@private/models/tags/types/tag.settings'
import { environment } from '@env/environment';
import { uiTag } from '@private/models/tags/types/tag.ui'
import Tag from '@private/models/tags/types/tag.model'


@Injectable({
  providedIn: 'root'
})
export class TagsLiason 
extends ModelLiason
//  implements IModelLiasonClass 
{

  // protected override factory
  protected model = Tag
  protected apiId = environment.api.ids.tags

  constructor(
    protected override readonly http:HttpClient,
    public override readonly events:EventsAppService
  ) {
    super(http, events)
  }

  protected assignEndpoints() {
    // this.apiId = this.saas.api.ids.tags
  }

  protected assignEnvironment() {
    // this.saas = environment
  }

  protected assignFactory() {
    // this.factory = TTagFactory
    // this.factory.settings = settings
  }

  protected assignUiModelWrapper() {
    // this.uiModelWrapper = uiTag    
  }

}
