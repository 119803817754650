import { ISupportService } from './support.service.interface'
import { SupportCommonService } from '@library/common/support-common/client/support-common.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class SupportService extends SupportCommonService implements Exactly<ISupportService, SupportService> {

  protected override apiId = environment.api.ids['support']
  
  constructor(
    protected override http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(http, events)
  }














  // protected override assignEndpoints(): void {
    
  // }

  











  
  // protected assignEnvironment() {
    // this.saas = environment
  // }

}