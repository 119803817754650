import { Injectable } from '@angular/core';
// import { ModelLiasonClass } from '@assets/classes/Abstract/ClassBase/ServiceBase/_ModelLiason/ModelLiason.class'
import { HttpClient } from '@angular/common/http';
import { EventsAppService } from '@services/app/events/events-app.service';
import { environment, environment as saas } from '@env/environment'
// import { ISettingsLiason } from './settings.liason.service.interface'
// import { copyOf } from '@assets/utilities/_copyOf';
// import { TSettingsFactory } from '../../../../../../../../library/iqqa-common/api/models/preferences/types/settings.factory'
// import { TPreferencesFactory } from '@private/models/preferences/types/preferences.factory'
import { ModelLiason } from '@library/common/generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract'
import Preference from '../../types/preferences.model'


@Injectable({
  providedIn: 'root'
})
export class PreferencesLiason 
extends ModelLiason
// implements ISettingsLiasonService 
{

  // protected override factory
  protected override model:typeof Preference = Preference
  protected override apiId: string = environment.api.ids.preferences

  constructor(
    protected override readonly http:HttpClient,
    public override readonly events:EventsAppService
  ) {
    super(http, events)
  }

  protected assignEndpoints() {
    // this.apiId = saas.api.ids['preferences']
  }

  protected assignEnvironment() {
    // this.saas = environment
  }

  protected assignFactory() {
    // this.factory = TPreferencesFactory
  }

  protected assignUiModelWrapper() {
    
  }

}
