// // export const environment:TEnvironment = {
import { TEnvironmentSettings } from '../library/common/generic-common/client/typings/TEnvironmentSettings.type'

export const environment:TEnvironmentSettings = {
  saasName: 'iqqa',
  version: '4.0.0',
  stage: `DEV`,
  beta: false,
  supportContactId: 'b1c0fe48-c9bb-47d1-8e49-d789c4ff0aa8',
  production: false,
  downForMaintenance: false,
  google: {
    recaptcha: {
      siteKey: null
    },
  },
  db: {
    logins: '_logins'
  },
  api: {
    protocol: `https`,
    region: 'us-east-2',
    ids: {
      auth: `4w9fxyt54d`,
      database: `icxvg2rrn7`,
      dateTime: `m67c1scveg`,
      logins: `9kkm8uram3`,
      global: `m1cfgl2ma0`,
      schedule: `5nbjfmbxf3`,
      error: `uy8mcrfe1h`,
      devices: `lz8l0wkqib`,
      tags: `2k83x4aug6`,
      questions: `oo4zs2q80e`,
      quizzes: `hx35zlhyw6`,
      preferences: `9kg35bkiy3`,
      support: `gbpn7m1or7`
    }
  },
};
