import { Component } from '@angular/core';
import { ComponentForm } from '../../../classes/Abstract/ComponentForm/ComponentForm.class';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TQuestionPersistence } from '@private/models/questions/types/question.type';
import { UxService } from '@services/ux/ux.service';
import { QuizProcessorService } from '@private/models/quizzes/client/service/quiz-processor.service';
import { exists } from '@library/common/generic-common/tools/utils/concise.exists';
import { TQuiz } from '@private/models/quizzes/types/quiz.type'
import { TTag } from '@private/models/tags/types/tag.type'
import { TFutureDateTimeResponse } from '@library/common/datetime-common/typings/datetime.service.io';
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists'
import { AddQuestionsPage } from '@private/features/add-questions/add-questions.page';


@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss'],
})
export class QuizFormComponent extends ComponentForm {


  public get displaySchedule():boolean {
    return (exists(this, 'form.controls.scheduled.value.value') && this.form.controls['scheduled'].value.value) ? true : false
  }

  public get numberOfIncludedQuestions():number|undefined {
    return (exists(this, 'processor.quiz.links.question')) ? Object.keys(this.processor.quiz.links.question).length : 0
    // return (exists(this, 'isolatedCopy.links.question')) ? Object.keys(this.isolatedCopy.links.question).length : undefined
  }

  public override get canSubmit():boolean {
    if (!exists(this, 'form.valid')) return false
    else if (this.scheduled) {
      let schedule = this.isolatedCopy.schedule as any
      if (!schedule.amount || !schedule.unit || !schedule.militaryTime || !schedule.dueAt) return false
      else return true
    }
    else return true
  }

  public get uiFirstQuiz():string|undefined {
    if (!this.dueAt) return
    let dueAt = new Date(this.dueAt)
    dueAt.setHours(dueAt.getHours() + (360 - 60)/ 60)
    return `${ dueAt.toLocaleString() } - ${ this.processor.timezone }`
  }

  public get descriptionStreak():string {
    return propertyIfExists(this, 'ux.settings.descriptions.streak')
  }

  public tagOptions!:TTag[]
  public streak!:number
  public scheduled!:boolean
  public scheduleOptions!:any
  public override isolatedCopy!:TQuiz
  private dueAt!:string
  private questionsSelected:{
    [key:string]:TQuestionPersistence
  }


  constructor(
    protected override ux:UxService,
    private processor:QuizProcessorService
  ) {
    super(ux)
    this.questionsSelected = { }
  }














  protected override setupEventListeners():void {
    this.eventSubscriptions.push(this.processor.onDueAt.subscribe(timestamp => this.assignDueAt(timestamp)))
  }














  protected override hookSetModelPost() {
    this.scheduleOptions = [
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ]
    if (this.input?.supplementary) this.tagOptions = this.input?.supplementary.tags as TTag[]
    if (this.isolatedCopy.schedule) this.scheduled = (this.isolatedCopy.schedule.militaryTime) ? true : false
    return this
  }














  protected override createForm() {
    this.form = new FormGroup({
      name: new FormControl(this.isolatedCopy.name, { validators: [Validators.required, Validators.minLength(1) ]}),
      questionAmount: new FormControl(this.isolatedCopy.questionAmount || 1, { validators: [Validators.required, Validators.min(1) ]}),
      tags: new FormControl(null),
      streak: new FormControl(this.isolatedCopy.streak),
      schedule: new FormControl(this.isolatedCopy.schedule),
      scheduled: new FormControl(null)
    })
    return this
  }














  protected override hookBindRecordToFormPost() {
    this.patchTagsFormValue()
    this.patchScheduledFormValue()
    return this
  }


      private patchTagsFormValue() {
        let selectedTags:TTag[] = [ ]
        Object.keys(this.isolatedCopy.links.tag).forEach(key => {
          let selectedTag = this.tagOptions.find(option => option.id === key)
          if (selectedTag) selectedTags.push(selectedTag)
        })
        this.form.controls['tags'].patchValue(selectedTags)
      }
        
    
      private patchScheduledFormValue() {
        if (this.form.controls['schedule'].value) this.form.controls['scheduled'].patchValue({ name: "Yes", value: true })
        else this.form.controls['scheduled'].patchValue({ name: "No", value: false })
      }













          
  public getSelectedTagState(option:TTag):boolean|void {
    if (!this.isolatedCopy.links.tag) return
    if (Object.keys(this.isolatedCopy.links.tag).includes(option.id)) return true
    else return false
  }
















  public onBrowseQuestions() {
    this.bindFormValuesToRecord()
    this.processor.quiz = this.isolatedCopy
    this.ux.events.Ux_onOverlay.next({ display: true, component: AddQuestionsPage })
  }
















  public onUser_ToggleSchedule() {
    // if (this.form.controls.scheduled.value === true) this.ux.events.Ux_onStateChange.next({ is: 'loading' })
    // else this.ux.events.Ux_onStateChange.next({ is: 'ready' })
  }














  protected override bindFormValuesToRecord():this {
    for (let [ field, value ] of Object.entries(this.form.value)) {
      if (['schedule', 'tags', 'question', 'scheduled'].includes(field)) continue
      this.isolatedCopy[field] = value
    }
    return this
  }














  protected override hookMutateDataBeforeEmit() {
    this.isolatedCopy.links.tag = { }
    if (this.form.value.tags) this.form.value.tags.forEach(tag => this.isolatedCopy.links.tag[tag.id] = null)
    return this
  }














  public onScheduleSettings(event:any) {
    this.isolatedCopy.schedule = event
    this.getNextDueDate()
  }















  private getNextDueDate():Promise<number> {
    return this.processor.getNextDueDate(this.isolatedCopy.schedule)
      .then(result => this.onGetNextDueDateSuccess(result))
      .catch(error => this.onGetNextDueDateFailure(error))
  }


      private onGetNextDueDateSuccess(result:TFutureDateTimeResponse) {
        if (!result.output.success) return this.onGetNextDueDateFailure(result)
        else this.isolatedCopy.schedule.dueAt = result.output.details.utcEpoch
      }


      private onGetNextDueDateFailure(error:any) {
        this.ux.events.Ux_onFeedbackSecondary.next({ message: this.ux.settings.standardMessages.errorGeneric, state: 'failure' })
        return error
      }













      
  private assignDueAt(event:TFutureDateTimeResponse) {
    this.dueAt = event.output.details.localEpoch
    this.isolatedCopy.schedule.dueAt = event.output.details.utcEpoch
  }













  
  public onSelectTag(event) {
    
  }

}
