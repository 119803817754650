import { Injectable } from '@angular/core';
import { TFutureDateTimeRequest, TFutureDateTimeResponse } from '@library/common/datetime-common/typings/io.TFutureDateTime.type';
import { TQuestionPersistence, TQuestionPresentation } from '@private/models/questions/types/question.type';
import { TQuiz } from '@private/models/quizzes/types/quiz.type';
import { AppService } from '@services/app/app.service'
// import { settings } from '@private/models/quizzes/types/quiz.settings'
import { TJob } from '@library/common/schedule-common/typings/TJob.type';
import { SchedulerComponent } from '@library/widgets/scheduler/scheduler.component';
import { TSchedule } from '@library/common/datetime-common/typings/TSchedule.type';
import { militaryTimeGet } from '@library/common/generic-common/tools/formatters/militaryTimeGet'
import { militaryTimeSet } from '@library/common/generic-common/tools/formatters/militaryTimeSet'
import { determineOddEven } from '@library/common/generic-common/tools/utils/determineOddEven';
import { TReadResponse } from '@library/common/generic-common/server/typings/TReadRequest.type';
import { scheduledJobSendEmailFactory } from '@library/common/schedule-common/typings/TScheduledJobSendEmail.type'
import { scheduleJobPushNotificationFactory } from '@library/common/schedule-common/typings/TScheduledJobPushNotification.type'
import { ConciseService } from '@library/common/generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract';
import { TGetTimezoneOffsetResponse } from '@library/common/datetime-common/typings/io.TGetTimezoneOffset.type';
import { Subject } from 'rxjs';
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists'
import Quiz from '@private/models/quizzes/types/quiz.model'
import Tag from '@private/models/tags/types/tag.model'

export type temporaryNothing = any

@Injectable({
  providedIn: 'root'
})
export class QuizProcessorService extends ConciseService {


  public get timezone():string { return propertyIfExists(this, 'app.state.timezone') }
  public onDueAt:Subject<TFutureDateTimeResponse> = new Subject()
  // public settings:typeof settings = settings
  public model:typeof Quiz = Quiz
  public quiz!:TQuiz
  public quizzes!:TQuiz[]
  public questions!:TQuestionPresentation[]
  public tags!:Tag[]


  constructor(
    private app:AppService
  ) { 
    super(app)
    // this.factory.settings = this.settings 
    this.app.events.DateTime_onFutureDateTimeResponse.subscribe(result => this.onDueAt.next(result))
  }














  public create() {
    return new this.model()
  }














  public getRecord(params) {
    return Promise.all([
      this.app.api.models.quizzes.get.id({ value: params.id }),
      this.app.api.models.tags.get.all()
    ])
    // return this.app.api.models.quizzes.get.id({ value: params.id })
      .then(result => {
        this.quiz = result[0]
        this.tags = result[1]
        return this.quiz
      })
  }













  
  // public getTags():Promise<any[]> {
  //   return this.app.api.models.tags.get.all()
  // }














  public getAllQuizzes():Promise<TQuiz[]> {
    return this.app.api.models.quizzes.get.all()
      .then(results => {
        this.quizzes = results
        return this.quizzes
      })
  }














  public getAllQuestions():Promise<TQuestionPresentation[]> {
    return this.app.api.models.questions.get.all()
      .then(results => {
        this.questions = results
        return this.questions
      })
  }













  
  public getNextDueDate(params:TFutureDateTimeRequest):Promise<TFutureDateTimeResponse> {
    return this.app.api.datetime.futureDateTime(params)
  }














  public setStreak():number {
    return JSON.parse(localStorage.getItem('settings') || '{ }').streak || 1 //this.app.settings.defaultStreak
  }














  public setAlreadySelectedQuestions():TQuestionPersistence[] {
    return this.questions.map((question:any) => {
      question.selected = this.setProperCheckboxState(question)
      return question
    })
  }


      private setProperCheckboxState(question):boolean {
        if (Object.keys(this.quiz.links.question).length === 0) return false
        if (question.id in this.quiz.links.question) return true
        else return false
      }














  public toggleQuestion(questionOptions:TQuestionPresentation[]):void {
    this.quiz.links.question = { }
    questionOptions
      .filter(question => question.selected)
      .map(question => question.id)
      .forEach(id => this.quiz.links.question[id] = null)
  }














  public update(params:TQuiz):Promise<any> {
    return Promise.all([
      this.app.api.models.quizzes.update({ item: params }),
      this.schedulePushNotification(params),
      this.scheduleEmailNotification(params)
    ])
    .finally(()=> this.quiz = null as temporaryNothing)
  }


      private schedulePushNotification(record):Promise<any> {
        if (!record.schedule) return Promise.resolve({ output: { success: true } })
        else {
          let jobPushNotification:TJob = scheduleJobPushNotificationFactory({
            accountId: this.app.state.accountId,
            id: record.id,
            saasName: this.app.state.saasName,
            stage: this.app.state.stage,
            title: `Quiz Due`,
            body: `Your ${ record.name } quiz is due.`,
            to: this.app.api.models.devices.records.map(device => device.fcmToken),
            dueAt: record.schedule.dueAt,
            timezone: this.app.state.timezone as string,
            schedule: record.schedule
          })
          return this.app.api.schedule.update({ item: jobPushNotification })
        } 
      }


      private scheduleEmailNotification(record):Promise<any> {
        if (!record.schedule || !this.app.state.get({ key: 'emailNotifications' })) return Promise.resolve({ output: { success: true } })
        else {
          let jobEmailNotification:TJob = scheduledJobSendEmailFactory({
            accountId: this.app.state.accountId,
            id: record.id,
            dueAt: record.schedule.dueAt,
            fromEmail: 'test',//this.app.settings.emailNotificationsFrom,
            saasName: this.app.state.saasName,
            stage: this.app.state.stage,
            timezone: this.app.state.timezone as string,
            toAddresses: [this.app.state.get({ key: 'email' })],
            subject: `Quiz Due`,
            body: `Your ${ record.name } quiz is now due.  You can log in <a href="www.iqqa.link">here</a> or if your email doesn't allow direct links you can go to https://www.iqqa.link`,
            bodyTextVersion: `Your ${ record.name } quiz is now due.  You can log in here:  https://www.iqqa.link`,
            schedule: record.schedule
          })
          return this.app.api.schedule.update({item: jobEmailNotification })
        } 
      }













          
  public getMilitaryTime(params:Date):string|undefined {
    return militaryTimeGet(params)
  }














  public setMilitaryTime(params:string):Date {
    return militaryTimeSet(params)
  }













      
  public createScheduleOutput(params:SchedulerComponent):TSchedule {
    return { 
      amount: params.form.controls['rate'].value, 
      unit: params.form.controls['unit'].value.value, 
      militaryTime: params.uiTime as string,
      gmtOffset: params.gmt,
      timezone: this.app.state.timezone as string,
      dueAt: null as temporaryNothing
    }
  }














  public getUtcOffset():Promise<number> {
    return this.app.api.datetime.getTimezoneOffset({ timezone: this.app.state.timezone || '' })
      .then(result => this.onGetUtcOffsetSuccess(result))
      .catch(error => this.onGetUtcOffsetFailure(error))
  }


      private onGetUtcOffsetSuccess(result:TGetTimezoneOffsetResponse):number|void { 
        if (!result.output.success) return this.onGetUtcOffsetFailure(result)
        else return parseInt(result.output.details.timezoneOffset)
      }


      private onGetUtcOffsetFailure(error) { 
        this.hasFailed({ at: this.onGetUtcOffsetFailure, error })
        return error
      }






      







  public setRowColor(index:number):'odd'|'even' {
    return determineOddEven(index)
  }

}
