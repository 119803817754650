import { Component, OnInit, Input } from '@angular/core';
import Question from '@private/models/questions/types/question.model';
import { TTag } from '@private/models/tags/types/tag.type';
import { BaseFormQuestion } from '../BaseFormQuestion.class'
import { QuestionProcessorService } from '@private/models/questions/client/service/question-processor.service'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-question-text-form',
  templateUrl: './question-text-form.component.html',
  styleUrls: ['./question-text-form.component.scss'],
})
export class QuestionTextFormComponent extends BaseFormQuestion implements OnInit {

  @Input() override input?:{ record:Question, supplementary: { tags:TTag[] } }
  public override isolatedCopy:any
  public override tags!:any[]


  constructor(
    protected override ux:UxService,
    protected override processor:QuestionProcessorService
  ) {
    super(ux, processor)
  }



  












  protected override hookSetModelPost() {
    this.isolatedCopy.type = 'text'
    return this
  }

}
