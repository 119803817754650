import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { IEventsAppService } from './events-app.service.interface'
import { EventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service'


@Injectable({
  providedIn: 'root'
})
export class EventsAppService extends EventsAppCommonService implements Exactly<IEventsAppService, EventsAppService> { 

  
}