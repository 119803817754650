import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { ModelLiason } from '@library/common/generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract'
// import { ModelLiasonClass } from '@assets/classes/Abstract/ClassBase/ServiceBase/_ModelLiason/ModelLiason.class'
import { IQuizzesLiason } from './QuizzesLiason.service.interface'
// import { settings } from './quiz.settings'
// import { TQuizFactory } from '@library/api/models/quizzes/types/quiz.factory'
// import { settings } from '@library/api/models/quizzes/types/quiz.settings'
import { environment } from '@env/environment'
import Quiz from '../../types/quiz.model'
// import { uiQuiz } from '@library/api/models/quizzes/types/quiz.ui'


@Injectable({
  providedIn: 'root'
})
// export class QuizLiasonService extends ModelLiasonClass implements IQuizLiasonService {
export class QuizzesLiason extends ModelLiason implements IQuizzesLiason {

  protected model = Quiz
  protected override apiId:string = environment.api.ids.quizzes

  constructor(
    protected override http:HttpClient,
    public override events:EventsAppService
  ) {
    // super(QuizLiasonService, http, events)
    super(http, events)
  }

  protected assignEnvironment() {
    // this.saas = environment
  }

  protected assignEndpoints() {
    // this.apiId = this.saas.api.ids.quizzes
  }


  protected assignFactory() {
    // this.factory = TQuizFactory
    // this.factory.settings = settings
  }

  protected assignUiModelWrapper() {
    // this.uiModelWrapper = uiQuiz    
  }

}
