import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment'
import { EventsAppService } from '@services/app/events/events-app.service';
import Device from '@private/models/devices/types/device.model'
// import { TDevice } from '@private/models/devices/types/device.type';
import { ModelLiason } from '@library/common/generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract'
// import { TReadResponse } from '@library/common/generic-common/server/typings/TReadRequest.type';


@Injectable({
  providedIn: 'root'
})
export class DevicesLiason extends ModelLiason {

  public records!:Device[]

  // protected override factory
  protected override model: any = Device
  protected override apiId: string = environment.api.ids.devices

  constructor(
    protected override http:HttpClient,
    public override events:EventsAppService,
  ) { 
    super(http, events)
    this.whatever()
  }

  protected assignEndpoints() {
    // this.apiId = this.saas.api.ids.devices
  }

  protected assignEnvironment() {
    // this.saas = environment
  }

  protected assignFactory() {
    // this.factory = TDeviceFactory
  }

  protected assignUiModelWrapper() {
    
  }















  protected whatever():void {
    this.get.all()
      .then((result:any) => this.onGetAllDevicesSuccess(result))
      .catch(error => this.onGetAllDevicesFailure(error))
  }


      private onGetAllDevicesSuccess(results:Device[]) {
        if (!results) return this.onGetAllDevicesFailure(results)
        else this.records = results
      }


      private onGetAllDevicesFailure(error) { 
        this.hasFailed({ at: this.onGetAllDevicesFailure, error })
      }






      







  private onDeviceInfo(deviceInfo) {
    if (!deviceInfo || this.deviceIsAlreadySaved(deviceInfo)) return
    else this.saveNewDevice(deviceInfo)
  }


      private deviceIsAlreadySaved(deviceInfo) {
        return (this.records.length > 0 && this.records.find(device => device.fcmToken === deviceInfo.fcmToken))
      }














  private saveNewDevice(deviceInfo) {
    this.update(deviceInfo)
      .then(result => this.saveNewDeviceSuccess(result))
      .catch(error => this.saveNewDeviceFailure(error))
  }


      private saveNewDeviceSuccess(result) {
        if (!result.success) this.saveNewDeviceFailure(result)
        // Else do nothing.
      }


      private saveNewDeviceFailure(error) {
        console.log('saveNewDeviceFailure...', error)  
        // this.hasFailed({ signature: 'error-4b9ce801-a7d5-4dd5-81ea-93d2d889c19d', error: error })
        // Do nothing, fail gracefully
      }

}
