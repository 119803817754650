import { Injectable } from '@angular/core'
import { ILoginsService } from './logins.service.interface'
import { LoginsCommonService } from '@library/common/account-common/logins/client/logins-common.service'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class LoginsService extends LoginsCommonService implements Exactly<ILoginsService, LoginsService> {


  constructor(
    protected override http:HttpClient,
    protected override events:EventsAppService
  ) {
    super(http, events)
  }













  
  // protected assignEnvironment() {
  //   this.saas = environment
  // }

}
