import { IInterpreterService } from './interpreter.service.interface'
import { InterpreterCommonService } from '@library/common/interpreter-common/interpreter-common.service'
import { EventsUxService } from '../events/events-ux.service'
import { Injectable } from '@angular/core'


@Injectable({
  providedIn: 'root'
})
export class InterpreterService extends InterpreterCommonService implements IInterpreterService {

  constructor(
    protected override events:EventsUxService
  ) {
    super(events)
  }

}