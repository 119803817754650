import { ILogsCommonService } from './logs-common.service.interface'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface'
import { TOnCodeCall, TApp_onError } from '@library/common/logs-common/client/events/app/logs-app-common.events.io'
import { TLogs_onUserAction, TLogs_onNavigation } from '@library/common/logs-common/client/events/ux/logs-ux-common.events.io'
import { TLogRecord } from '../types/TLogRecord.type'
import { IEventsUxCommonService } from '@library/common/events-common/client/ux/events-ux-common.service.interface'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly';
import { HttpOptions } from '@library/common/generic-common/client/classes/HttpOptions/HttpOptions.class'
import { HttpClient } from '@angular/common/http'
import { TGlobalServiceGetValueRequest, TGlobalServiceGetValueResponse } from '../../generic-common/server/interfaces/global-server-service.interface'
import { environment } from '@env/environment'


export abstract class LogsCommonService implements Exactly<ILogsCommonService, LogsCommonService> {


  protected saas:any


  constructor(
    protected app:IEventsAppCommonService,
    protected ux:IEventsUxCommonService,
    protected http:HttpClient
  ) {
    this.setEnvironment()
    this.loggingExclusions()
    this.setupEvents()
    this.initialize()
  }














  protected abstract setEnvironment():void














  private loggingExclusions():void {
    let params:TGlobalServiceGetValueRequest = { value: `${ environment.saasName }_loggingExclusions` }
    let options = new HttpOptions({ params })
    this.http.get(`${ this.saas.api.protocol }://${ this.saas.api.ids.global }.execute-api.${ this.saas.api.region }.amazonaws.com/${ this.saas.stage }/get`, options).toPromise()
      .then(result => {
        if ((result as TGlobalServiceGetValueResponse).output.success) localStorage.setItem('loggingExclusions', JSON.stringify((result as TGlobalServiceGetValueResponse).output.details.Items[0].details))
        else console.warn(`Unable to set loggingExclusions value from server.`)
      })
  }














  private setupEvents() {
    this.app.Logs_onError.subscribe(error => this.onError(error))
    this.app.Logs_onCodeCall.subscribe(call => this.onCodeCall(call))
    this.ux.Logs_onCodeCall.subscribe(call => this.onCodeCall(call))
    this.ux.Logs_onUserAction.subscribe(action => this.onUserAction(action))
    this.ux.Logs_onNavigation.subscribe(event => this.onNavigation(event))
  }

  














  private initialize() {
    let log = localStorage.getItem('log')
    if (!log) try {
      localStorage.setItem('log', JSON.stringify([]))
    } catch (error) {
      console.error(error)
    }
  }














  private onUserAction(action:TLogs_onUserAction) {
    let item:TLogRecord = { datetime: new Date().valueOf(), type: 'action', origin: action.origin, action: action.method, details: action.details }
    this.writeItem(item)
  }














  private onCodeCall(event:TOnCodeCall) {
    let item:TLogRecord = { datetime: new Date().valueOf(), type: 'call', origin: event.origin, action: event.method, details: event.details }
    this.writeItem(item)
  }














  private onNavigation(event:TLogs_onNavigation) {
    let item:TLogRecord = { datetime: new Date().valueOf(), type: 'navigation', origin: 'router', details: event.details }
    this.writeItem(item)
  }














  private onError(error:TApp_onError) {
    let item:TLogRecord = { datetime: new Date().valueOf(), type: 'error', origin: error.origin, action: error.name, details: error.details }
    console.error(item)
    this.writeItem(item)
  }













  
  private writeItem(item:any) {  try {
    let log:TLogRecord[] = JSON.parse(localStorage.getItem('log') as string)
    if (!log) log = [ ]
    log.push(item)
    localStorage.setItem('log', JSON.stringify(log))
  } 
  catch (error) { this.onWriteItemFailure(error) } }


      private onWriteItemFailure(error:any) {
        this.truncateLog()
      }














  private truncateLog() {
    let log:TLogRecord[] = JSON.parse(localStorage.getItem('log') as string)
    if (!log || log.length < 100) return
    let truncateBy = Math.round(log.length / 2)
    let cleanedLog = log.slice(-truncateBy)
    console.log(`Original: ${ log.length } - Cleaned: ${ cleanedLog.length }`)
    localStorage.setItem('log', JSON.stringify(cleanedLog))
  }

}
