import { Component, Input } from '@angular/core';
import { TBreadcrumb } from '@library/types/TBreadcrumbs.type';
import { UxService } from '@services/ux/ux.service';
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends ConciseComponent {

  @Input() breadcrumbs!:TBreadcrumb[]


  constructor(
    public override ux:UxService
  ) { 
    super(ux)
  }














  public onUser_NavigateCrumb(crumb:TBreadcrumb) {
    this.navigateProcedure(crumb)
  }















  public onUser_NavigateDashboard() {
    this.navigateProcedure({ url: ['dashboard'] } as TBreadcrumb)
  }














  public onUser_NavigateSearch() {
    this.navigateProcedure({ url: ['search'] } as TBreadcrumb)
  }













  public onUser_NavigateSettings() {
    this.navigateProcedure({ url: ['preferences'] } as TBreadcrumb)
  }













  
  private navigateProcedure(params:TBreadcrumb) {
    if (params.url) this.ux.navigation.navigate(params.url)
  }














  public isClickable(crumb:TBreadcrumb):string {
    if (crumb.url) return 'clickable'
    else return ''
  }

}
