export default function propertyIfExists(object:any, nestedPropertiesString:string) {
  if (!object || !nestedPropertiesString) return
	let value
	for (let property of nestedPropertiesString.split('.')) {
    if (!object) return
    if (property in object) object = value = object[property]
    else return
  }
  return value
}
