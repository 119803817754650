<form [formGroup]="form">
  <h3>Question:</h3>
    <div class="formContent">
      <textarea rows="6" pTextarea>{{ uiQuestion }}</textarea>
    </div>
  <h3>Your Answer:</h3>
    <div class="formContent">
      <textarea formControlName="userAnswer" rows="6" pTextarea></textarea>
    </div>
  <div *ngIf="mode === 'grade'">
    <h3>Correct Answer:</h3>
      <div class="formContent">
        <textarea rows="6" pTextarea>{{ uiAnswer }}</textarea>
      </div>
    <h3>Reason:</h3>
      <div class="formContent">
        <textarea rows="6" pTextarea>{{ uiReason }}</textarea>
      </div>
  </div>
  <div *ngIf="mode === 'grade'">
    <h3>Grade Your Answer:</h3>
      <div class="formContent">
        <p-dropdown [options]="correctOptions" formControlName="correct" placeholder="Select One" optionLabel="name"></p-dropdown>
      </div>
  </div>
  <hr class="formContent">
  <div id="buttonContainer">
    <button label="Next" class="buttonTertiary" (click)="onUser_Submit()" pButton></button>
  </div>
</form>
