<div id="container">

  <div id="primaryDescriptor">
    <span>{{ primaryDescriptor }}</span>
  </div>

  <div id="secondaryDescriptor">{{ secondaryDescriptor }}</div>

  <div *ngIf="primaryAction" id="primaryAction">
    <button id="primaryActionButton" (click)="primaryAction.action(id)" class="mx-1">
      <i [class]="primaryAction.icon"></i>
    </button>
  </div>

  <div *ngIf="secondaryAction" id="secondaryAction">
    <button id="secondaryActionButton" (click)="secondaryAction.action(id)" class="mx-1">
      <i [class]="secondaryAction.icon"></i>
    </button>
  </div>

</div>