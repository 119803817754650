import { Component } from '@angular/core';
import { TUiFeedback } from '@library/common/generic-common/client/typings/TUiFeedback.type';
import copyOf from '@library/common/generic-common/tools/utils/concise.copyOf';
import { id } from '@library/common/generic-common/tools/utils/concise.short.id';
import { UxService } from '@services/ux/ux.service';
import { ConciseComponent } from '@library/common/generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract';
import { slideInOut } from '@library/common/generic-common/client/animations/slideInOut'


type Notice = TUiFeedback & { id:string }


@Component({
  selector: 'app-feedback-toaster',
  templateUrl: './feedback-toaster.component.html',
  styleUrls: ['./feedback-toaster.component.scss'],
  animations: [slideInOut]
})
export class FeedbackToasterComponent extends ConciseComponent {

  public notices:Notice[] = [ ]


  constructor(
    public override ux:UxService
  ) { 
    super(ux)
  }














  protected override setupEventListeners() {
    this.eventSubscriptions.push(this.ux.events.Ux_onFeedbackSecondary.subscribe(request => this.onFeedback(request)))
  }














  public onFeedback(feedback:TUiFeedback) {
    let notice = copyOf(feedback)
    if (this.notices.find(previousNotice => this.alreadyNotified({ previousNotice, notice }))) return
    else this.addNewNotice(notice)
  }














  private alreadyNotified(params:{ previousNotice:Notice, notice:Notice }):boolean|undefined {
    if (params.notice.message === params.previousNotice.message 
      && params.notice.state === params.previousNotice.state) return <unknown>params.previousNotice as boolean
    else return
  }














  private addNewNotice(notice) {
    notice.id = id()
    this.notices.unshift(notice)
  }














  public onDismiss(id:string):void {
    this.notices = this.notices.filter(notice => notice.id !== id)
  }

}
