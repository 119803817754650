import { TSchedule } from '../../datetime-common/typings/TSchedule.type'
import { TJob } from './TJob.type'


export type TScheduledJobPushNotificationParams = { saasName:string, stage:'DEV'|'PROD', id:string, dueAt:number, timezone:string, accountId:string, title:string, body:string, to:string[], schedule?:TSchedule }

export function scheduleJobPushNotificationFactory(params:TScheduledJobPushNotificationParams):TJob {
  let lambdaName = `${ params.saasName }-quizzes-${ params.stage }-reminder`
  let job:TJob = {
    id: `${ params.id }-${ params.dueAt }-${ lambdaName }`,
    createdAt: new Date().valueOf(),
    updatedAt: new Date().valueOf(),
    dueAt: params.dueAt,
    status: 'queued',
    timezone: params.timezone,
    saas: params.saasName,
    accountId: params.accountId,
    boundTo: params.id,
    details: {
      lambdaName: lambdaName,
      params: {
        to: params.to,
        title: params.title,
        body: params.body
      }
    }
  }
  if (params.schedule) job.schedule = params.schedule
  return job
}