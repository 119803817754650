import { Injectable } from '@angular/core'
import { ILayersService } from './layers.service.interface'
import { TLayer } from '@library/common/generic-common/client/typings/TLayer.type'
import { EventsUxService } from '@services/ux/events/events-ux.service'
import { TUx_onStateChange } from '@library/common/events-common/client/ux/events-ux-common.service.io'


@Injectable({
  providedIn: 'root'
})
export class LayersService implements ILayersService {

  public 'layer-loading':TLayer
  public 'layer-primary-channel':TLayer
  public 'layer-secondary-channel':TLayer
  public 'layer-overlay':TLayer
  public 'layer-main':TLayer
  public 'layer-background':TLayer


  constructor(
    private events:EventsUxService
  ) {
      this.makeBackgroundLayer()
      this.makeMainLayer()
      this.makeSecondaryChannelLayer()
      this.makePrimaryChannelLayer()
      this.makeOverlayLayer()
      this.makeLoadingLayer()
      this.setupEventListeners()
    }














    private makeBackgroundLayer() {
      this['layer-background'] = {
        name: 'background',
        display: true,
        style: { 'z-index':  4000, }
      }
    }














    private makeMainLayer() {
      this[`layer-main`] = {
        name: 'main',
        display: true,
        style: { 'z-index':  5000 }
      }
    }














    private makeSecondaryChannelLayer() {
      this[`layer-secondary-channel`] = {
        name: 'secondary-channel',
        display: true,
        style: { 'z-index':  6000,
          position: 'absolute',
          bottom: '45px',
          right: '0px',
          marginRight: '1rem',
          width: '250px',
          display: 'flex',
          flexDirection: 'column-reverse',
        }
      }
    }














    private makePrimaryChannelLayer() {
      this[`layer-primary-channel`] = {
        name: 'primary-channel',
        display: true,
        style: { 'z-index':  7000,
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '0px'
        }
      }
    }














    private makeOverlayLayer() {
      this[`layer-overlay`] = {
        name: 'overlay',
        display: false,
        style: { 'z-index':  8000,
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%'
        }
      }
    }













    
  private makeLoadingLayer() {
    this['layer-loading'] = {
      name: 'loading',
      display: false,
      style: { 
        'z-index':  9000,
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
      }
    }
  }













    
  private setupEventListeners() {
    this.events.Ux_onStateChange.subscribe(state => this.onStateChange(state))
  }













  
  private onStateChange(state:TUx_onStateChange) {
    if (state.is === 'working') this['layer-loading'].display = true
    else this['layer-loading'].display = false
  }

}
