<main>
  <div id="prompt">
    <section id="body">
      <p>{{ message }}</p>
    </section>
    <section id="actions">
      <hr>
      <button [label]="labelDismiss" (click)="onDismissButton()" class="buttonTertiary" pButton></button>
    </section>
  </div>
</main>
