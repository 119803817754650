<main>

  <h1>Privacy Policy for IQQA</h1>

    <p>At IQQA, accessible from www.iqqa.link, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by IQQA and how we use it.</p>

    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

  <h3>Privacy Policies</h3>

    <p>The app IQQA and it's developers Concise Constructs, LLC. will never sell or share your information to any third-party.  </p>

    <p>Third-party advertising or library servers use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that may appear on IQQA, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

    <p>Note that IQQA has no access to or control over these cookies that are used by third-party advertisers.</p>

  <h3>Third Party Privacy Policies</h3>

    <p>IQQA's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

  <h3>User Information</h3>

    <p>IQQA does not knowingly collect any Personal Identifiable Information other than email addresses used to gain entry to the website or application.  Once a user gains entry to the website or application a unique device identifier may be recorded and stored to allow users to toggle which devices they wish to receive reminders on.</p>

  <h3>Device Features</h3>

    <p>Mobile users of IQQA may be prompted for the application to use certain features of the device.  The features are implemented to enhance the user experience of the app and are not used to gather information.  Any information that is gathered either through the app or website will not be sold to any third party.</p>

  <h3>Data Retention & Deletion</h3>

    <p>Data generated by the user will be held in the IQQA database unless an account closure request at the address of <a href="https://www.iqqa.link/policies/data-removal" class="clickable">https://www.iqqa.link/policies/data-removal</a> is initiated by the user.  Once the request is deemed valid via confirmation of email account ownership of the account holder, this request removes all user data and effectively closes the user's account for the IQQA application.  If the user has only created an account at IQQA and no other service or app provided by the developer Concise Constructs, LLC this will also effectively remove all data in regards to the user possessed by the developer.</p>

  <h3>Consent</h3>

  <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
  <div id="ending">&nbsp;</div>

</main>