import { ConciseService } from '../generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract'
import { IInterpreterCommonService } from './interpreter-common.service.interface'
import { EventsUxCommonService } from '../events-common/client/ux/events-ux-common.service'
import { exists } from '../generic-common/tools/utils/concise.exists'
import { Exactly } from '../generic-common/client/typings/Exactly'


export abstract class InterpreterCommonService extends ConciseService implements Exactly<IInterpreterCommonService, InterpreterCommonService> {

  private generic_messageDefault:string = `An error occurred, please try again.`
  private auth_messageDefault:string = `Unable to log in/out.  If you haven't verfied your email please do that first.  Otherwise, you can try again or try reloading the page/app.`


  constructor(
    protected override events:EventsUxCommonService
  ) {
    super(events)
  }














  public error(error:any):string|undefined {
    if (!error) return
    switch (error.constructor.name) {
      case 'HttpErrorResponse': return this.interpretErrorAsHttpError(error)
      case 'DropinError': return this.interpretErrorForBraintree(error)
      case 'String': return this.interpretErrorAsString(error)
      case 'Object': return this.interpretErrorObject(error)
      default: return
    }
  }














  private interpretErrorAsHttpError(error:any):string {
    // Develop this as new error codes are encountered
    switch (error.status) {
      case 401: return `The current user does not authorization to access this request.  If you have multiple users make sure you are logged in as a user which has authorization.`
      case 403: return `This request is not allow.`
      case 404: return `The requested resource was not found.  You could try again as it may be temporarily unavailable.`
      case 500: return `Unable to connect to server.  Please try again.`
      case 400: return `Your request did not go through.  You can try again.`
      case 408: return `Your request timed out.  Please try again.`
      case 503: return `The server is unable to handle your request at this time.  Please try again later.`
      default: return `An unknown error occurred.  Please try again.`
    }
  }













  
  private interpretErrorForBraintree(error:any):undefined {
    // Develop this as new error codes are encountered
    switch (error.message) {
      case "No payment method is available.": return
      default: return
    }
  }














  private interpretErrorObject(error:any):string|undefined {
    if (error.message) return error.message
    else if (error.code) return this.interpretErrorCode(error)
    else if (error.details && error.details.constructor === String) return error.details
    else return this.generic_messageDefault
  }














  private interpretErrorCode(error:any):string {
    // Develop this as new error codes are encountered
    switch (error.code) {
      default: return this.generic_messageDefault
    }
  }














  private interpretErrorAsString(error:string) { 
    // Develop this as errors are encountered
    return this.generic_messageDefault
  }














  public authorization(params:any):string {
    if (exists(params, 'details.output.thirdParty.code')) return this.autorizationCode(params)
    else if (exists(params, 'details.output.thirdParty.message')) return params.details.output.thirdParty.message
    else return this.auth_messageDefault
  }


      private autorizationCode(params:any):string {
        switch (params.details.output.thirdParty.code) {
          case "UserNotConfirmedException": return `Please check your email for a message from "no-reply@verificationemail.com" and click the verification link before logging in.`
          case 'UserNotFoundException': return `Invalid login credentials.  Please re-enter the values and try again.`
          case 'PasswordResetRequiredException': return `A password reset request was made.  Please check your email and reset your password before attempting to login.`
          case 'UsernameExistsException': return `An account with the given email already exists.  Try logging in instead of signing up.`
          case 'TooManyRequestsException': return `There have been too many attempts.  Please wait a while and try again.`
          case 'NotAuthorizedException':return params.details.output.thirdParty.message
          case 'InternalErrorException': return this.auth_messageDefault
          case 'InvalidParameterException': return this.auth_messageDefault
          case 'InvalidLambdaResponseException': return this.auth_messageDefault
          case 'ResourceNotFoundException': return this.auth_messageDefault
          case 'UnexpectedLambdaException': return this.auth_messageDefault
          case 'InvalidSmsRoleAccessPolicyException': return this.auth_messageDefault
          case 'InvalidSmsRoleTrustRelationshipException': return this.auth_messageDefault
          case 'InvalidUserPoolConfigurationException': return this.auth_messageDefault
          case 'UserLambdaValidationException': return this.auth_messageDefault
          default: return this.auth_messageDefault
        }
      }

}