import { HttpClient } from '@angular/common/http'
import { ApiService } from '../../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract'
import { HttpOptions } from '../../../generic-common/client/classes/HttpOptions/HttpOptions.class'
import { IAuthServerService } from '../server/interfaces/auth-server-service.interface'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface'
import * as io from '../typings/io.index'
import { environment } from '@env/environment'


export abstract class AuthCommonService extends ApiService implements IAuthServerService {


  protected override apiId:string = environment.api.ids.auth

  
  constructor(
    protected http:HttpClient,
    protected override events:IEventsAppCommonService
  ) { 
    super(events)
  }














  public signIn(params:io.TSignInRequest):Promise<io.TSignInResponse> {
    return this.http.post(this.baseUrl + this.signIn.name, new HttpOptions({ params })).toPromise() as Promise<io.TSignInResponse>
  }














  public signOut(params:io.TSignOutRequest):Promise<io.TSignOutResponse> {
    return this.http.post(this.baseUrl + this.signOut.name, new HttpOptions({ params })).toPromise() as Promise<io.TSignOutResponse>
  }














  public passwordChange(params:io.TPasswordChangeRequest):Promise<io.TPasswordChangeResponse> {
    return this.http.post(this.baseUrl + this.passwordChange.name, new HttpOptions({ params })).toPromise() as Promise<io.TPasswordChangeResponse> 
  }













  
  public passwordForgot(params:io.TPasswordForgotRequest):Promise<io.TPasswordForgotResponse> {
    return this.http.post(this.baseUrl + this.passwordForgot.name, new HttpOptions({ params })).toPromise() as Promise<io.TPasswordForgotResponse> 
  }














  public passwordReset(params:io.TPasswordForgotRequest):Promise<io.TPasswordForgotResetResponse> {
    return this.http.post(this.baseUrl + this.passwordReset.name, new HttpOptions({ params })).toPromise() as Promise<io.TPasswordForgotResetResponse> 
  }














  public profileCreate(params:io.TAuthProfileCreateRequest):Promise<io.TAuthProfileCreateResponse> {
    return this.http.post(this.baseUrl + this.profileCreate.name, new HttpOptions({ params })).toPromise() as Promise<io.TAuthProfileCreateResponse>
  }














  public profileRemove(params:io.TAuthProfileRemoveRequest):Promise<io.TAuthProfileRemoveResponse> {
    return this.http.post(this.baseUrl + this.profileRemove.name, new HttpOptions({ params })).toPromise() as Promise<io.TAuthProfileRemoveResponse>
  }

}
