import { ConciseService } from '../generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract'
import { Exactly } from '../generic-common/client/typings/Exactly';
import { IAccountManagerCommonService } from './account-manager-common.service.interface'
import { TAccount_Auth_onAccessGranted } from './events/account-manager-common.events.io';
import { exists } from '../generic-common/tools/utils/concise.exists'
import { TReadAuthIdResponse } from './logins/server/TReadAuthId'
import { TReadLoginProfileResponse } from './logins/typings/TReadLoginProfileResponse.type';
import { IRegistrationManagerCommonService } from './registration/registration-manager-common.interface';
import { ILoginsCommonService } from './logins/client/logins-common.service.interface';
import { IAuthCommonService } from './auth/client/auth-common.service.interface';
import { IDateTimeCommonService } from '../datetime-common/client/datetime-common.service.interface';
import { IStateCommonService } from '@library/common/state-common/state-common.service.interface'
import { onStateRequest } from '../state-common/events/state-common.events.io';
import * as io from './account-manager-common.service.io'


export abstract class AccountManagerCommonService extends ConciseService implements Exactly<IAccountManagerCommonService, AccountManagerCommonService> {

  
  protected accountId!:string
  protected userId!:string
  

  constructor(
    protected override events:any,
    protected registration:IRegistrationManagerCommonService,
    protected logins:ILoginsCommonService,
    protected auth:IAuthCommonService,
    protected datetime:IDateTimeCommonService,
    protected state:IStateCommonService
  ) {
    super(events)
    this.events.State_onStateRequest.subscribe((state:onStateRequest) => this.state = state)
    this.hookConstructorPre()
    this.setupEventListeners()
  }














  protected hookConstructorPre() { } // To be optionally overwritten by child classes such as providing env settings.














  protected setupEventListeners() {
    this.registration.onFinished.subscribe(event => this.events.Account_onRegisterResponse.next(event))
    this.events.Account_onUserEmailCheckAvailabilityRequest.subscribe((request:io.TUserEmailCheckAvailabilityRequest) => this.userEmailCheckAvailability(request))
    this.events.Account_onRegisterRequest.subscribe((request:io.TAccountRegisterRequest) => this.accountRegister(request))
    this.events.Account_onUnregisterRequest.subscribe((request:io.TAccountUnregisterRequest) => this.accountUnregister(request))
    this.events.Account_onLoginRequest.subscribe((request:io.TUserLoginRequest) => this.userLogin(request))
    this.events.Account_onLogoutRequest.subscribe(()=> this.userLogout({ saasName: this.state.saasName, username: localStorage.getItem('userId') as string }))
    this.events.Account_onPasswordForgotRequest.subscribe((request:io.TPasswordForgotRequest) => this.userPasswordForgot(request))
    this.events.Account_onPasswordForgotResetRequest.subscribe((request:io.TPasswordForgotResetRequest) => this.userPasswordForgotReset(request))
    this.events.Account_onPasswordChangeRequest.subscribe((request:io.TPasswordChangeRequest) => this.userPasswordChange(request))
    this.events.Account_onUserCreateRequest.subscribe((request:io.TAuthProfileCreateRequest) => this.userCreate(request))
    this.events.Account_onUserDeleteRequest.subscribe((request:io.TAuthProfileRemoveRequest) => this.userDelete(request))
  }














  public userEmailCheckAvailability(params:io.TUserEmailCheckAvailabilityRequest):void {
    this.logins.readEmail(params)
      .then(result => this.onUserEmailCheckAvailabilitySuccess(result))
      .catch(error => this.onUserEmailCheckAvailabilityFailure(error))
  }


      private onUserEmailCheckAvailabilitySuccess(result:TReadLoginProfileResponse) {
        if (!result.output.success && !result.output.details) return this.onUserEmailCheckAvailabilityFailure(result)
        this.events.Account_onUserEmailCheckAvailabilityResponse.next((result.output.success && result.output.details.Count === 0) ? true : false)
      }

      
      private onUserEmailCheckAvailabilityFailure(error:any) {
        this.hasFailed({ at: this.onUserEmailCheckAvailabilityFailure, error })
      }














  public loginProfileReadByUserId(params:io.TLoginProfileReadByUserIdRequest):void {
    this.logins.readUserId(params)
      .then(result => this.events.Account_onLoginProfileReadByUserIdResponse.next(result))
      .catch(error => this.hasFailed({ at: this.loginProfileReadByUserId, error }))
  }














  public async accountRegister(params:io.TAccountRegisterRequest) {  
    let timezone = this.datetime.guessTimezone()
    let utcOffset = await this.datetime.getTimezoneOffset({ timezone })
    let request = Object.assign(params, { timezone, utcOffset: parseInt(utcOffset.output.details.timezoneOffset) })
    this.registration.begin(request)
  }














  public accountUnregister(params:io.TAccountUnregisterRequest):void {   
    this.auth.profileRemove(params)
      .then(result => this.onAccountUnregisterSuccess(result))
      .catch(error => this.onAccountUnregisterFailure(error))
  }


      protected onAccountUnregisterSuccess(result:io.TAccountUnregisterResponse) { 
        if (exists(result, 'output.success')) this.events.Account_onUnregisterResponse.next(result)
        else this.onAccountUnregisterFailure(result)
      }


      protected onAccountUnregisterFailure(error:any) { 
        this.events.Account_onUnregisterResponse.next(error)
        this.hasFailed({ at: this.onAccountUnregisterFailure, error })
      }














  public userLogin(params:io.TUserLoginRequest):void { 
    this.auth.signIn(params)
      .then(result => this.onUserLoginSuccess(result))
      .catch(error => this.onUserLoginFailure(error))
  }


      private onUserLoginSuccess(result:io.TUserLoginResponse) {
        if (!result.output.success) this.onUserLoginFailure(result)
        else this.onAccessGranted({ success: true, details: { authId: result.output.thirdParty.cognitoUser, accessToken: result.output.thirdParty.accessToken } })
      }

    
      private onUserLoginFailure(error:any) {
        this.events.Account_onLoginResponse.next({ success: false, details: error })
      }
    
    
    
    
    
          
    
    
    
    
    
    
    
    
  public userLogout(params:io.TUserLogoutRequest):void { 
    this.auth.signOut(params)
      .then(result => this.onUserLogoutSuccess(result))
      .catch(error => this.onUserLogoutFailure(error))
  }


      protected onUserLogoutSuccess(result:io.TUserLogoutResponse) {
        if (!exists(result, 'output.success')) this.onUserLogoutFailure(result)
        else this.events.Account_onLogoutResponse.next({ success: result.output.success, details: result.output })
      }


      protected onUserLogoutFailure(error:any) {
        this.events.Account_onLogoutResponse.next({ success: false, details: error })
        this.hasFailed({ at: this.onUserLogoutFailure, error })
      }














  public userPasswordForgot(params:io.TPasswordForgotRequest):void { 
    this.auth.passwordForgot(params)
      .then(result => this.onUserPasswordForgotSuccess(result))
      .catch(error => this.onUserPasswordForgotFailure(error))
  }


      protected onUserPasswordForgotSuccess(result:io.TPasswordForgotResponse) { 
        if (!result.output.success) this.onUserPasswordForgotFailure(result)
        else this.events.Account_onPasswordForgotResponse.next(result)
      }


      protected onUserPasswordForgotFailure(error:any) { 
        this.events.Account_onPasswordForgotResponse.next(error)
      }














  public userPasswordForgotReset(params:io.TPasswordForgotResetRequest):void {
    this.auth.passwordReset(params)
      .then(result => this.onUserPasswordForgotResetSuccess(result))
      .catch(error => this.onUserPasswordForgotResetFailure(error))
  }


      protected onUserPasswordForgotResetSuccess(result:io.TPasswordForgotResetResponse) { 
        if (!result.output.success) return this.onUserPasswordForgotResetFailure(result)
        this.events.Account_onPasswordForgotResetResponse.next(result)
      }


      protected onUserPasswordForgotResetFailure(error:any) { 
        this.events.Account_onPasswordForgotResetResponse.next(error)
      }











  public userPasswordChange(params:io.TPasswordChangeRequest):void { 
    this.auth.passwordChange(params)
      .then(result => this.onUserPasswordChangeSuccess(result))
      .catch(error => this.onUserPasswordChangeFailure(error))
  }


      protected onUserPasswordChangeSuccess(result:io.TPasswordChangeResponse) { 
        if (!result.output.success) return this.onUserPasswordChangeFailure(result)
        this.events.Account_onPasswordChangeResponse.next(result)
      }


      protected onUserPasswordChangeFailure(error:any) { 
        this.events.Account_onPasswordChangeResponse.next(error)
      }














  public userCreate(params:io.TAuthProfileCreateRequest):void {
    this.auth.profileCreate(params)
      .then(result => this.onUserCreateSuccess(result))
      .catch(error => this.onUserCreateFailure(error))
  }


      protected onUserCreateSuccess(result:io.TAuthProfileCreateResponse) { 
        if (!result.output.success) this.onUserCreateFailure(result)
        else this.events.Account_onUserCreateResponse.next(result)
      }


      protected onUserCreateFailure(error:any) { 
        this.events.Account_onUserCreateResponse.next(error)
        this.hasFailed({ at: this.onUserCreateFailure, error })
      }














  public userDelete(params:io.TAuthProfileRemoveRequest):void {
    this.auth.profileRemove(params)
      .then(result => this.onUserDeleteSuccess(result))
      .catch(error => this.onUserDeleteFailure(error))
  }


      protected onUserDeleteSuccess(result:io.TAuthProfileRemoveResponse) { 
        if (!result.output.success) this.onUserDeleteFailure(result)
        else this.events.Account_onUserDeleteResponse.next(result)
      }


      protected onUserDeleteFailure(error:any) { 
        this.events.Account_onUserDeleteResponse.next(error)
        this.hasFailed({ at: this.onUserDeleteFailure, error })
      }


      
  










  protected onAccessGranted(params:TAccount_Auth_onAccessGranted):void {
    this.logins.readAuthId({ saasName: this.state.saasName, authId: params.details.authId })
      .then(result => this.onAccessGrantedSuccess(result, params.details.authId))
      .catch(error => this.onAccessGrantedFailure(error))
  }
  

      protected onAccessGrantedSuccess(result:TReadAuthIdResponse, authId:string) {
        if (!result.output.success) return this.onAccessGrantedFailure(result)
        this.events.Account_onSessionData.next({ success: true, details: { authId: authId, accountId: result.output.details.Items[0].acctId, userId: result.output.details.Items[0].userId } })
        this.events.Account_onLoginResponse.next({ success: true })
      }


      protected onAccessGrantedFailure(error:any) {
        this.events.Account_onLoginResponse.next({ success: false, details: error })
        this.hasFailed({ at: this.onAccessGrantedFailure, error })
      }

}
