import { Input, Output, EventEmitter, OnChanges, SimpleChanges, Directive } from '@angular/core'
import { formFrontDoor } from './front-door.form'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { ConciseComponent } from '../../../generic-common/client/classes/Abstract/ConciseComponent/ConciseComponent.abstract'
import { IUxService } from '@services/ux/ux.service.interface'
import { exists } from '@library/common/generic-common/tools/utils/concise.exists'
import { TMode } from './front-door.io'
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists'


@Directive()
export abstract class AbstractFrontDoorFormWidget extends ConciseComponent implements OnChanges {

  @Input('reset') reset:boolean|null
  @Output() output = new EventEmitter()

  public get mode():TMode { return propertyIfExists(this, 'form.controls.mode.value') }

  public get newPasswordInvalid():boolean {
    if (!exists(this, 'form.controls.passwordNew.valid') || !exists(this, 'form.controls.passwordConfirm.valid')) return true
    if ((this.form.controls['passwordNew'].valid && this.form.controls['passwordConfirm'].valid)
      && (this.form.controls['passwordNew'].value === this.form.controls['passwordConfirm'].value)) return false
    else return true
    
  }

  public form:FormGroup = formFrontDoor
  

  constructor(
    protected override ux:IUxService
  ) { 
    super(ux)
  }














  public ngOnChanges(changes:SimpleChanges):void {
    if (!this.reset) return
    if (this.reset) this.onUser_cancel()
    this.reset = null
  }















  public onUser_KeyDown(event:KeyboardEvent) {
    if (event.key === `Enter`) this.onUser_submit()
  }














  public onUser_submit(value?:TMode) {
    if (value) this.form.controls['mode'].patchValue(value)
    this.mutateForm()
    this.output.emit(this.form.value)
    if (this.form.controls['mode'].value === 'changed') this.onUser_cancel()
  }














  private mutateForm() {
    switch (this.form.controls['mode'].value) {
      case 'reset': {
        this.fieldAdd({ fieldName: 'code', validators: [Validators.required] })
      }
      break
      case 'confirm': {
        this.fieldAdd({ fieldName: 'passwordNew', validators: [Validators.required] })
        this.fieldAdd({ fieldName: 'passwordConfirm', validators: [Validators.required, Validators.minLength(8)] })
      }
      break
      case 'changed': {
        this.fieldRemove('code')
      }
      break
    }
  }














  public onUser_cancel() {
    this.fieldRemove('passwordNew')
    this.fieldRemove('passwordConfirm')
    this.fieldRemove('code')
    this.form.reset()
    this.form.controls['mode'].patchValue('login')
  }














  private fieldAdd(params:{ fieldName:string, validators:ValidatorFn[] }) {
    this.form.addControl(params.fieldName, new FormControl(null, { validators: params.validators }))
  }














  private fieldRemove(fieldName:string) {
    this.form.removeControl(fieldName)
  }

}
