import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UxService } from '@services/ux/ux.service';
import { ComponentForm } from '../../../classes/Abstract/ComponentForm/ComponentForm.class';


@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss'],
})
export class TagFormComponent extends ComponentForm {


  constructor(
    public override ux:UxService
  ) {
    super(ux)
  }















  protected override createForm() {
    this.form = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] }),
      description: new FormControl(null),
    })
    return this
  }

}
