import { Component } from '@angular/core';
import { AbstractFrontDoorFormWidget } from '@library/common/account-common/forms/front-door/front-door.widget'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'form-front-door',
  templateUrl: '../../../../library/common/account-common/forms/front-door/front-door.form.html',
  styleUrls: ['front-door.widget.scss'],
})
export class FrontDoorFormWidget extends AbstractFrontDoorFormWidget {


  constructor(
    protected override ux:UxService
  ) {
    super(ux)
  }
  

}