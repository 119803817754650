import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
// import { ComponentBase } from '@assets/classes/Abstract/ClassBase/ComponentBase/ComponentBase.class'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent 
// extends ComponentBase implements OnInit 
{

  @Input() device:any
  @Output() onRemove:EventEmitter<any>
  @Output() onToggleNotifications:EventEmitter<any>

  public get header():string { return `Device: ${ this.device.id }` }
  public notifications!:boolean


  constructor(
    public ux:UxService
  ) {
    // super(DeviceComponent, ux)
    this.onRemove = new EventEmitter()
    this.onToggleNotifications = new EventEmitter()
  }














  ngOnInit():void {
    this.notifications = this.device.notifications
  }














  public onUser_ToggleNotifications() {
    try { this.toggleNotificationsProcedure() }
    catch(error) { 
      // this.hasFailed({ signature: 'error-143754f3-01cc-46f7-aaa0-f80fea209cba', error }) 
    }
  }


      private toggleNotificationsProcedure() {
        this.device.notifications = this.notifications
        this.onToggleNotifications.emit(this.device)
      }














  public onUser_Remove() {
    try { this.onRemove.emit(this.device) }
    catch (error) { 
      // this.hasFailed({ signature: 'error-7d0560b3-2438-43a2-a30b-cc771829c3a1', error: error }) 
    }
  }

}
