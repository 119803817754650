import { TSchedule } from '../../datetime-common/typings/TSchedule.type'
import { TJob } from './TJob.type'


export type TScheduledJobSendEmailParams = { saasName:string, stage:'DEV'|'PROD', id:string, dueAt:number, accountId:string, subject:string, body:string, bodyTextVersion:string, toAddresses:string[], fromEmail:string, timezone:string, schedule:TSchedule }

export function scheduledJobSendEmailFactory(params:TScheduledJobSendEmailParams):TJob {
  let lambdaName = `Email-${ params.stage }-send`
  let job:TJob = {
    id: `${ params.id }-${ params.dueAt }-${ lambdaName }`,
    createdAt: new Date().valueOf(),
    updatedAt: new Date().valueOf(),
    dueAt: params.dueAt,
    status: 'queued',
    saas: params.saasName,
    accountId: params.accountId,
    boundTo: params.id,
    details: {
      lambdaName: lambdaName,
      params: {
        toAddresses: params.toAddresses,
        from: params.fromEmail,
        subject: params.subject,
        body: params.body,
        bodyTextVersion: params.bodyTextVersion
      }
    },
    timezone: params.timezone
  }
  if (params.schedule) job.schedule = params.schedule
  return job
}