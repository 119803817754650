import { IStateService } from './state.service.interface'
import { StateCommonService } from '@library/common/state-common/state-common.service'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { UsersLiason } from '@services/app/api/models/users/UsersLiason.service'
import { EventsAppService } from '../events/events-app.service'
import { Injectable } from '@angular/core'


@Injectable({
  providedIn: 'root'
})
export class StateService extends StateCommonService implements Exactly<IStateService, StateService> {


  public emailNotifications?:boolean
  // public override email!:string


  constructor(
    protected override users:UsersLiason,
    protected override events:EventsAppService,
  ) {
    super(users, events)
    this.initialize()
  }














  public initialize() {
    this.events.DateTime_onTimezonesResponse.subscribe(result => {
      if (result.output.success) this.timezones = result.output.details
    })
  }

}