import { Injectable } from '@angular/core';
// import { AccountManagerService } from '@services/integrated/app/account/account-manager.service';
import { ApiService } from '@services/app/api/api.service';
import { EventsAppService } from '@services/app/events/events-app.service';
import { LogsService } from '@services/app/logs/logs.service'
// import { MobileService } from '@services/integrated/app/mobile/mobile.service';
import { StateService } from '@services/app/state/state.service'
import { SettingsAppService } from '@services/app/settings/settings-app.service'
import { IAppService } from '@services/app/app.service.interface'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly';
// import { SupportService } from '@services/integrated/app/api/support/support.service';


@Injectable({
  providedIn: 'root'
})
export class AppService implements Exactly<IAppService, AppService> {

  
  constructor(
    // public readonly account:AccountManagerService,
    public readonly api:ApiService,
    public readonly events:EventsAppService,
    public readonly logs:LogsService,
    public readonly state:StateService,
    public readonly settings:SettingsAppService,
    // public readonly support:SupportService
  ) { }

}
