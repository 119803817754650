import { exists } from '../../tools/utils/concise.exists';
import { TDynamoRecord } from './TDynamoRecord.type'
import { id } from "../../tools/utils/concise.short.id"


export abstract class ModelDynamoDb implements TDynamoRecord {

  protected static hookCreatePost(record:any):any { return record }
  protected static hookPresentationPre(response:{ Items:any[] }):any { return response } 
  protected static hookPresentationPost(response:any):any { } 
  protected static hookPersistencePre(item:any):any { return item }
  protected static hookCleanupLegacyDataPost(item:any):any { return item }
  protected static hookCreateIndexesPost(item:any):any { return item }
  protected static hookPersistencePost(item:any):any { return item }
  protected static cleanupLegacyData(item:any):any { return item }

  public get ui_createdAt():string { return new Date((this as any).createdAt).toLocaleString() }
  public get ui_updatedAt():string { return new Date((this as any).updatedAt).toLocaleString() }
  public get ui_nameAbbreviated():string { 
    if (!this.name) return ''
    else if (this.name.length <= 17) return this.name
    else return `${ this.name.slice(0, 17) }...`
  }
  public readonly table:string
  public id:string
  public readonly createdAt:number
  public readonly updatedAt:number
  public readonly record:boolean
  public readonly links:{
    [foreignTable:string]: {
      [foreignId:string]:'parent'|null
    }
  } = { }
  public name:string
  public preset?:boolean

  public link(model:string, id:string):void {
    if (!this.links) (this as any).links = { }
    this.links![model] = this.links![model] || { }
    this.links![model][id] = null
  }

  public unlink(model:string, id:string):void {
    if (!this.links) return
    if (!this.links[model]) return
    delete this.links[model][id]
  }

  public linksClear(model:string) {
    if (this.links) this.links[model] = { }
  }



  constructor(record:any) { 
    if (record) for (let [ property, value ] of Object.entries(record)) this[property as keyof this] = value as any
    if (this.links) Object.keys(this.links).forEach(foreignTable => Object.defineProperties(this, this.createGetterAndSetterForUI(foreignTable)))
  }














  private createGetterAndSetterForUI(foreignTable:string) {
    return { 
      [`ui_${ foreignTable }`]: {
        get: ()=> { 
          if (exists(this, `links.${ foreignTable }`)) return Object.keys((this as any).links[foreignTable])
          else return [ ]
        },
        set: (ids:string[])=> { 
          (this as any).links[foreignTable] = { }
          ids.forEach((id:string) => (this as any).links[foreignTable][id] = null)
        }
      } 
    }
  }













  public static create(this:any, accountId:string):any {  
    let record:any = { }
    record.table = `${ accountId }.${ this.settings.namePlural }`
    record.id = `${ this.settings.prefix }-${ id(this.settings.idLength) }`
    record.record = true
    record.links = this.setEmptyLinksStructure()
    record.createdAt = record.updatedAt = new Date().valueOf()
    record = this.hookCreatePost(record)
    return record
  }














  public static presentation(this:any, response:{ Items:any[] }):any {
    if (response.Items.length === 0) return
    response = this.hookPresentationPre(response)
    let record = response.Items.shift()
    record.links = this.createLinks(response)
    record = this.cleanupLegacyData(record)
    this.hookPresentationPost(response)
    return record
  }














  public static persistence(this:any, item:any):any {
    item = this.hookPersistencePre(item)
    item = this.cleanupLegacyData(item)
    item = this.hookCleanupLegacyDataPost(item)
    item = this.createIndexes(item)
    item = this.hookCreateIndexesPost(item)
    if (item.updatedAt) item.updatedAt = new Date().valueOf()
    item = this.hookPersistencePost(item)
    delete item.links
    item.record = `true`
    if (item.queue) {
      if (item.queue.constructor === String) {
        item.queue = (item.queue === 'true') ? 'true' : 'false' ;
      }
      else {
        item.queue = (item.queue === true) ? 'true' : 'false' ;
    }
    }
    return item
  }














  protected static createLinks(this:any, response:{ Items:any[] }) {
    let startingLinksValue = this.setEmptyLinksStructure()
    return response.Items
        .filter((item:any) => item.id.includes('link'))
        .reduce((aggregate:any, currentValue:any)=> this.mergeLinks(aggregate, currentValue), startingLinksValue)
  }














  protected static mergeLinks(this:any, aggregate:any, currentValue:{ table:string, id:string, value?:any }) {
    let [ foreignTable, foreignId ] = currentValue.id.split('|')[1].split('.')
    if (!aggregate[foreignTable]) aggregate[foreignTable] = { }
    Object.assign(aggregate[foreignTable], { [foreignId]: currentValue.value || null })
    return aggregate
  }














  protected static createIndexes(this:any, item:any):any { 
    Object.values(this.settings.indexes).forEach((index:any) => {
      if (item[index.name] === null) delete item[index.name]
      if (item[index.property] !== undefined) item[index.name] = `${ index.property }:${ item[index.property] }`
      else if (index.name !== 'createdAt' && index.name !== 'updatedAt') delete item[index.name]
    })
    return item
  }














  protected static setEmptyLinksStructure(this:any) {
    let startingLinksValue:any = { } 
    if (exists(this, 'settings.canLinkTo')) this.settings.canLinkTo!.forEach((foreignTable:string) => startingLinksValue[foreignTable] = { })
    return startingLinksValue
  }

}
