import { IRegistrationAssistantNewAuthService } from './registration-assistant-new-auth.interface'
import { RegistrationAssistantCommonNewAuthService } from '@library/common/account-common/registration/new-auth/registration-assistant-common-new-auth.service'
import { AuthService } from '../../auth/auth.service'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { Injectable } from '@angular/core'


@Injectable({
  providedIn: 'root'
})
export class RegistrationAssistantNewAuthService extends RegistrationAssistantCommonNewAuthService implements Exactly<IRegistrationAssistantNewAuthService, RegistrationAssistantNewAuthService> { 

  
  constructor(
    protected override auth:AuthService
  ) {
    super(auth)
  }

}