import { TModelSettings } from '@library/common/generic-common/typings/ModelDynamoDbV2/TModelSettings.type'
import { ModelDynamoDb } from '../../../../../../library/common/generic-common/typings/ModelDynamoDbV2/ModelDynamoDb.abstract'


class Tag extends ModelDynamoDb {

  ui_questions


  constructor(record?) {
    super(record)
  }

}

namespace Tag {

  export const settings:TModelSettings = {
    nameSingular: 'tag',
    namePlural: 'tags',
    prefix: 'tag',
    idLength: 5,
    canLinkTo: [
      'questions',
    ],
    indexes: {
      indexA: { name: 'indexA', property: null },
      indexB: { name: 'indexB', property: null },
      indexC: { name: 'indexC', property: null },
    },
    summary: ['name', 'description'],
  }

}

export default Tag