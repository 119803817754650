import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PublicService } from '@public/service/public.service'
import { HttpClientModule } from '@angular/common/http'
import { WidgetsModule } from '@library/widgets/widgets.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoalescingComponentFactoryResolver } from '@library/common/generic-common/client/classes/CoalescingComponentFactoryResolver/CoalescingComponentFactoryResolver';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({ animated: false }), 
    AppRoutingModule,
    HttpClientModule,
    WidgetsModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PublicService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { 

  constructor(
    coalescingResolver:CoalescingComponentFactoryResolver,
  ) {
    coalescingResolver.init()
  }

}
