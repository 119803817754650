import { ConciseService } from '../ConciseService/ConciseService.abstract'
import { IApiService } from './ApiService.abstract.interface'
import { Exactly } from '../../../typings/Exactly';
import { environment } from '@env/environment'
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';


export abstract class ApiService extends ConciseService implements Exactly<IApiService, ApiService> {

  protected get baseUrl():string {
    return `${ this.protocol }://${ this.apiId }.execute-api.${ this.regionName }.amazonaws.com/${ this.stageName }/`
  }

  protected abstract apiId:string

  private protocol = environment.api.protocol || 'https'
  private regionName = environment.api.region || 'us-east-2'
  private stageName = environment.stage || 'DEV'



  constructor(
    protected override events:IEventsAppCommonService,
    protected override name?:string
  ) {
    super({ } as any)
    this.hookConstructorPre()
    this.hookConstructorPost()
  }




      protected hookConstructorPre() { }



      protected hookConstructorPost() { }

}