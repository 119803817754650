import { Subject } from 'rxjs'
import { TAuthProfileCreateRequest, TAuthProfileCreateResponse } from '../../../account-common/auth/typings/io.TProfileCreate.type'
import { TAuthProfileCreateDetails } from '../../../account-common/auth/typings/TAuthProfileCreateDetails.type'
import { TGenericFailure } from '../../../generic-common/client/typings/TGenericFailure.type'
import { IRegistrationAssistantCommonNewAuthService } from './registration-assistant-common-new-auth.interface'
import { Exactly } from '../../../generic-common/client/typings/Exactly';
import { IAuthCommonService } from '../../../account-common/auth/client/auth-common.service.interface'
export type TRegistrationAssistantNewAuth_onFinished = { success:true, details:TAuthProfileCreateDetails }|{ success:false, details:string }


export abstract class RegistrationAssistantCommonNewAuthService implements Exactly<IRegistrationAssistantCommonNewAuthService, RegistrationAssistantCommonNewAuthService> {


  public onFinished:Subject<TRegistrationAssistantNewAuth_onFinished> = new Subject()

  
  constructor(
    protected auth:IAuthCommonService,
  ) { }














  public createProfile(params:TAuthProfileCreateRequest):Promise<TAuthProfileCreateResponse> {
    return this.auth.profileCreate(params)
      .then(result => this.onCreateNewProfileSuccess(result))
      .catch(error => this.onCreateNewProfileFailure(error))
  }


      private onCreateNewProfileSuccess(result:TAuthProfileCreateResponse) {
        if (!result.output.success) return this.onCreateNewProfileFailure(result)
        this.onFinished.next({ success: true, details: result.output.thirdParty })
        return result
      }


      private onCreateNewProfileFailure(error:TGenericFailure|TAuthProfileCreateResponse) {
        let message
        if ("statusText" in error) message = error.statusText
        else if ("output" in error && "thirdParty" in error.output) message = error.output.thirdParty.message || error.output.thirdParty.name
        this.onFinished.next({ success: false, details: message })
        return {
          output: {
            success: false,
            details: error
          }
        } as TAuthProfileCreateResponse
      }

}