import { Directive } from '@angular/core'
import { ComponentForm } from '../../../classes/Abstract/ComponentForm/ComponentForm.class'
import { UxService } from '@services/ux/ux.service'
import { EventEmitter, Input, Output } from '@angular/core'
import Question from '@private/models/questions/types/question.model'
import { FormGroup } from '@angular/forms'
import copyOf from '@library/common/generic-common/tools/utils/concise.copyOf'
import { exists } from '@library/common/generic-common/tools/utils/concise.exists'
import { capitalizeLeadingCharacter } from '@library/common/generic-common/tools/formatters/capitalizeLeadingCharacter'
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists'


@Directive()
export abstract class BaseFormQuestionaire extends ComponentForm {
  
  @Input() override input:undefined|{ record:Question, supplementary? }
  @Input() mode!:'exam'|'grade'
  @Output() override output!:EventEmitter<{ userAnswer:any }|{ correct:boolean }>
  
  public get uiQuestion():string {
    return propertyIfExists(this, 'isolatedCopy.question')
  }
    
  public get uiAnswer() {
    return capitalizeLeadingCharacter(propertyIfExists(this, 'isolatedCopy.userAnswer'))
  }
    
  public get uiReason():string {
    return propertyIfExists(this, 'isolatedCopy.reason')
  }

  public get uiCorrectAnswer() {
    if (!exists(this, 'isolatedCopy.options')) return
    let correctRecord = (this.isolatedCopy as any).options?.filter(question => question.correct)[0] as any
    return correctRecord.answer
  }
      
  public get uiUserAnswer() {
    return capitalizeLeadingCharacter(propertyIfExists(this, 'isolatedCopy.userAnswer'))
  }

  public override form!:FormGroup
  public correctOptions!: { name: string; value: boolean }[]
  public correct!:boolean|string
  protected override isolatedCopy!:Question
  protected isLoading!:boolean


  constructor(
    protected Class:any,
    protected override ux:UxService,
  ) {
    super(ux)
    this.output = new EventEmitter()
  }














  override ngOnInit() {
    if (!this.isLoading) this.init()
  }














  ionViewWillEnter() {
    if (!this.isLoading) this.init()
  }














  protected init() {
    this.isLoading = true
    this.isolatedCopy = copyOf(this.input?.record)
    this.correctOptions = [
      { name: 'Correct', value: true },
      { name: 'Incorrect', value: false },
    ]
    this.createForm()
  }














  ngOnChanges() {
    if (!this.isolatedCopy) return
    if (this.input?.record.id != this.isolatedCopy.id) this.ngOnInit()
  }














  public onUser_Submit():{ userAnswer:any }|{ correct:any } {
    let output
    if (this.mode === 'exam') output = { userAnswer: this.form.controls['userAnswer'].value }
    else output = ({ correct: exists(this, 'form.controls.correct.value.value') ? this.form.controls['correct'].value.value : this.form.controls['correct'].value })
    this.output.emit(output)
    return output
  }

}