import { ISettingsAppService } from './settings-app.service.interface'
import { SettingsAppCommonService } from '@library/common/settings-common/app/settings-app-common.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { TEnvironmentSettings } from '@library/common/generic-common/client/typings/TEnvironmentSettings.type'
import { environment } from '@env/environment'


@Injectable({
  providedIn: 'root'
})
export class SettingsAppService extends SettingsAppCommonService implements Exactly<ISettingsAppService, SettingsAppService> {

  public override env:TEnvironmentSettings = environment
  public defaultStreak:number = 3

  constructor() {
    super()
  }

}