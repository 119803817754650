import { IRegistrationAssistantNewLoginService } from './registration-assistant-new-login.service.interface'
import { RegistrationAssistantCommonNewLoginService } from '@library/common/account-common/registration/new-login/registration-assistant-common-new-login.service'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { EventsAppService } from '@services/app/events/events-app.service'
import { LoginsService } from '../../logins/logins.service'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { StateService } from '@services/app/state/state.service'


@Injectable({
  providedIn: 'root'
})
export class RegistrationAssistantNewLoginService extends RegistrationAssistantCommonNewLoginService implements Exactly<IRegistrationAssistantNewLoginService, RegistrationAssistantNewLoginService> {


  protected override apiId:string = environment.api.ids.database


  constructor(
    protected override events:EventsAppService,
    protected override logins:LoginsService,
    protected override state:StateService
  ) {
    super(events, logins, state)
  }













  
  // protected assignEnvironment() {
  //   this.saas = environment
  // }

}
