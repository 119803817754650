import { Directive, Injectable } from '@angular/core'
import { TEnvironmentSettings } from '../../generic-common/client/typings/TEnvironmentSettings.type'
import { ISettingsAppCommonService } from './settings-app-common.service.interface'


@Directive()
export abstract class SettingsAppCommonService implements ISettingsAppCommonService { 
  public abstract env:TEnvironmentSettings
  public accountIdPrefix:string = `acct`
  public accountIdLength:number = 5 
  public userIdPrefix:string = `user`
  public userIdLength:number = 4
  public emailNotificationsFrom = `concise.constructs@gmail.com`
}