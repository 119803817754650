import { IStateCommonService } from './state-common.service.interface'
import { ConciseService } from '../generic-common/client/classes/Abstract/ConciseService/ConciseService.abstract';
import { Exactly } from '../generic-common/client/typings/Exactly';
import { TReadResponse } from '../generic-common/server/typings/TReadRequest.type'
import { TGenericFailure } from '../generic-common/client/typings/TGenericFailure.type';
import { environment as saas } from '@env/environment'
import { TAccount_Logins_onSessionData } from '../account-common/events/account-manager-common.events.io'
import { IEventsAppService } from '@services/app/events/events-app.service.interface';
import { TUser } from '../generic-common/typings/user/typings/TUser.type';
import { ModelLiason } from '../generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract';


export abstract class StateCommonService extends ConciseService implements Exactly<IStateCommonService, StateCommonService> {


  public set hasLoggedIn(value) {  }
  public get hasLoggedIn():boolean { return localStorage.getItem('userId')? true : false }
  public set accountId(accountId:string) { localStorage.setItem('accountId', accountId) }
  public get accountId():string { return localStorage.getItem('accountId') as string }
  public set authId(authId:string) { localStorage.setItem('authId', authId) }
  public get authId():string { return localStorage.getItem('authId') as string }

  public set userId(userId:string) { localStorage.setItem('userId', userId) }
  public get userId():string { return localStorage.getItem('userId') as string }

  public set paymentId(paymentId:number) { if (paymentId) localStorage.setItem('paymentId', JSON.stringify(paymentId)) }
  public get paymentId():number { return JSON.parse(localStorage.getItem('paymentId') as string) }

  public set logoutAt(value:number) { localStorage.setItem('logoutAt', JSON.stringify(value)) }
  public get logoutAt():number { return JSON.parse(localStorage.getItem('logoutAt') as string) }

  public set saasName(value) {  }
  public get saasName():string { return saas.saasName }

  public set stage(value) {  }
  public get stage():'DEV'|'PROD' { return saas.stage }

  public get supportContactId():string|undefined { return saas.supportContactId }

  public set recaptchaKey(value) {  }
  public get recaptchaKey():string|null { return saas.google!.recaptcha.siteKey }

  public set version(value) {  }
  public get version():string { return saas.version }

  public set beta(value) {  }
  public get beta():boolean { return saas.beta }

  public set production(value) {  }
  public get production():boolean { return saas.production }

  public set downForMaintenance(value) { }
  public get downForMaintenance():boolean { return saas.downForMaintenance }

  public get timezone():string|undefined { return localStorage.getItem('timezone') || undefined }
  public set timezone(value:string|undefined) { localStorage.setItem('timezone', value as string) }

  public get timezones():string[] { return JSON.parse(localStorage.getItem('timezones') as string) || [ ] }
  public set timezones(values:string[]) { localStorage.setItem('timezones', JSON.stringify(values)) }

  public get gmtOffset():number { return parseInt(localStorage.getItem('gmtOffset') as string) }
  public set gmtOffset(value:number) { localStorage.setItem('gmtOffset', JSON.stringify(value)) }

  public firstName!:string
  public lastName!:string
  public email!:string
  public newAccountId!:string


  constructor(
    protected users:ModelLiason,
    protected override events:IEventsAppService,
  ) {
    super(events)
    if (this.accountId) this.getUserProperName()
    this.events.State_onStateRequest?.next(this)
    this.events.Account_onSessionData?.subscribe(params => this.onLogin(params))
    this.events.Account_onLogoutResponse?.subscribe(() => this.onLogout())
  }














  public abstract initialize():void 














  private getUserProperName() {
    if (saas.api.ids['users']) this.users.get.isExactly({ value: this.userId })
      .then(result => this.onUserGetSuccess(result as TUser[]))
      .catch(error => this.onUserGetFailure(error))
  }


      private onUserGetSuccess(result:TUser[]) {
        if (result.length > 0) {
          this.firstName = result[0].firstName
          this.lastName = result[0].lastName
          this.email = result[0].email
        }
      }


      private onUserGetFailure(error:TReadResponse|TGenericFailure) {
        this.hasFailed({ at: this.onUserGetFailure, error })
      }














  private onLogin(params:TAccount_Logins_onSessionData) {
    if (params.success) this.startLoginSession(params)
  }














  private onLogout() {
    this.clearLoginSession()
  }

  











  
  private startLoginSession(params:TAccount_Logins_onSessionData) {
    for (let [ property, value ] of Object.entries(params.details)) (this as any)[property] = value
    this.initialize()
  }














  public clearLoginSession() {
    let exclusions = ['timezones', 'log', 'queue', 'completedJobs']
    Object.keys(localStorage)
      .filter(property => !exclusions.includes(property))
      .forEach(property => {
        try { localStorage.removeItem(property) }
        catch(error) { /* Do nothing */  }
      })
  }














  public store(params:{ key:string, value:any }):void {
    localStorage.setItem(params.key, JSON.stringify(params.value))
  }













  
  public get(params:{ key:string }):any {
    return JSON.parse(localStorage.getItem(params.key) as any)
  }

}
