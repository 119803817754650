import { ISettingsUxService } from './settings-ux.service.interface'
import { SettingsUxCommonService } from '@library/common/settings-common/ux/settings-ux-common.service'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'


@Injectable({
  providedIn: 'root'
})
export class SettingsUxService extends SettingsUxCommonService implements Exactly<ISettingsUxService, SettingsUxService> {

  constructor() {
    super()
  }













  
  public descriptions = {
    reasonFieldDescription: `Reason Why (optional, helps reinforce the idea).`,
    streak: `This is how many consecutive correct answers before that question is moved to a different bucket (which is quizzed less often).`,
    emailNotifications: `Toggle "Quiz Due" email notifications.`
  }
  
}
