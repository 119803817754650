// import { TModelSettings } from '../../../../typings/TModelSettings.type'

import { isEmail } from '../../../../generic-common/tools/validators/isEmail'
import { id } from '../../../../generic-common/tools/utils/concise.short.id'



export const TUserSettings:any = {
  nameSingular: 'user',
  namePlural: 'users',
  prefix: 'user',
  idLength: 3,
  indexes: {
    indexA: { name: 'indexA', property: 'email' },
    indexB: { name: 'indexB', property: null },
    indexC: { name: 'indexC', property: null },
    indexD: { name: 'createdAt', property: null },
    indexE: { name: 'updatedAt', property: null },
  },
  grid: {
    multiRowSelect: true,
    actions: {
      headerName: 'Actions',
      cellRenderer: 'CustomGridActions',
      cellRendererParams: {
        actions: [
          { name: 'onUser_editRow', label: 'Edit', icon: 'fa fa-pencil', action: null  },
          { name: 'onUser_deleteRow', label: 'Delete', icon: 'fa fa-trash', action: null }
        ]
      }
    },
    actionColumnSettings: { edit: 0, remove: 1 }
  },
  canLinkTo: [
    'location'
  ],
  form: {
    relationships: {
      locations: {
        name: 'locations',
        label: 'Locations',
        type: Array,
        field: 'multi-select',
        displayGrid: false,
        displayForm: true,
        icon: 'fa fa-building',
        value: [],
        options: true,
        editable: true,
      }
    }
  },
  schema: {

    table: {
      name: 'table',
      label: 'Table',
      type: String,
      field: 'text',
      displayGrid: false,
      displayForm: false,
      icon: null,
      value: null,
      editable: false,
      placeholder: null,
    },


    id: {
      name: 'id',
      label: 'id',
      type: String,
      field: 'text',
      displayGrid: false,
      displayForm: false,
      icon: null,
      value: `user-${ id(3) }`,
      editable: false,
      placeholder: null,
    },


    email: {
      name: 'email',
      label: 'Email',
      type: String,
      field: 'text',
      displayGrid: true,
      displayForm: true,
      icon: null,
      value: null,
      editable: false,
      placeholder: "Email address",
      validation: {
        required: {
          need: true,
          isValid: false
        }
      },
      customValidation: [
        { need: isEmail, isValid: false}
      ]
    },


    firstName: {
      name: 'firstName',
      label: 'First Name',
      type: String,
      field: 'text',
      displayGrid: true,
      displayForm: true,
      icon: null,
      value: null,
      editable: true,
      placeholder: "",
      validation: {
        required: {
          need: true,
          isValid: false
        }
      }
    },


    lastName: {
      name: 'lastName',
      label: 'Last Name',
      type: String,
      field: 'text',
      displayGrid: true,
      displayForm: true,
      icon: null,
      value: null,
      editable: true,
      placeholder: "",
      validation: {
        required: {
          need: true,
          isValid: false
        }
      }
    },


    // password: {
    //   name: 'password',
    //   label: 'Password',
    //   type: String,
    //   field: 'password',
    //   displayGrid: false,
    //   displayForm: true,
    //   icon: 'fa fa-key',
    //   value: null,
    //   editable: true,
    //   placeholder: "",
    //   validation: {
    //     required: {
    //       need: true,
    //       isValid: false
    //     }
    //   }
    // },


    createdAt: {
      name: 'createdAt',
      label: 'Created At',
      type: Number,
      field: 'text',
      displayGrid: true,
      displayForm: false,
      icon: null,
      value: new Date().valueOf(),
      editable: false,
      placeholder: null,
    },


    updatedAt: {
      name: 'updatedAt',
      label: 'Updated At',
      type: Number,
      field: 'text',
      displayGrid: true,
      displayForm: false,
      icon: null,
      value: new Date().valueOf(),
      editable: false,
      placeholder: null,
    }

  }
  
}


// export interface Get {
//   all():Promise<any>
//   id(id:string):Promise<any>
//   where:Indexes
// }

//         export interface Indexes {
//           email:GetQueriesInterface
//           indexB:GetQueriesInterface
//           indexC:GetQueriesInterface
//           indexD:GetQueriesInterface
//           indexE:GetQueriesInterface
//         }

// export const forView = {
//   locationForm: {
//     name: 'locationForm',
//     ProjectionExpression: '#id, #firstName, #lastName, #email',
//     ExpressionAttributeNames: {
//       "#id": 'id',
//       "#firstName": 'firstName',
//       "#lastName": 'lastName',
//       "#email": 'email',
//     }
//   },
// }
// export interface forView {
//   locationForm():{ get:Get }
// }
