import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseFormQuestionaire } from '../BaseFormQuestionaire.class'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-questionaire-boolean',
  templateUrl: './questionaire-boolean.component.html',
  styleUrls: ['./questionaire-boolean.component.scss'],
})
export class QuestionaireBooleanComponent extends BaseFormQuestionaire implements OnInit, OnChanges {

  public override correct!:boolean


  constructor(
    public override ux:UxService
  ) {
    super(QuestionaireBooleanComponent, ux)
  }















  protected override createForm():this {
    this.form = new FormGroup({
      answer: new FormControl(`${ (this.isolatedCopy as any).answer }`),
      userAnswer: new FormControl(`${ (this.isolatedCopy as any).userAnswer }`, { validators: [Validators.required] }),
      correct: new FormControl((this.isolatedCopy as any).correct, { validators: [Validators.required] })
    })
    return this
  }

}
