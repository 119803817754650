import { Injectable } from '@angular/core';
import { ScreenService } from '@services/ux/screen/screen.service';
import { InterpreterService } from '@services/ux/interpreter/interpreter.service'
import { LayersService } from '@services/ux/layers/layers.service'
import { NavigationService } from '@services/ux/navigation/navigation.service'
import { FeedbackService } from './feedback/feedback.service'
import { EventsUxService } from '@services/ux/events/events-ux.service'
import { IUxService } from './ux.service.interface'
import { SettingsUxService } from '@services/ux/settings/settings-ux.service';
import { CoalescingComponentFactoryResolver } from '@library/common/generic-common/client/classes/CoalescingComponentFactoryResolver/CoalescingComponentFactoryResolver'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly';
import { MobileService } from './mobile/mobile.service'


@Injectable({
  providedIn: 'root'
})
export class UxService implements Exactly<IUxService, UxService>  {

  constructor(
    public readonly events:EventsUxService,
    public readonly feedback:FeedbackService,
    public readonly interpreter:InterpreterService,
    public readonly layers:LayersService,
    public readonly mobile:MobileService,
    public readonly navigation:NavigationService,
    public readonly screen:ScreenService,
    public readonly settings:SettingsUxService,
    public readonly resolver:CoalescingComponentFactoryResolver
  ) { }

}
