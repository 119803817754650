import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { EventsAppService } from '@services/app/events/events-app.service'
import { ModelLiason } from '@library/common/generic-common/client/classes/Abstract/ModelLiason/ModelLiason.abstract'
import Question from '../../types/question.model'
// import { IModelLiasonClass } from '@assets/classes/Abstract/ClassBase/_ServiceBase/_ModelLiason/ModelLiason.class.interface'
// import { settings } from './question.settings'
// import { TQuestionFactory } from '@library/api/models/questions/types/question.factory'
// import { settings } from '@library/api/models/questions/types/question.settings'
import { environment } from '@env/environment';
// import { uiQuestion } from '@library/api/models/questions/types/question.ui'


@Injectable({
  providedIn: 'root'
})
export class QuestionsLiason 
extends ModelLiason 
// implements IModelLiasonClass 
{

  // protected override factory
  protected override model:typeof Question = Question
  protected override apiId:string = environment.api.ids.questions

  constructor(
    protected override http:HttpClient,
    public override events:EventsAppService
  ) {
    super(http, events)
  }

  protected assignEnvironment() {
    // this.saas = environment
  }

  protected assignEndpoints() {
  //  this.apiId = this.saas.api.ids.questions 
  }

  protected assignFactory() {
    // this.factory = TQuestionFactory
    // this.factory.settings = settings
  }

  protected assignUiModelWrapper() {
    // this.uiModelWrapper = uiQuestion
  }

}
