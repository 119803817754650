<form [formGroup]="form">
  <section id="name">
    <h4>Quiz Name:</h4>
    <div class="formContent">
      <input type="text" formControlName="name" pInputText />
    </div>
  </section>
  <section id="tags">
    <h4>Include All Questions From Categories:</h4>
    <div class="formContent">
      <p-multiSelect formControlName="tags" [options]="tagOptions" defaultLabel="Select One" optionLabel="name" (click)="onSelectTag($event)"></p-multiSelect>
    </div>
  </section>
  <section id="questions">
    <h4>Add Questions Individually</h4>
    <div class="formContent">
      <button type="button" label="Browse Questions" (click)="onBrowseQuestions()" pButton></button>
      <h6>{{ numberOfIncludedQuestions }} Questions Included Directly</h6>
    </div>
  </section>
  <section id="amount">
    <h4>Amount of Questions When Quized</h4>
    <div class="formContent">
      <p-spinner formControlName="questionAmount"></p-spinner>
    </div>
  </section>
  <section id="streak">
    <h4>Streak</h4>
    <div class="formContent">
      <p>{{ descriptionStreak }}</p>
      <p-spinner formControlName="streak"></p-spinner>
    </div>
  </section>
  <section id="schedule">
    <h4>Quiz Schedule (Optional)</h4>
    <div id="scheduleToggleContainer" class="formContent">
      <p-dropdown formControlName="scheduled" [options]="scheduleOptions"  placeholder="Select One" optionLabel="name" (onChange)="onUser_ToggleSchedule()" ></p-dropdown>
    </div>
    <div *ngIf="displaySchedule" id="scheduledContainer">
      <app-scheduler [input]="this.isolatedCopy.schedule" (output)="onScheduleSettings($event)"></app-scheduler>
    </div>
  </section>
  <section id="feedback">
    <hr class="formContent">
    <h3>Next Quiz: {{ uiFirstQuiz }}</h3>
    <div *ngIf="feedbackMessage" id="feedbackMessage">{{ feedbackMessage }}</div>
  </section>
  <section id="actions">
    <div id="buttonContainer">
      <button [label]="submitButtonLabel" class="buttonTertiary" [disabled]="!canSubmit" (click)="onUser_submit()" pButton></button>
      <button label="Cancel" class="buttonPrimary" (click)="onUser_cancel()" pButton></button>
    </div>
  </section>
</form>
