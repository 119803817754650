import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../../generic-common/client/classes/Abstract/ApiServiceClass/ApiService.abstract';
import { HttpOptions } from '../../../../../generic-common/client/classes/HttpOptions/HttpOptions.class';
import { IGetQueries } from '../../../../../generic-common/client/classes/Abstract/ModelLiason/IGetQueries.interface';
import { TReadRequest, TReadResponse } from '../../../../../generic-common/server/typings/TReadRequest.type';
import { TUpdateRequest, TUpdateResponse } from '../../../../../generic-common/server/typings/TUpdateRequest.type';
import { TDeleteRequest, TDeleteResponse } from '../../../../../generic-common/server/typings/TDeleteRequest.type';
import { TDynamoRecord } from '../../../../../generic-common/typings/ModelDynamoDbV2/TDynamoRecord.type'
import { environment } from '@env/environment'
import { id } from '@library/common/generic-common/tools/utils/concise.short.id';
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';


export abstract class ModelLiason extends ApiService {


  public get:IGetQueries = this.assignQueries()
  protected abstract model:any


  constructor(
    protected http:HttpClient,
    protected override events:IEventsAppCommonService
  ) {
    super(events)
  }














  private assignQueries() {
    return {
      list: this.list.bind(this),
      all: this.all.bind(this),
      id: this.id.bind(this),
      isExactly: this.isExactly.bind(this),
      isLessThan: this.isLessThan.bind(this),
      isLessOrEqual: this.isLessOrEqual.bind(this),
      isGreaterThan: this.isGreaterThan.bind(this),
      isGreaterOrEqual: this.isGreaterOrEqual.bind(this),
      isBetween: this.isBetween.bind(this),
      beginsWith: this.beginsWith.bind(this)
    } as IGetQueries
  }













  
  protected list(params?:TReadRequest):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'all' }
    request.indexName = 'record'
    if (params?.lastEvaluatedKey) request.lastEvaluatedKey = JSON.stringify(params.lastEvaluatedKey)
   
    let syntaxPlaceholders = this.model.settings.summary
      .map((propertyName:string) => { return { [`#${ id() }`]: propertyName } })
      .reduce((aggregate:any, value:string) => Object.assign(aggregate, value))

    request.view = <any>JSON.stringify({
      ProjectionExpression: Object.keys(syntaxPlaceholders).toString(),
      ExpressionAttributeNames: syntaxPlaceholders
    })
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }














  public create(properties?:TDynamoRecord) { 
    let record = (this.model as any).create(localStorage.getItem('accountId') as string)
    if (properties) for (let [ property, value ] of Object.entries(properties)) record[property] = value
    return new (this as any).model(record)
  }

  
  
  
  
  
  
  
  
  
  
  
  
  
  protected all(params?:{ indexName?:string, lastEvaluatedKey?:any }):Promise<TDynamoRecord[]> {
    params = params || { }
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'all' }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = JSON.stringify(params.lastEvaluatedKey)
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  protected id(params:{ value:string }):Promise<TDynamoRecord> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'id', value: params.value }
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord>
  }














  protected isExactly(params:{ value:string|number, indexName?:string, lastEvaluatedKey?:any, view?:any }):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'isExactly', value: params.value }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = params.lastEvaluatedKey
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  protected isLessThan(params:{ value:string|number, indexName?:string, lastEvaluatedKey?:any }):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'isLessThan', value: params.value }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = params.lastEvaluatedKey
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  

  
  protected isLessOrEqual(params:{ value:string|number, indexName?:string, lastEvaluatedKey?:any }):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'isLessOrEqual', value: params.value }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = params.lastEvaluatedKey
    return this.read(request)
    .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  

  
  protected isGreaterThan(params:{ value:string|number, indexName?:string, lastEvaluatedKey?:any, view?:any }):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'isGreaterThan', value: params.value }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = params.lastEvaluatedKey
    return this.read(request)
    .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  

  
  protected isGreaterOrEqual(params:{ value:string|number, indexName?:string, lastEvaluatedKey?:any }):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'isGreaterOrEqual', value: params.value }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = params.lastEvaluatedKey
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  protected isBetween(params:{ lowerBounds:string|number, upperBounds:string|number, indexName?:string, lastEvaluatedKey?:any }):Promise<TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'isBetween', lowerBounds: params.lowerBounds, upperBounds: params.upperBounds }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) {
      request.lastEvaluatedKey = params.lastEvaluatedKey
      request.lastEvaluatedKey = JSON.stringify(params.lastEvaluatedKey)
    }
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  protected beginsWith(params:{ value:string|number, indexName?:string, lastEvaluatedKey?:any }):Promise<TDynamoRecord|TDynamoRecord[]> {
    let request:TReadRequest = { accountId: localStorage.getItem('accountId')!, condition: 'beginsWith', value: params.value }
    if (params.indexName) request.indexName = params.indexName
    if (params.lastEvaluatedKey) request.lastEvaluatedKey = params.lastEvaluatedKey
    return this.read(request)
      .then((results:any) => this.processResults({ results, params, conditional: request.condition! })) as Promise<TDynamoRecord[]>
  }
  












      
  public read(params:TReadRequest):Promise<TReadResponse> { 
    let options = new HttpOptions()
    options.params = params
    return this.http.get(this.baseUrl + this.read.name, options).toPromise()
      .catch(error => { return { success: false, details: error.message } as any })
  }














  protected processResults(params:{ results:TReadResponse, params:any, conditional:string }):TDynamoRecord|TDynamoRecord[] {
    params.results = this.appendQueryData(params)  
    params.results =  params.results.output.details.Items.map((item:TDynamoRecord) => new (this as any).model(item))
    this.migrate(params.results as any)
    if (params.conditional === 'id') return (params.results as any)[0]
    else return params.results as unknown as TDynamoRecord
  }


      protected appendQueryData(params:{ results:TReadResponse, params:any, conditional:string }) {
        params.results.output.details.query = { conditional: params.conditional }
        if (params.params) for (let [ property, value ] of Object.entries(params.params)) params.results.output.details.query[property] = value
        return params.results
      }














  protected migrate(results:any[]) {
    results
      .filter(record => this.migrationCondition(record))
      .map(record => this.migrationMutation(record))
      .forEach(record => this.update({ item: record }))
  }


    protected migrationCondition(record:any) {
      if ((record.inventory === true) && (!record.indexA)) return true
    }


    protected migrationMutation(record:any):any {
      record.schema = environment.version
      return record
    }














  public update(params:TUpdateRequest):Promise<TUpdateResponse> { 
    let options = new HttpOptions()
    options.params = params
    return this.http.put(this.baseUrl + this.update.name, options).toPromise() as Promise<TUpdateResponse>
  }













  
  public delete(params:TDeleteRequest):Promise<TDeleteResponse> { 
    let options = new HttpOptions()
    options.params = params
    return this.http.delete(this.baseUrl + this.delete.name, options).toPromise() as Promise<TDeleteResponse>
  }
  
}
