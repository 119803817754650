<ion-app>
  <div *ngIf="isReady">
    <div *ngIf="loading!.display" [id]="loading!.name" [ngStyle]="loading!.style" class="layer" >
      <app-loading></app-loading>
    </div>
    <div *ngIf="primary!.display" [id]="primary!.name" [ngStyle]="primary!.style" class="layer">
      <app-feedback-header></app-feedback-header>
    </div>
    <div *ngIf="secondary!.display" [id]="secondary!.name" [ngStyle]="secondary!.style" class="layer">
      <app-feedback-toaster></app-feedback-toaster>
    </div>
    <div *ngIf="overlay!.display" [id]="overlay!.name" [ngStyle]="overlay!.style" class="layer dynamicContentOverlay" fadeInView>
      <ng-container #dynamicContentContainer></ng-container>
    </div>
    <div *ngIf="main!.display" [id]="main!.name" [ngStyle]="main!.style" class="layer">
      <ion-router-outlet></ion-router-outlet>
    </div>
    <div *ngIf="background!.display" [id]="background!.name" [ngStyle]="background!.style" class="layer">
      <div id="background"><span id="logo">?</span></div>
    </div>
  </div>
</ion-app>
