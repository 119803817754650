import { ComponentForm } from '../../../classes/Abstract/ComponentForm/ComponentForm.class'
import { TTag } from '@private/models/tags/types/tag.type'
import { QuestionProcessorService } from '@private/models/questions/client/service/question-processor.service'
import { exists } from '@library/common/generic-common/tools/utils/concise.exists'
import { UxService } from '@services/ux/ux.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import propertyIfExists from '@library/common/generic-common/tools/utils/propertyIfExists'


export abstract class BaseFormQuestion extends ComponentForm {

  public get reasonFieldDescription():string {
    return propertyIfExists(this, 'ux.settings.descriptions.reasonFieldDescription')
  }
  
  public override form!:FormGroup
  public tags!:TTag[]


  constructor(
    protected override ux:UxService,
    protected processor:QuestionProcessorService
  ) { 
    super(ux)
  }













  
  protected override createForm() {
    this.form = new FormGroup({
      question: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] }),
      answer: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] }),
      reason: new FormControl(null),
      tags: new FormControl(null)
    })
    return this
  }














  protected override hookCreateFormPost() {
    // this.tags = this.input.tags
    if (this.input?.supplementary) this.form.controls['tags'].setValue(this.processor.setTags({ question: this.isolatedCopy, tags: this.input?.supplementary.tags }))
    return this
  }















  public onTagsSelect(event:CustomEvent) {
    this.form.value.tags = [ ]
    event.detail.value.forEach(tagId => this.form.value.tags.push(tagId))
  }
















  public getSelectedStatus(option):boolean|void {
    if (!exists(this, 'record.links.tags')) return
    else if (Object.keys(this.isolatedCopy.links.tags).includes(option.id)) return true
    else return false
  }

  
      












  protected override hookMutateDataBeforeEmit() {
    this.isolatedCopy = this.processor.mutateFormOutput(this.isolatedCopy)
    return this
  }

}