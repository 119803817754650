import { Subject } from 'rxjs';
import { TNewAccountData } from '../typings/TNewAccountData.type'
import { TTablesUsersCreateResponse } from '../../../database-common/typings/io.TTablesUserCreate.type';
import { IRegistrationAssistantCommonNewDbService } from './registration-assistant-common-new-db.service.interface'
import { Exactly } from '../../../generic-common/client/typings/Exactly';
import { IUsersLiasonCommon } from '../../../generic-common/typings/user/client/UsersLiason-common.service.interface';
import { IStateCommonService } from '@library/common/state-common/state-common.service.interface';
import { IEventsAppCommonService } from '@library/common/events-common/client/app/events-app-common.service.interface';
import copyOf from '../../../generic-common/tools/utils/concise.copyOf';


export abstract class RegistrationAssistantCommonNewDbService implements Exactly<IRegistrationAssistantCommonNewDbService, RegistrationAssistantCommonNewDbService> {


  public onFinished:Subject<any> = new Subject()
  protected saas:any


  constructor(
    protected events:IEventsAppCommonService,
    protected user:IUsersLiasonCommon,
    protected state:IStateCommonService
  ) {
    this.hookConstructorPre()
    this.init()
  }














  protected abstract hookConstructorPre():void













  
  private init() {
    this.events.State_onStateRequest.subscribe(state => this.state = state)
    this.events.Database_onUserDatabaseCreateResponse.subscribe(result => this.onCreateAccountDatabaseSuccess(result))
  }














  public create(params:TNewAccountData):void {
    let records = this.createUserDatabaseRecords(params) || [ ]
    records.push(this.createInitialUser(params))
    this.events.Database_onUserDatabaseCreateRequest.next({ siloName: `${ this.saas.saasName }-${ this.state.stage }`, records: records })
  }


      protected abstract createUserDatabaseRecords(params:TNewAccountData):any[]


      protected createInitialUser(params:TNewAccountData) {
        let data = copyOf(params)
        data.table = `${ params.accountId }.users`
        data.id = data.userId
        delete data.userId
        delete data.acctId
        delete data.accountId
        delete data.authId
        delete data.password
        let initialUser = this.user.create(data)
        return initialUser
      }


      private onCreateAccountDatabaseSuccess(data:TTablesUsersCreateResponse) {
        if (!data.output.success) this.onCreateAccountDatabaseFailure(data)
        this.onFinished.next({ success: true, details: data })
      }


      private onCreateAccountDatabaseFailure(error:any) {
        this.onFinished.next({ success: false, error: error })
      }

}
