import { TModelSettings } from '@library/common/generic-common/typings/ModelDynamoDbV2/TModelSettings.type'
import { ModelDynamoDb } from '../../../../../../library/common/generic-common/typings/ModelDynamoDbV2/ModelDynamoDb.abstract'
import { TPreferences } from './preferences.type'


class Preference extends ModelDynamoDb {

  constructor(record) {
    super(record)
  }

}


namespace Preference {

  export const TPreferencesSettings:TModelSettings = {
    nameSingular: 'preference',
    namePlural: 'preferences',
    prefix: 'prf',
    idLength: 5,
    canLinkTo: [
    
    ],
    indexes: {
      indexA: { name: 'indexA', property: null },
      indexB: { name: 'indexB', property: null },
      indexC: { name: 'indexC', property: null },
    },
    summary: [ ],
  }

  
}

export default Preference