import { Injectable } from '@angular/core';
import { QuizzesLiason } from '@private/models/quizzes/client/liason/QuizzesLiason.service'
import { QuestionsLiason } from '@private/models/questions/client/liason/QuestionsLiason.service'
import { TagsLiason } from '@private/models/tags/client/liason/TagsLiason.service'
import { IModelsService } from './models.service.interface'
import { DevicesLiason } from '@private/models/devices/client/DevicesLiason.service';
import { PreferencesLiason } from '@private/models/preferences/client/liason/PreferencesLiason.service';
import { Exactly } from '@library/common/generic-common/client/typings/Exactly';


@Injectable({
  providedIn: 'root'
})
export class ModelsService implements Exactly<IModelsService, ModelsService> {

  constructor(
    public quizzes:QuizzesLiason,
    public questions:QuestionsLiason,
    public tags:TagsLiason,
    public devices:DevicesLiason,
    public preferences:PreferencesLiason
  ) { }
  
}
