import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseFormQuestionaire } from '../BaseFormQuestionaire.class'
import { UxService } from '@services/ux/ux.service';


@Component({
  selector: 'app-questionaire-text',
  templateUrl: './questionaire-text.component.html',
  styleUrls: ['./questionaire-text.component.scss'],
})
export class QuestionaireTextComponent extends BaseFormQuestionaire implements OnInit, OnChanges {


  constructor(
    public override ux:UxService
  ) {
    super(QuestionaireTextComponent, ux)
  }

  














  protected override createForm():this {
    this.form = new FormGroup({
      userAnswer: new FormControl((this.isolatedCopy as any).userAnswer, { validators: [Validators.required, Validators.minLength(1)] }),
      correct: new FormControl(null)
    })
    return this
  }

}
