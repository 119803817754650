import { ReplaySubject, Subject } from 'rxjs'
import { Injectable } from '@angular/core'
import { Exactly } from '@library/common/generic-common/client/typings/Exactly'
import { IEventsAppCommonService } from './events-app-common.service.interface'
import * as account from '@library/common/account-common/events/account-manager-common.events.io'
import * as captcha from '@library/common/captcha-common/client/events/captcha-common.events.io'
import * as database from '@library/common/database-common/client/events/database-common.events.io'
import * as datetime from '@library/common/datetime-common/client/events/datetime-common.events.io'
import * as logs from '@library/common/logs-common/client/events/app/logs-app-common.events.io'
import * as schedule from '@library/common/schedule-common/client/events/schedule-common.events.io'
import * as state from '@library/common/state-common/events/state-common.events.io'
import * as support from '@library/common/support-common/client/events/support-common.events.io'


@Injectable({
  providedIn: 'root'
})
export class EventsAppCommonService implements Exactly<IEventsAppCommonService, EventsAppCommonService> { 

  public Account_onRegisterRequest:Subject<account.TAccount_Registration_onRegisterRequest> = new Subject()
  public Account_onRegisterResponse:Subject<account.TAccount_Registration_onRegisterResponse> = new Subject()
  public Account_onUnregisterRequest:Subject<account.TAccount_Registration_onUnregisterRequest> = new Subject()
  public Account_onUnregisterResponse:Subject<account.TAccount_Registration_onUnregisterResponse> = new Subject()
  public Account_onLoginRequest:Subject<account.TAccount_Logins_onLoginRequest> = new Subject()
  public Account_onLoginResponse:ReplaySubject<account.TAccount_Logins_onLoginResponse> = new ReplaySubject()
  public Account_onLogoutRequest:Subject<account.TAccount_Logins_onLogoutRequest> = new Subject()
  public Account_onLogoutResponse:Subject<account.TAccount_Logins_onLogoutResponse> = new Subject()
  public Account_onPasswordForgotRequest:Subject<account.TAccount_Logins_onPasswordForgotRequest> = new Subject()
  public Account_onPasswordForgotResponse:Subject<account.TAccount_Logins_onPasswordForgotResponse> = new Subject()
  public Account_onPasswordForgotResetRequest:Subject<account.TAccount_Logins_onPasswordForgotResetRequest> = new Subject()
  public Account_onPasswordForgotResetResponse:Subject<account.TAccount_Logins_onPasswordForgotResetResponse> = new Subject()
  public Account_onPasswordChangeRequest:Subject<account.TAccount_Logins_onPasswordChangeRequest> = new Subject()
  public Account_onPasswordChangeResponse:Subject<account.TAccount_Logins_onPasswordChangeResponse> = new Subject()
  public Account_onUserEmailCheckAvailabilityRequest:Subject<account.TAccount_Logins_onUserEmailCheckAvailabilityRequest> = new Subject()
  public Account_onUserEmailCheckAvailabilityResponse:Subject<account.TAccount_Logins_onUserEmailCheckAvailabilityResponse> = new Subject()
  public Account_onUserCreateRequest:Subject<account.TAccount_Logins_onUserCreateRequest> = new Subject()
  public Account_onUserCreateResponse:Subject<account.TAccount_Logins_onUserCreateResponse> = new Subject()
  public Account_onUserDeleteRequest:Subject<account.TAccount_Logins_onUserDeleteRequest> = new Subject()
  public Account_onUserDeleteResponse:Subject<account.TAccount_Logins_onUserDeleteResponse> = new Subject()
  public Account_onLoginProfileReadByUserIdRequest:Subject<account.TAccount_Logins_onLoginProfileReadByUserIdRequest> = new Subject()
  public Account_onLoginProfileReadByUserIdResponse:Subject<account.TAccount_Logins_onLoginProfileReadByUserIdResponse> = new Subject()
  public Account_onSessionData:ReplaySubject<account.TAccount_Logins_onSessionData> = new ReplaySubject()

  public Captcha_onHumanVerifyRequest:Subject<captcha.TCaptcha_onHumanVerifyRequest> = new Subject()
  public Captcha_onHumanVerifyResponse:Subject<captcha.TCaptcha_onHumanVerifyResponse> = new Subject()

  public Database_onRecordLinkRequest:Subject<database.TDatabase_onRecordLinkRequest> = new Subject()
  public Database_onUserDatabaseCreateRequest:Subject<database.TDatabase_onUserDatabaseCreateRequest> = new Subject()
  public Database_onUserDatabaseCreateResponse:Subject<database.TDatabase_onUserDatabaseCreateResponse> = new Subject()

  public DateTime_onAddSubtractRequest:Subject<datetime.TDateTime_onAddSubtractRequest> = new Subject()
  public DateTime_onAddSubtractResponse:Subject<datetime.TDateTime_onAddSubtractResponse> = new Subject()
  public DateTime_onEpochByTimezoneRequest:Subject<datetime.TDateTime_onEpochByTimezoneRequest> = new Subject()
  public DateTime_onEpochByTimezoneResponse:Subject<datetime.TDateTime_onEpochByTimezoneResponse> = new Subject()
  public DateTime_onFutureDateTimeRequest:Subject<datetime.TDateTime_onFutureDateTimeRequest> = new Subject()
  public DateTime_onFutureDateTimeResponse:Subject<datetime.TDateTime_onFutureDateTimeResponse> = new Subject()
  public DateTime_onGetTimezoneOffsetRequest:Subject<datetime.TDateTime_onGetTimezoneOffsetRequest> = new Subject()
  public DateTime_onGetTimezoneOffsetResponse:Subject<datetime.TDateTime_onGetTimezoneOffsetResponse> = new Subject()
  public DateTime_onTimezonesRequest:Subject<datetime.TDateTime_onTimezonesRequest> = new Subject()
  public DateTime_onTimezonesResponse:ReplaySubject<datetime.TDateTime_onTimezonesResponse> = new ReplaySubject()

  public Logs_onCodeCall:Subject<logs.TOnCodeCall> = new Subject()
  public Logs_onError:Subject<logs.TApp_onError> = new Subject()

  public Schedule_onJobRequest:Subject<schedule.TSchedule_onJobRequest> = new Subject()

  public State_onStateRequest:ReplaySubject<state.onStateRequest> = new ReplaySubject()

  public Support_onAlertRequest:Subject<support.TSupport_onAlertRequest> = new Subject()
  public Support_onAlertResponse:Subject<support.TSupport_onAlertResponse> = new Subject()
  public Support_onSupporContactRequest:Subject<support.TSupport_onSupporContactRequest> = new Subject()
  public Support_onSupporContactResponse:Subject<support.TSupport_onSupporContactResponse> = new Subject()
  public Support_onSupportLogRequest:Subject<support.TSupport_onSupportLogRequest> = new Subject()
  public Support_onSupportLogResponse:Subject<support.TSupport_onSupportLogResponse> = new Subject()
  public Support_onDataRemovalRequest:Subject<support.TSupport_onDataRemovalRequest> = new Subject()
  public Support_onDataRemovalResponse:Subject<support.TSupport_onDataRemovalResponse> = new Subject()

}